import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { SetStateAction, useMemo } from "react";
import PropertyReadOnlyPreview from "../../../../Content/Containers/Common/PropertyReadOnlyPreview";
import * as React from "react";
import slugify from "slugify";
import { useTranslation } from "react-i18next";

type EntitySlugProps = {
  values: any;
  setValues: (e: SetStateAction<any>) => void;
  allowEdit: boolean;
  inProgress: boolean;
  variant: string;
  slugPrefix: string;
};

function EntitySlug(props: EntitySlugProps) {
  const { values, setValues, variant, allowEdit, inProgress, slugPrefix } =
    props;
  const { t } = useTranslation();
  const handleUpdateName = (e: any) => {
    setValues((prevState: any) => ({
      ...prevState,
      name: e.target.value,
      slug: slugify(e.target.value).toLowerCase(),
    }));
  };

  const handleUpdateSlug = (e: any) => {
    setValues((prevState: any) => ({
      ...prevState,
      slug: e.target.value,
    }));
  };

  const valueSlug = useMemo(() => {
    if (values.slug) return values.slug.replace(slugPrefix, "");
    return values.slug;
  }, [values.slug, slugPrefix]);

  if (!allowEdit) {
    return (
      <Grid container spacing={2}>
        <Grid key={"name"} item xs={12} sm={6}>
          <PropertyReadOnlyPreview
            name={t("formElement.name")}
            value={values.name}
          />
        </Grid>
        <Grid key={"slug"} item xs={12} sm={6}>
          <PropertyReadOnlyPreview
            name={t("formElement.slug")}
            value={values.slug}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Stack
      direction={"row"}
      columnGap={2}
      alignItems={"flex-start"}
      style={{ width: "100%" }}
    >
      <TextField
        label={t("formElement.name")}
        size={"small"}
        fullWidth
        id={"name"}
        name={"name"}
        required={true}
        disabled={inProgress || !allowEdit}
        // @ts-ignore
        variant={variant}
        value={values.name}
        style={{ flex: 1 }}
        onChange={handleUpdateName}
      />
      <TextField
        label={t("formElement.slug")}
        size={"small"}
        fullWidth
        id={"slug"}
        name={"slug"}
        required={true}
        disabled={inProgress || !allowEdit}
        // @ts-ignore
        variant={variant}
        value={valueSlug}
        style={{ flex: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{slugPrefix}</InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        onChange={handleUpdateSlug}
      />
    </Stack>
  );
}

export default EntitySlug;
