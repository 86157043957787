import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#ffffff',
  100: '#e6e6e6',
  200: '#999999',
  300: '#bfbfbf',
  400: '#b3b3b3',
  500: '#000',
  600: '#999999',
  650: '#e6e6e6',
  700: '#808080',
  800: '#808080',
  850: '#e6e6e6',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#454F5B', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#88a6fd',
  light: '#6188fd',
  main: '#396afc',
  dark: '#2e55ca',
  darker: '#224097',
  contrastText: GREY[0],
};

const SECONDARY = {
  light: '#d1d1e0',
  main: '#c2c2d6',
  dark: '#b3b3cc',
  darker: '#8585ad',
  contrastText: '#7575a3',
};

const INFO = {
  light: '#4dabf5',
  main: '#2196f3',
  dark: '#1769aa',
  darker: '#1769aa',
  contrastText: '#ffffff',
};

const SUCCESS = {
  // lighter: '#eafaeb',
  // light: '#d5f6d7',
  main: '#77DD77',
  // dark: '#c1f1c3',
  // darker: '#acecaf',
  // darkest: '#59d95f',
  contrastText: '#165816',
};

const WARNING = {
  // lighter: '#ffe380',
  // light: '#ffd84d',
  main: '#FF6C40',
  // dark: '#ffc800',
  // darker: '#cca000',
  contrastText: GREY[800],
};

const ERROR = {
  // light: '#fde7e7',
  main: '#DA100B',
  // dark: '#DA100B',
  // darker: '#f6716f',
  contrastText: '#fde7e7',
};

const CUSTOM = {
  main: '#66ccff',
  contrastText: '#536DFE',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#66ccff', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const palette = {
  common: { black: '#000', white: '#fff' },
  mode: "dark",
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  custom: { ...CUSTOM },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  // text: { primary: GREY[900], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#1C1B1F', default: "#101010", neutral: "#0d0d0d", active: "#404040" },
  // action: {
  //   active: GREY[600],
  //   hover: GREY[500_8],
  //   selected: GREY[500_16],
  //   disabled: GREY[500_80],
  //   disabledBackground: GREY[500_24],
  //   focus: GREY[500_24],
  //   hoverOpacity: 0.08,
  //   disabledOpacity: 0.48,
  // },
};

export default palette;
