import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import React, { useMemo } from "react";
import { SchemaProperty } from "../../../../../../models/Schema";
import { UseType } from "../../../../../../models/UseType";
import Selector from "components/@lib/Inputs/Selector";
import Typography from "@mui/material/Typography";
import useFormElements from "../../../../../../hooks/form/useFormElements";
import { usePropertyFormElements } from "../../Properties/form-elements";
import Property from "../../../../../../models/Property";
import ReservedUUID from "../../../../../../models/ReservedUUID";
import { getIconForProperty } from "./PropertyList";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import {Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

type PropertyConfigProps = {
  selectedElement: SchemaProperty | undefined;
  updateConfig: (prop: keyof SchemaProperty, value: any) => void;
  handleEditProperty: (property: SchemaProperty) => void;
  disabled?: boolean;
  isSaving?: boolean;
  hasError?: boolean;
};

type ToggleProps = {
  updateConfig: (key: keyof SchemaProperty, value: boolean) => void;
  disabled?: boolean;
  checked: boolean;
  label: string;
  tooltip: string;
  id: keyof SchemaProperty;
};
const Toggle = ({
  id,
  checked,
  disabled,
  label,
  updateConfig,
  tooltip,
}: ToggleProps) => (
  <Stack
    direction={"row"}
    alignItems={"center"}
    justifyContent={"space-between"}
  >
    <FormControlLabel
      disabled={disabled}
      control={
        <Switch
          checked={checked}
          onChange={() => updateConfig(id, !checked)}
          name={id}
        />
      }
      label={label}
    />
    <Tooltip title={tooltip} placement={"top"}>
      <InfoOutlinedIcon fontSize={"small"} />
    </Tooltip>
  </Stack>
);

function PropertyConfig(props: PropertyConfigProps) {
  const { selectedElement, updateConfig, disabled, isSaving, hasError, handleEditProperty } = props;
  const { t } = useTranslation();
  const values = Property.toFlatProperty(
    selectedElement?.property ?? new Property()
  );
  const elements = usePropertyFormElements(
    values.propertyType,
    values,
    values.uuid
  );
  const form = useFormElements({
    formElements: elements.map((l) => ({ ...l, expanded: false })),
    values,
    allowEdit: false,
  });

  const hideConfig = values.propertyGroup === ReservedUUID.SystemPropertyGroup;

  const indicator = useMemo(() => {
    if (isSaving)
      return {
        message: t("schemas.properties.indicator.saving"),
        color: "text.secondary",
      };
    if (hasError === true)
      return {
        message: t("schemas.properties.indicator.error"),
        color: "error.dark",
      };
    if (hasError === false)
      return {
        message: t("schemas.properties.indicator.saved"),
        color: "success.dark",
      };
    return null;
  }, [hasError, isSaving, t]);
  return (
    <Stack
      direction={"column"}
      style={{
        flex: 1,
        maxHeight: "calc(100vh - 200px)",
        height: "calc(100vh - 200px)",
        overflow: "auto",
      }}
      p={4}
    >
      {selectedElement && (
        <Stack rowGap={2}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <Typography variant={"overline"} color={"textSecondary"}>
              {t("schemas.properties.configuration")}
            </Typography>
            {indicator && (
              <Typography variant={"body2"} sx={{ color: indicator.color }}>
                {indicator.message}
              </Typography>
            )}
          </Stack>
          {selectedElement.property && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Chip
                icon={getIconForProperty(selectedElement.property.propertyType)}
                color={"primary"}
                label={selectedElement.property.name}
                style={{ marginRight: "auto" }}
              />
              <Tooltip
                placement={"top"}
                title={t("schemas.properties.edit.tooltip").toString()}
              >
                <Button
                  variant={"outlined"}
                  size={"small"}
                  startIcon={<EditIcon fontSize={"small"} />}
                  onClick={() => handleEditProperty(selectedElement)}
                  sx={{
                    color: "primary.main",
                    borderRadius: 2,
                    p: 1,
                  }}
                >
                  <Typography variant={"body2"}>
                    {t("schemas.properties.edit")}
                  </Typography>
                </Button>
              </Tooltip>
            </Stack>
          )}
          {!hideConfig && (
            <Selector
              disabled={disabled}
              options={[
                {
                  id: UseType.OPTIONAL,
                  name: t("schemas.properties.use.optional"),
                },
                {
                  id: UseType.RECOMMENDED,
                  name: t("schemas.properties.use.rec"),
                },
                { id: UseType.REQUIRED, name: t("schemas.properties.use.req") },
              ]}
              value={selectedElement.use}
              onChange={(e) => updateConfig("use", e.target.value)}
              name={"use"}
              label={t("formElement.use").toString()}
            />
          )}
          {!hideConfig && (
            <Stack>
              <Toggle
                updateConfig={updateConfig}
                disabled={disabled}
                checked={Boolean(selectedElement.repeatable)}
                label={t("schemas.properties.repeatable")}
                id={"repeatable"}
                tooltip={t("schemas.properties.repeatable.tooltip")}
              />
              {/*{selectedElement.repeatable && (*/}
              {/*  <Stack direction={"row"} columnGap={2} my={1}>*/}
              {/*    <TextField*/}
              {/*      disabled={disabled}*/}
              {/*      fullWidth*/}
              {/*      variant={"filled"}*/}
              {/*      type={"number"}*/}
              {/*      size={"small"}*/}
              {/*      name={"minOccurs"}*/}
              {/*      label={"Min Occurs"}*/}
              {/*      value={selectedElement.minOccurs}*/}
              {/*      onChange={(e) => updateConfig("minOccurs", e.target.value)}*/}
              {/*    />*/}
              {/*    <TextField*/}
              {/*      disabled={disabled}*/}
              {/*      fullWidth*/}
              {/*      variant={"filled"}*/}
              {/*      type={"number"}*/}
              {/*      size={"small"}*/}
              {/*      name={"maxOccurs"}*/}
              {/*      label={"Max Occurs"}*/}
              {/*      value={selectedElement.maxOccurs}*/}
              {/*      onChange={(e) => updateConfig("maxOccurs", e.target.value)}*/}
              {/*    />*/}
              {/*  </Stack>*/}
              {/*)}*/}
              <Toggle
                updateConfig={updateConfig}
                disabled={disabled}
                checked={Boolean(selectedElement.indexable)}
                label={t("schemas.properties.indexable")}
                id={"indexable"}
                tooltip={t("schemas.properties.indexable.tooltip")}
              />
              <Toggle
                updateConfig={updateConfig}
                disabled={disabled}
                checked={Boolean(selectedElement.publishable)}
                label={t("schemas.properties.publishable")}
                id={"publishable"}
                tooltip={t("schemas.properties.publishable.tooltip")}
              />
              <Toggle
                updateConfig={updateConfig}
                disabled={disabled}
                checked={Boolean(selectedElement.editable)}
                label={t("schemas.properties.editable")}
                id={"editable"}
                tooltip={t("schemas.properties.editable.tooltip")}
              />
            </Stack>
          )}

          {!hideConfig && <Divider sx={{ my: 2 }} />}

          <Typography variant={"overline"} color={"textSecondary"}>
            {t("schemas.properties.details")}
          </Typography>
          <Stack style={{ maxWidth: 920 }}>{form}</Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default PropertyConfig;
