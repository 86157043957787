import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import "react-google-places-autocomplete/dist/index.min.css";
import { FlatProperty } from "../../../models/FlatProperty";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PlacesAutocomplete from "../../Common/PlacesAutocomplete";
import Typography from "@mui/material/Typography";

type PreviewProps = {
  property: FlatProperty;
  value: SpatialValue;
  required: boolean;
  recommended: boolean;
  setValue: Dispatch<SetStateAction<SpatialValue>>;
  readonly?: boolean;
};

export class SpatialValue {
  placeName: string = "";
  longitude: number = 0.0;
  latitude: number = 0.0;
}

function SpatialProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
}: PreviewProps) {

  const lng = useMemo(()=>{
    return value.longitude
  }, [value])

  const lat = useMemo(()=>{
    return value.latitude
  }, [value])

  const setNewValue = useCallback((pos: google.maps.LatLng) => {
    setValue({
      placeName: value.placeName,
      latitude: pos.lat(),
      longitude: pos.lng(),
    });
  },[setValue, value]);

  const setLatitude = (latitude: number) => {
    const pos = new google.maps.LatLng(latitude, lng);
    setNewValue(pos)
  }

  const setLongitude = (longitude: number) => {
    const pos = new google.maps.LatLng(lat, longitude);
    setNewValue(pos)
  }

  const handlePlaceSelection = (place: google.maps.places.PlaceResult) => {
    setValue({
      placeName: place.formatted_address ?? "",
      latitude: place.geometry?.location?.lat() ?? 0,
      longitude: place.geometry?.location?.lng() ?? 0,
    });
  };

  const handleMapClickOrDragEnd = (e: google.maps.MapMouseEvent) => {
    setValue({
      placeName: "",
      longitude: e.latLng?.lng() ?? 0,
      latitude: e.latLng?.lat() ?? 0,
    });
  };

  if (readonly) {
    return (
      <Stack rowGap={1}>
        <Typography variant={"caption"} color={"textSecondary"}>
          {property.name} {required && "*"} {recommended && <sup>(Rec)</sup>}
        </Typography>
        <GoogleMap
          mapContainerStyle={{ width: "auto", height: "400px" }}
          center={{ lat: lat, lng: lng }}
          zoom={5}
        >
          <Marker position={{ lat: lat, lng: lng }} />
        </GoogleMap>
      </Stack>
    );
  }

  return (
    <FormControl fullWidth>
      <FormLabel sx={{ mb: 1 }}>{property.name}  {required && "*"} {recommended && <sup>(Rec)</sup>}</FormLabel>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Tooltip title={property.tooltip} placement={"bottom-start"}>
            <PlacesAutocomplete onPlaceSelected={handlePlaceSelection} />
          </Tooltip>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant={"standard"}
            type={"number"}
            fullWidth
            size={"small"}
            value={lat}
            onChange={(e) => setLatitude(Number(e.target.value))}
            label={"Latitude"}
            required={required}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant={"standard"}
            type={"number"}
            fullWidth
            size={"small"}
            value={lng}
            onChange={(e) => setLongitude(Number(e.target.value))}
            label={"Longitude"}
            required={required}
          />
        </Grid>
        <Grid item md={12} sm={12} xl={12}>
          <GoogleMap
            mapContainerStyle={{ width: "auto", height: "400px" }}
            center={{ lat: lat, lng: lng }}
            zoom={5}
            onClick={handleMapClickOrDragEnd}
          >
            <Marker
              position={{ lat: lat, lng: lng }}
              draggable={true}
              onDragEnd={handleMapClickOrDragEnd}
            />
          </GoogleMap>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default SpatialProperty;
