import {PropertyType} from "./PropertyType";
import Attribute from "./Attribute";
import Property from "./Property";
import {DataSource, TextTransform} from "./DataOptions";
import {SpatialValue} from "../components/Properties/ControlledProperty/SpatialProperty";
import {TagValueType} from "./TagValueType";

export class FlatProperty {
  uuid: string | undefined = undefined;
  name = "";
  slug = "";
  description = "";
  propertyType = PropertyType.VOCABULARY;
  namespace = "";
  tagName = "";
  tagValueType  = TagValueType.ALL;
  attributes: Attribute[] = [];
  prefix = "";
  suffix = "";
  tooltip = "";
  placeholder = "";
  showCharCounter = false;
  showWordCounter = false;
  textCase = TextTransform.NONE;
  defaultValue?: string | string[] | boolean | SpatialValue | number[] | number;
  maxLength = "";
  mask?: string = "";
  minLength = "";
  minValue?: number | string;
  maxValue?: number | string;
  minRows?: number;
  maxRows?: number;
  enableStaticSearch = false;
  values: any = [];
  marks: any = [];
  multipleValues = false;
  requireDecimal = false;
  decimalLimit = 0;
  delimeter = false;
  propertyGroup = "";
  dataSource?: DataSource = undefined;
  vocabularyId?: string = undefined;
  identifierId?: string = undefined;
  step?: number
  datastreamTypes?: string[]
  containerType?: string

  static toProperty(flat: FlatProperty) {
    const property = new Property();
    property.uuid = flat.uuid;
    property.name = flat.name;
    property.slug = flat.slug;
    property.namespace = flat.namespace;
    property.description = flat.description;
    property.tagName = flat.tagName;
    property.tagValueType = flat.tagValueType;
    property.propertyType = flat.propertyType;
    property.attributes = flat.attributes;
    property.propertyGroup = flat.propertyGroup;

    property.dataOptions = {
      textCase: flat.textCase,
      defaultValue: flat.defaultValue,
      enableStaticSearch: flat.enableStaticSearch,
      values: flat.values,
      marks: flat.marks,
      multipleValues: flat.multipleValues,
      dataSource: flat.dataSource,
      vocabularyId: flat.vocabularyId,
      identifierId: flat.identifierId,
      step: flat.step,
      containerType: flat.containerType,
      datastreamTypes: flat.datastreamTypes
    };
    property.displayOptions = {
      suffix: flat.suffix,
      prefix: flat.prefix,
      tooltip: flat.tooltip,
      showCharCounter: flat.showCharCounter,
      showWordCounter: flat.showWordCounter,
      placeholder: flat.placeholder,
      maxRows: flat.maxRows,
      minRows: flat.minRows,
      requireDecimal: flat.requireDecimal,
      decimalLimit: flat.decimalLimit,
      delimeter: flat.delimeter,
      mask: flat.mask
    };
    property.validationOptions = {
      maxLength: parseInt(flat.maxLength),
      minLength: parseInt(flat.minLength),
      minValue: flat.minValue,
      maxValue: flat.maxValue,
    };
    return property;
  }
}
