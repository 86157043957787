const styles = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
  },
  "&multiLine": {
    control: {
      fontFamily: "Roboto, sans-serif",
      minHeight: 80,
    },
    highlighter: {
      padding: 8,
      border: "1px solid transparent",
    },
    input: {
      padding: 8,
      border: "1px solid lightgrey",
      outline: "none",
      borderRadius: 8
    },
  },
  "&singleLine": {
    display: "inline-block",
    width: 180,
    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      color: "black",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 12,
      height: 150,
      overflowY: "scroll",
    },
    item: {
      padding: "5px 15px",
      fontFamily: "Roboto, sans-serif",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#b3beff",
      },
    },
  },
};

export default styles