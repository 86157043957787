import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import * as React from "react";
import Job, { JobState } from "../../models/Job";
import { useDefaultTable } from "../defaultTable/useDefaultTable";
import { useSortHandlerWithRouter } from "../sort/useSortHandlerWithRouter";
import { useNavigate } from "react-router-dom";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export function transformEnum(enumStr: string): string {
  const words = enumStr.split("_");
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
/*export const getJobTypeReadable = (jobType: string) => {
  switch (jobType) {
    case JobType.IDX_FULLTEXT_DATASTREAM_CREATE:
      return "Create datastream to FULLTEXT";
    case JobType.IDX_FULLTEXT_DATASTREAM_UPDATE:
      return "Update datastream to FULLTEXT";
    case JobType.IDX_FULLTEXT_REINDEX_ALL:
      return "Recreate all documents to FULLTEXT";
    case JobType.IDX_FULLTEXT_REINDEX_MISSING:
      return "Create all datastreams not already stored in FULLTEXT";
    case JobType.IDX_AI_DATASTREAM_CREATE:
      return "Create document to AI";
    case JobType.IDX_AI_DATASTREAM_UPDATE:
      return "Update document to AI";
    case JobType.IDX_AI_REINDEX_ALL:
      return "Recreate all documents to AI";
    case JobType.IDX_AI_REINDEX_MISSING:
      return "Create all datastreams not already stored in AI";
    case JobType.IDX_AI_DELETE_BY_DATASTREAM:
      return "Delete document from AI";
    case JobType.FILE_INTEGRITY:
      return "File integrity";
    case JobType.DATASTREAM_THUMBNAIL_CREATE:
      return "Thumbnail creation";
    case JobType.PREVIEW_XLSX:
      return "Preview xlsx file";
    case JobType.IMPORT_XLSX:
      return "Import xlsx file without preview";
    case JobType.IMPORT_XLSX_REVALIDATE:
      return "Import xlsx file with preview";
    case JobType.IMPORT_ARCHIVE:
      return "Import archive";
    case JobType.EXPORT_XLSX:
      return "Export xlsx file";
    case JobType.EXPORT_ARCHIVE:
      return "Export archive";
    case JobType.REMOVE:
      return "Remove to trash";
    case JobType.RESTORE:
      return "Restore from trash";
    case JobType.DELETE:
      return "Delete from trash";
    default:
      return transformEnum(jobType);
  }
};*/


function calculateDuration(createdAt: string, completedAt?: string): string {
  const startMoment = moment(createdAt);
  const endMoment = moment(completedAt ?? new Date());
  const duration = moment.duration(endMoment.diff(startMoment));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (years > 0) {
    return years === 1 ? "1 year" : `${years} years`;
  } else if (months > 0) {
    return months === 1 ? "1 month" : `${months} months`;
  } else if (days > 0) {
    return days === 1 ? "1 day" : `${days} days`;
  } else if (hours > 0) {
    const hoursString = hours === 1 ? "1 hour" : `${hours} hours`;
    const minutesString = minutes === 0 ? "" : ` ${minutes} mins`;
    return `${hoursString}${minutesString}`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 min" : `${minutes} mins`;
  } else {
    return `${seconds} s`;
  }
}


const useJobsTableBody = (jobs: Job[]) => {
  const navigate = useNavigate();

  // const [users] = useUserList()
  // const userMap = useMap(users, "uuid")

  const sortHandler = useSortHandlerWithRouter();
  const { renderBodyCell } = useDefaultTable(jobs, sortHandler, "jobId", false);


  const handleJob = (job: Job) => {
    navigate(`/store/my-activity/${job.jobId}`);
  };

  return (jobs: Job[]) => {
    return jobs.map((job: Job) => {
      /*const user = userMap.get(job.createdBy)*/

      const getIcon = () => {
        if (job.state === JobState.PROCESSING) {
          return <CircularProgress value={job.progress} size={24}/>
        } else if (job.state === JobState.FAILED) {
          return <CancelIcon color={"error"}/>
        } else if (job.state === JobState.SUCCEEDED) {
          return <CheckCircleIcon color={"success"}/>
        } else if (job.state === JobState.ENQUEUED) {
          return <PauseCircleOutlineIcon />
        } else {
          return <Tooltip title={job.state}>
            <InfoOutlinedIcon  />
          </Tooltip>
        }
      }

      return (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={job.jobId}
          onClick={() => handleJob(job)}
          sx={{backgroundColor: "background.paper", borderBottom: "1px solid", borderColor: "divider"}}
        >
          {renderBodyCell(
            <Stack direction={"row"} py={1}>
              <Stack flex={1} direction={"row"} spacing={2} alignItems={"center"} width={"100%"}>
                {getIcon()}
                <Stack direction={"column"} spacing={0.5}>
                  <Typography fontWeight={"bold"} color={"textPrimary"}>
                    {job.jobName ? job.jobName : transformEnum(job.jobType)}
                  </Typography>
                  <Typography variant={"caption"} color={"textSecondary"}>
                    {transformEnum(job.jobType)}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"column"} spacing={0.5} width={140}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <EventIcon fontSize={"small"}/>
                  <Typography variant={"caption"} color={"textSecondary"}>
                    {moment(job.createdAt).fromNow()}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <AccessTimeIcon fontSize={"small"}/>
                  <Typography variant={"caption"} color={"textSecondary"}>
                    {calculateDuration(job.createdAt, job.completedAt)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </TableRow>
      );
    });
  }
}

export default useJobsTableBody