import SavedSearchOutlinedIcon from "@mui/icons-material/SavedSearchOutlined";
import IconButton from "@mui/material/IconButton";
import React, { Fragment, useState } from "react";
import ViewSavedSearchDialog from "pages/Search/ViewSavedSearchDialog/ViewSavedSearchDialog";

function SearchHistory() {
  const [openViewSavedSearch, setOpenViewSavedSearch] = useState(false);

  return (
    <Fragment>
      <IconButton onClick={() => setOpenViewSavedSearch(true)} sx={{ ml: 1 }}>
        <SavedSearchOutlinedIcon />
      </IconButton>

      <ViewSavedSearchDialog
        open={openViewSavedSearch}
        handleClose={() => setOpenViewSavedSearch(false)}
      />
    </Fragment>
  );
}

export default SearchHistory;
