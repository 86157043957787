import * as React from "react";
import {PropertyType} from "../../../models/PropertyType";
import TextFieldPreview from "./TextfieldPreview";
import SelectPreview from "./SelectPreview";
import TogglePreview from "./TogglePreview";
import TextAreaPreview from "./TextAreaPreview";
import NumberPreview from "./NumberPreview";
import DatePreview from "./DatePreview";
import RichTextPreview from "./RichTextPreview";
import ReferencePreview from "./ReferencePreview";
import {FlatProperty} from "../../../models/FlatProperty";
import SpatialPreview from "./SpatialPreview";
import VocabularyPreview from "./VocabularyPreview";
import SliderPreview from "./SliderPreview";
import DatastreamReferencePreview from "components/Properties/Previews/DatstreamReferencePreview";
import GroupProperty from "../ControlledProperty/GroupProperty";
import {ReactNode} from "react";
import IdentifierPreview from "./IdentifierPreview";

type PreviewProps = {
  property: FlatProperty;
  children?: ReactNode
};

function Preview(props: PreviewProps) {
  const { property, children } = props;

  switch (property.propertyType) {
    case PropertyType.TEXT_FIELD:
    case PropertyType.URL:
      return <TextFieldPreview property={property} />;
    case PropertyType.IDENTIFIER:
      return <IdentifierPreview property={property} />;
    case PropertyType.DATE:
      return <DatePreview property={property} />
    case PropertyType.SELECT:
      return <SelectPreview property={property} />;
    case PropertyType.BOOLEAN:
      return <TogglePreview property={property} />;
    case PropertyType.TEXT_AREA:
      return <TextAreaPreview property={property} />;
    case PropertyType.NUMBER:
      return <NumberPreview property={property} />;
    case PropertyType.RICH_TEXT:
      return <RichTextPreview property={property} />;
    case PropertyType.REFERENCE:
      return <ReferencePreview property={property}/>;
    case PropertyType.DATASTREAM_REF:
      return <DatastreamReferencePreview property={property}/>;
    case PropertyType.SPATIAL:
      return <SpatialPreview property={property} />;
    case PropertyType.VOCABULARY:
      return <VocabularyPreview property={property} />;
    case PropertyType.SLIDER:
      return <SliderPreview property={property} />;
    case PropertyType.PROPERTY_GROUP:
      return <GroupProperty property={property} children={children}/>
    default:
      return null;
  }
}

export default Preview;
