import { useCallback, useEffect, useState } from "react";
import {postData} from "../../utils/utils";
import Paging from "../../models/Pageable";
import EntityActivity from "../../models/EntityActivity";

export const useEntityActivity = (
  uuid: string | undefined,
  url: string,
  open: boolean,
  events: string[],
  filters: string[],
): [
  activityData: Paging<EntityActivity>,
  loadMore: (page: number) => void,
  isLoading: boolean,
  error: string | null
] => {
  const [activityData, setActivityData] = useState<Paging<EntityActivity>>(
    new Paging()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!uuid || !open || !events.length) {
      setActivityData(new Paging())
      return;
    }
    const payload={
      eventTypes: filters.length > 0 ? filters : events
    }
    setIsLoading(true);
    (async () => {
      try {
        const data = await postData(
          `${url}/${uuid}/activity?size=20&page=0`, payload
        );
        setActivityData(data);
        setError(null);
        setIsLoading(false);
      } catch (ex: any) {
        setError(ex);
        setIsLoading(false);
      }
    })();
  }, [open, uuid, url, events, filters]);

  const loadMore = useCallback(
    async (page: number) => {
      const payload={
        eventTypes: filters.length > 0 ? filters : events
      }
      setIsLoading(true);
      postData(`${url}/${uuid}/activity?size=20&page=${page}`, payload)
        .then((data) => {
          setActivityData((prevState) => {
            return {
              ...data,
              content: [...prevState.content, ...data.content],
            };
          });
          setError(null);
          setIsLoading(false);
        })
        .catch((ex) => {
          setError(ex);
          setIsLoading(false);
        });
    },
    [uuid, url, filters, events]
  );

  return [activityData, loadMore, isLoading, error];
};
