import {useMemo} from "react";
import {PropertyType} from "../../models/PropertyType";
import {TagValueType, TagValueTypeProps} from "../../models/TagValueType";

export const useTagValueTypeOptions = ((type: PropertyType, tagValueType: TagValueTypeProps[]) => {

    const datastreamTagValueTypeOptions = tagValueType.filter((x) => {
        return x.id === TagValueType.ALL || x.id === TagValueType.LABEL || x.id === TagValueType.FILENAME || x.id === TagValueType.URL || x.id === TagValueType.THUMBURL
    });

    const selectTagValueTypeOptions = tagValueType.filter((x) => {
        return x.id === TagValueType.ALL || x.id === TagValueType.LABEL || x.id === TagValueType.VALUE
    });

    const vocabularyTagValueTypeOptions = tagValueType.filter(x => {
        return x.id === TagValueType.ALL || x.id === TagValueType.UUID || x.id === TagValueType.LABEL || x.id === TagValueType.TERM_ID || x.id === TagValueType.TERM_URI
    })

    return useMemo(() => {
        if (type === PropertyType.DATASTREAM_REF) return datastreamTagValueTypeOptions
        else if (type === PropertyType.VOCABULARY) return vocabularyTagValueTypeOptions
        else return selectTagValueTypeOptions
    }, [type, datastreamTagValueTypeOptions, vocabularyTagValueTypeOptions, selectTagValueTypeOptions])
})