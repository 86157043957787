import {
  CardActionArea,
  Collapse,
  Stack,
  SvgIconTypeMap,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import * as React from "react";
import { useState } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";

interface MenuItemType {
  path: string;
  name: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  items?: MenuItemType[];
}

interface MenuItemProps {
  item: MenuItemType;
  isActive: (path: string) => boolean;
  expanded: boolean;
}

const getStyles = (active: boolean) => ({
  bgcolor: active ? "primary.dark" : "primary.main",
  px: 1.5,
  py: 1.25,
  borderRadius: 1,
  color: "primary.contrastText",
  fontWeight: "bold",
  opacity: active ? 1 : 0.8,
  "&:hover": { opacity: 1 }
});

export default function MenuItem(props: MenuItemProps) {
  const { item } = props;

  if (item.items) {
    return <ExpandableItem {...props} />;
  }

  return <SingleItem {...props} />;
}

function ExpandableItem(props: MenuItemProps) {
  const { item, isActive, expanded } = props;
  const [open, setOpen] = useState(false);

  return (
    <Stack>
      <CardActionArea
        onClick={() => setOpen(!open)}
        sx={{ ...getStyles(false) }}
      >
        <Stack direction="row" columnGap={1.5} alignItems="center">
          {expanded && <item.icon fontSize="small" />}
          {!expanded && (
            <Tooltip placement={"right"} title={item.name}>
              <item.icon fontSize="small" />
            </Tooltip>
          )}
          {expanded && (
            <Typography
              noWrap
              variant="body2"
              sx={{ textDecoration: "none", fontWeight: "bold" }}
            >
              {item.name}
            </Typography>
          )}
          {expanded && (
            <ExpandCircleDownOutlinedIcon
              fontSize={"small"}
              sx={{ ml: "auto", transform: open ? "rotate(180deg)" : "none" }}
            />
          )}
        </Stack>
      </CardActionArea>
      <Collapse in={open}>
        <Stack ml={expanded ? 1 : 0}>
          {item.items!.map((item) => {
            return (
              <SingleItem
                key={item.path}
                item={item}
                isActive={isActive}
                expanded={expanded}
              />
            );
          })}
        </Stack>
      </Collapse>
    </Stack>
  );
}

function SingleItem(props: MenuItemProps) {
  const { item, isActive, expanded } = props;
  const active = isActive(item.path);

  return (
    <Link to={`/${item.path}`} style={{ textDecoration: "none" }}>
      <CardActionArea sx={{ ...getStyles(active) }}>
        <Stack direction="row" columnGap={1.5} alignItems="center">
          {expanded && <item.icon fontSize="small" />}
          {!expanded && (
            <Tooltip placement={"right"} title={item.name}>
              <item.icon fontSize="small" />
            </Tooltip>
          )}
          {expanded && (
            <Typography
              noWrap
              variant="body2"
              sx={{ textDecoration: "none", fontWeight: "bold" }}
            >
              {item.name}
            </Typography>
          )}
        </Stack>
      </CardActionArea>
    </Link>
  );
}
