enum EntityType {
  schema,
  property,
  propertyGroup,
  attribute,
  containerType,
  datastreamType,
  user,
  tag,
  vocabulary,
  namespace,
  mapping,
  xrApplication,
  portal,
  apiKey,
  oaipmhSet,
  workflow,
  identifier
}

export default EntityType
