import { AttributeType } from "./AttributeType";
import DisplayOptions from "./DisplayOptions";
import DataOptions, {TextTransform} from "./DataOptions";
import ValidationOptions from "./ValidationOptions";
import { UseType } from "./UseType";
import {SpatialValue} from "../components/Properties/ControlledProperty/SpatialProperty";
import {PropertyType} from "./PropertyType";

class Attribute {
  uuid?: string = undefined;
  name: string = "";
  description: string = "";
  attributeType: AttributeType = AttributeType.TEXT;
  displayOptions?: DisplayOptions = {};
  dataOptions?: DataOptions = {};
  validationOptions?: ValidationOptions = {};
  use?: UseType = UseType.OPTIONAL;


  static getPropertyAssociateType(attributeType: AttributeType) {
    switch (attributeType) {
      case AttributeType.TEXT: return PropertyType.TEXT_FIELD
      case AttributeType.SELECT: return PropertyType.SELECT
      case AttributeType.URL: return PropertyType.URL
    }
  }
};

export default Attribute;


export class FlatAttribute {
  uuid = undefined;
  name = "";
  description = "";
  attributeType = AttributeType.TEXT;
  prefix = "";
  suffix = "";
  tooltip = "";
  placeholder = "";
  showCharCounter = false;
  showWordCounter = false;
  textCase = TextTransform.NONE;
  defaultValue?: string | string[] | boolean | SpatialValue | number[] | number;
  maxLength = "";
  minLength = "";
  values: any = [];

}