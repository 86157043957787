import Box from "@mui/material/Box";
import FolderIcon from "../@lib/Graphics/FolderIcon";
import React from "react";
import { CoverFile } from "../../models/Container";

interface Props {
  size: 36 | 60;
  coverFile?: CoverFile;
}

function ContainerCover({ coverFile, size }: Props) {
  return coverFile?.thumbnails.mediumUrl ? (
    <img
      src={coverFile?.thumbnails.mediumUrl}
      alt={""}
      style={{
        width: size,
        height: size,
        objectFit: "cover",
        borderRadius: 8,
      }}
    />
  ) : (
    <Box sx={{ width: size, height: size, minWidth: size }}>
      <FolderIcon />
    </Box>
  );
}

export default ContainerCover;
