import { create } from "zustand";
import {createCommonStore, Entity, useEntityList} from "./core";
import { API_URL } from "../utils/config";
import Namespace from "../models/Namespace";


export const useNamespaceStore = create<Entity<Namespace>>(createCommonStore(`${API_URL}/cm/property_namespaces/all`, "prefix"));

export const useNamespaceList = (): [Namespace[], boolean] => {
  const store = useNamespaceStore()
  return useEntityList(store)
};

