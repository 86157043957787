import EntityType from "./EntityType";

export const EntityTitle = {
  [EntityType.schema]: "entity.schema",
  [EntityType.property]: "entity.property",
  [EntityType.propertyGroup]: "entity.propertyGroup",
  [EntityType.attribute]: "entity.attribute",
  [EntityType.containerType]:"entity.containerType",
  [EntityType.datastreamType]:"entity.DatastreamType",
  [EntityType.user]:"entity.user",
  [EntityType.tag]:"entity.tag",
  [EntityType.vocabulary]:"entity.vocabulary",
  [EntityType.namespace]:"entity.namespace",
  [EntityType.mapping]:"entity.mapping",
  [EntityType.apiKey]:"entity.apiKey",
  [EntityType.xrApplication]:"entity.application",
  [EntityType.portal]:"entity.portal",
  [EntityType.oaipmhSet]:"entity.oaipmhSet",
  [EntityType.workflow]:"entity.workflows",
  [EntityType.identifier]:"entity.id",
};
