import React, { useMemo } from "react";
import { MuiSelector } from "components/@lib/Inputs/Selector";
import PropertyReadOnlyPreview from "../../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import { FlatProperty } from "models/FlatProperty";
import { SelectOption } from "models/DataOptions";
import { sortBy } from "utils/utils";
import TableSelect from "../../@lib/Inputs/TableSelect";

type PreviewProps = {
  property: FlatProperty;
  value: string | string[];
  setValue: (newValue: any) => void;
  readonly?: boolean;
  errorMessage?: string;
  required: boolean;
  recommended: boolean;
};

function SelectProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
  errorMessage,
}: PreviewProps) {
  const options = property.values
    .map((x: SelectOption) => ({
      id: x.value,
      name: x.label,
    }))
    .sort((a: SelectOption, b: SelectOption) => sortBy(a, b, "name"));

  const valueLabel = useMemo(() => {
    // @ts-ignore
    return value ? options.filter((x) => value.includes(x.id)).map((x)=>{return x.name}) : "";
  }, [options, value]);

  if (readonly) {
    let formatted = Array.isArray(valueLabel)
      ? valueLabel.join(", ")
      : valueLabel;

    return (
      <PropertyReadOnlyPreview
        name={property.name}
        value={formatted}
        required={required}
        recommended={recommended}
      />
    );
  }

  if (property.multipleValues && Array.isArray(value)) {
    return <TableSelect
      options={options}
      label={property.name}
      value={value}
      onChange={setValue}
      name={property.name}
      required={required}
      recommended={recommended}
      helperText={errorMessage}
      error={Boolean(errorMessage)}
      multiSelect
    />
  }

  const val = Array.isArray(value) ? value[0] : value;

  return (
    <MuiSelector
      options={options}
      fullWidth
      label={property.name}
      size={"small"}
      hasNone
      value={val}
      onChange={(e) => setValue(e.target.value ? [e.target.value] : [])}
      name={property.name}
      required={required}
      recommended={recommended}
      helperText={errorMessage}
      error={Boolean(errorMessage)}
    />
  );
}

export default SelectProperty;
