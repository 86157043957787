import { Stack, SvgIconTypeMap } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Sidebar from "./SideNavigation";
import Toolbar from "./Toolbar";
import {useUIStore} from "../../../store/uiStore";
import FileManagerWidget from "../../FileManagerWidget";

interface Item {
  name: string;
  path: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  items?: Item[];
}

interface Props {
  children: React.ReactNode;
  menuItems: Item[];
  header: React.ReactNode;
  logo: React.ReactNode;
  logoIcon: React.ReactNode;
  rootPath: string
}

function ModernLayout(props: Props) {
  const { children, menuItems, header, logo, rootPath, logoIcon } = props;
  const { drawerOpen, toggleLayout } = useUIStore()

  const { t } = useTranslation();

  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };
  
  const translated = useMemo(() => {
    return menuItems.map(item => ({
      ...item,
      name: t(item.name),
      items: item.items?.map(item => ({
        ...item,
        name: t(item.name),
      }))
    }))
  }, [menuItems, t])

  return (
    <Stack sx={{ width: "100vw", height: "100vh", display: "flex" }} direction={"row"}>
      
      {/* SideNavigation */}
      <Sidebar 
        menuItems={translated}
        expanded={drawerOpen}
        isActive={isActive}
        toggleSidebar={toggleLayout}
        logo={logo}
        logoIcon={logoIcon}
        rootPath={rootPath}
      />

      <Stack sx={{ flex: 1, overflowY: "auto" }}>
        
        {/* Toolbar */}
        <Toolbar>
          {header}
        </Toolbar>

        {/* Main Content */}
        <Stack sx={{ p: 2 }}>
          {children}
        </Stack>

        <FileManagerWidget />
        
      </Stack>
    </Stack>
  );
}

export default ModernLayout;
