import * as React from "react";
import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import DateProperty from "../ControlledProperty/DateProperty";
import {FlatProperty} from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
};

const initialState = ""

function TextFieldPreview(props: PreviewProps) {
  const { property } = props;
  const [value, setValue] = useState(initialState);

  usePreviewDefaultValue<string>(property.defaultValue, setValue, initialState);

  return (
    <DateProperty
      property={property}
      value={value}
      setValue={setValue}
      required={false}
      recommended={false}
    />
  );
}

export default TextFieldPreview;
