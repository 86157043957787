import Dialog from "../../../../../components/@lib/Dialog";
import * as React from "react";
import { useEffect } from "react";
import * as yup from "yup";
import Attribute, { FlatAttribute } from "../../../../../models/Attribute";
import useFormElements from "../../../../../hooks/form/useFormElements";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { fetchData, postData, updateData } from "../../../../../utils/utils";
import { API_URL } from "../../../../../utils/config";
import {
  convertAttributeToForm,
  convertFlatToAttribute,
  useAttributeFormElements,
} from "./form-elements";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { clearDataOptionValues } from "../../../../../utils/property";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  attribute?: Attribute;
  onCreate: (payload: any) => void;
  onUpdate: (payload: any) => void;
};

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
});

function AttributeDialog({
  open,
  handleClose,
  attribute,
  onUpdate,
  onCreate,
}: DialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    values,
    handleChange,
    setValues,
    isSubmitting,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: new FlatAttribute(),
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const attribute = convertFlatToAttribute(values);
      const type = Attribute.getPropertyAssociateType(attribute.attributeType);
      const payload = {
        ...attribute,
        dataOptions: {
          ...attribute.dataOptions,
          values: clearDataOptionValues(type, attribute.dataOptions?.values),
        },
      };
      try {
        if (values.uuid) {
          const response = await updateData(
            `${API_URL}/cm/attributes/${attribute.uuid}`,
            payload
          );
          onUpdate && onUpdate(response);
          handleClose();
        } else {
          const response = await postData(`${API_URL}/cm/attributes`, payload);
          onCreate && onCreate(response);
          handleClose();
        }
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setValues(new FlatAttribute());
    if (!attribute || !open) return;
    fetchData(`${API_URL}/cm/attributes/${attribute.uuid}`)
      .then((data) => {
        setValues(convertAttributeToForm(data));
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, [attribute, setValues, open]);

  const formElements = useAttributeFormElements(
    values.attributeType,
    values,
    values.uuid
  );
  const form = useFormElements({
    formElements,
    values,
    setValues,
    handleChange,
    inProgress: isSubmitting,
  });

  const onClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      title={t("attributes.modal.title").toString()}
      onSubmit={handleSubmit}
      secondaryAction={onClose}
      primaryActionTitle={
        attribute
          ? t("buttons.update").toString()
          : t("buttons.create").toString()
      }
      secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
      maxWidth={"md"}
      fullWidth
      inProgress={isSubmitting}
    >
      <Stack rowGap={2}>{form}</Stack>
    </Dialog>
  );
}

export default AttributeDialog;
