import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import PropertyReadOnlyPreview from "../../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import { DateField } from "@mui/x-date-pickers/DateField/DateField";
import {FlatProperty} from "../../../models/FlatProperty";
import moment from "moment-timezone";

type PreviewProps = {
  property: FlatProperty;
  value: string;
  required: boolean;
  recommended: boolean;
  setValue: (s: string) => void;
  readonly?: boolean;
  errorMessage?: string;
};

function DateProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
  errorMessage
}: PreviewProps) {
  const timezone = localStorage.getItem("timezone") || "Europe/Athens"

  if (readonly) {
    const { mask } = property
    const formatted = mask && value ? moment.utc(value).tz(timezone).format(mask) : value
    return (
      <PropertyReadOnlyPreview name={property.name} value={formatted} required={required} recommended={recommended}/>
    )
  }

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <TextField
        variant={"standard"}
        fullWidth
        required={required}
        // required
        type={"date"}
        size={"small"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        label={<>{property.name} {recommended && <sup>(Rec)</sup>}</>}
        placeholder={property.placeholder}
        InputProps={{
          inputProps: { min: property.minValue, max: property.maxValue },
          startAdornment: property.prefix && (
            <InputAdornment position="start">
              <DateField/>
            </InputAdornment>
          ),
        }}
        helperText={errorMessage}
        error={Boolean(errorMessage)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Tooltip>
  );
}

export default DateProperty;
