import { ClickAwayListener, Popper } from "@mui/material";
import GlobalSearchField from "components/GlobalSearch/GlobalSearchField";
import { GlobalSearchPopper } from "components/GlobalSearch/GlobalSearchPopper";
import SettingsPopper from "components/GlobalSearch/SettingsPopper";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/query/useQuery";
import Container from "models/Container";
import { FullTextSearchDatastream } from "models/Datastream";
import ReservedUUID from "models/ReservedUUID";
import { postData } from "utils/utils";
import { API_URL } from "utils/config";
import Popover from "@mui/material/Popover";

function StoreSearch() {
  const searchParams = useQuery();
  const textQuery = searchParams.get("text") ?? "";
  const [searchResultContainers, setSearchResultContainers] = useState<Container[]>([]);
  const [searchResultDatastreams, setSearchResultDatastreams] = useState<FullTextSearchDatastream[]>([]);

  const [value, setValue] = useState<string>(textQuery);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setValue(textQuery);
  }, [textQuery]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
  };
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElSettings);
  const id = open ? "simple-popper" : undefined;
  const idSettings = openSettings ? "simple-popper" : undefined;

  useEffect(() => {
    if (!value) {
      return;
    }

    const payload = {
      location: ReservedUUID.MyContentRoot,
      value: value,
      labelSensitivity: "CASE_INSENSITIVE",
      owner: "ANYONE",
      type: "ALL",
      filetype: [],
      containerTypes: [],
      datastreamTypes: [],
      isTrashed: false,
      publishStatus: "ALL",
      propertyValueFilters: [{
        propertyUuid: "ANY",
        propertyValue: value,
        logicalOperator: "OR",
        operator: "CONTAINS",
      }],
      fulltextSearch: false,
    };

    let timeout = setTimeout(() => {
      postData(`${API_URL}/search`, payload)
        .then((data) => {
          setSearchResultContainers(data.containers.content);
          setSearchResultDatastreams(data.datastreams.content);
        })
        .catch((ex) => console.log(ex));
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const routeChange = (parameters: string) => {
    navigate(`/store/search${parameters}`);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <GlobalSearchField
          value={value}
          setValue={setValue}
          handleClick={handleClick}
          handleClickSettings={handleClickSettings}
          handleClose={handleClose}
          onEnter={routeChange}
        />
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          sx={{ zIndex: 10000 }}
        >
          <GlobalSearchPopper
            value={value}
            containers={searchResultContainers}
            datastreams={searchResultDatastreams}
            handleClose={handleClose}
            onFilterClick={routeChange}
          />
        </Popper>
        <Popover
          id={idSettings}
          open={openSettings}
          anchorEl={anchorElSettings}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setAnchorElSettings(null)}
          sx={{ zIndex: 10000 }}
          PaperProps={{
            sx: {
              marginTop: 0.5,
              marginLeft: 5.2,
            },
          }}
        >
          <SettingsPopper
            open={openSettings}
            handleClose={() => setAnchorElSettings(null)}
          />
        </Popover>
      </div>
    </ClickAwayListener>
  );
}

export default StoreSearch;
