import { AsyncOption } from "components/@lib/Inputs/AsyncSelect";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import Dialog from "components/@lib/Dialog";
import { useDefaultTable } from "hooks/defaultTable/useDefaultTable";
import { useSortHandler } from "hooks/sort/useSortHandler";
import DefaultTable from "components/DefaultTable";
import TableSelectorButton from "components/@lib/Inputs/Common/TableSelectorButton";
import SearchField from "components/@lib/Inputs/SearchField";
import Stack from "@mui/material/Stack";
import usePaginatedSearch from "hooks/common/usePaginatedSearch";

type Props = {
  url: string;
  label: string;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  name: string;
  value?: string | string[];
  onChange: (selected: string[]) => void;
  multiSelect?: boolean;
  disabled?: boolean;
  defaultValue?: any[];
  formatOptions?: (options: any) => AsyncOption[];
  customHeadCells?: HeadCell[]
};

interface HeadCell {
  id: string
  label: string
}

const headCells = [
  {
    id: "label",
    label: "Label",
  },
];

function AsyncTableSelect(props: Props) {
  const [open, setOpen] = useState(false);

  const {
    url,
    label,
    required,
    recommended,
    onChange,
    error,
    helperText,
    value,
    disabled,
    defaultValue,
    multiSelect,
    formatOptions,
    customHeadCells
  } = props;

  const {
    setPage,
    page,
    results,
    term,
    setTerm,
    loading
  } = usePaginatedSearch<AsyncOption>(url);

  const sortHandler = useSortHandler();
  const {
    renderDefaultBody,
    renderHeadCell,
    renderCheckAllCell,
    setSelected,
    selected,
  } = useDefaultTable(results.content, sortHandler, "uuid", true, multiSelect);

  useEffect(() => {
    if (open) {
      if (Array.isArray(value)) {
        // @ts-ignore
        setSelected(value)
      } else if (typeof value == "string"){
        // @ts-ignore
        setSelected([value])
      }
    }
  }, [open, setSelected, value]);

  const handleConfirmSelection = () => {
    setOpen(false);
    onChange(selected);
  };

  const rows = useMemo(() => {
    return formatOptions ? formatOptions(results.content) : results.content
  }, [formatOptions, results.content])

  const valueString = useMemo(() => {
    if (multiSelect && Array.isArray(value)) {
      if (rows.length === 0) return undefined
      const valueStringFallback = value.map(uuid => {
        const item = rows.find(x => x.uuid === uuid)
        return item?.label ?? "N/A"
      })
      return valueStringFallback.join(", ")

    } else {
      return rows.find(x => x.uuid === value)?.label
    }
  }, [multiSelect, rows, value])

  const defaultValueString = useMemo(() => {
    if (multiSelect && Array.isArray(value)) {
      return defaultValue?.map(x => x.label).join(", ")

    } else {
      if (defaultValue && defaultValue[0] && defaultValue[0].label) {
        return defaultValue[0].label
      }
    }
  }, [defaultValue, multiSelect, value])


  return (
    <Fragment>
      <TableSelectorButton
        handleClick={() => setOpen(true)}
        label={label}
        required={required}
        recommended={recommended}
        disabled={disabled}
        valueString={valueString ?? defaultValueString}
        error={error}
        helperText={helperText}
      />

      <Dialog
        primaryAction={handleConfirmSelection}
        maxWidth={"sm"}
        fullWidth
        open={open}
        handleClose={() => setOpen(false)}
        title={label}
        inProgress={loading}
      >
        <Stack rowGap={2}>
          <SearchField value={term} setValue={setTerm} />
          <DefaultTable
            selectableRows
            renderCheckAllCell={renderCheckAllCell}
            headCells={customHeadCells ?? headCells}
            renderDefaultBody={renderDefaultBody}
            renderHeadCell={renderHeadCell}
            rows={rows}
            totalPages={results.totalPages}
            page={page}
            setPage={setPage}
          />
        </Stack>
      </Dialog>
    </Fragment>
  );
}

export default AsyncTableSelect;
