import { create } from "zustand";
import {createCommonStore, Entity, useEntityList} from "./core";
import { API_URL } from "../utils/config";
import Property from "../models/Property";


export const usePropertyStore = create<Entity<Property>>(createCommonStore(`${API_URL}/cm/properties/all`, "uuid"));

export const usePropertyList = (): [Property[], boolean] => {
  const store = usePropertyStore()
  return useEntityList(store)
};



