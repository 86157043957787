import * as React from "react";
import { UsageEntityType } from "../../../../../hooks/contentModel/useSchemaUsage";
import { useSortHandler } from "../../../../../hooks/sort/useSortHandler";
import useSortedList from "../../../../../hooks/sort/useSortedList";
import { useDefaultTable } from "../../../../../hooks/defaultTable/useDefaultTable";
import Dialog from "../../../../../components/@lib/Dialog";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Selector from "components/@lib/Inputs/Selector";
import DefaultTable from "../../../../../components/DefaultTable";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
//import useFilterArray from "../../../../hooks/common/useFilterArray";

type Results = {
  [key: number]: UsageEntityType[];
};

export enum UsageRelationship {
  containerTypes,
  datastreamTypes,
  containers,
  datastreams,
  schemas,
  properties,
}

type Option = {
  id: UsageRelationship;
  name: string;
  disabled?: boolean;
};

type UsagesDialogProps = {
  open: boolean;
  handleClose: () => void;
  value: UsageRelationship;
  setValue: React.Dispatch<React.SetStateAction<UsageRelationship>>;
  onItemClick: (
    item: UsageEntityType,
    usageRelationship: UsageRelationship
  ) => void;
  contents: Results;
  isLoading: boolean;
  options: Option[];
  title: string;
  page?: number;
  totalElements?: number;
  totalPages?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  text?: string;
};

const headCells = [
  {
    id: "name",
    label: "table.name",
    isSortable: true,
  },
];

function UsagesDialog(props: UsagesDialogProps) {
  const {
    open,
    handleClose,
    value,
    setValue,
    options,
    isLoading,
    contents,
    onItemClick,
    title,
    page,
    setPage,
    totalElements,
    totalPages,
  } = props;
  const { t } = useTranslation();
  const results = contents[value] ?? [];

  const sortHandler = useSortHandler();
  //const filtered = useFilterArray(results, term, ["name"]);
  const sorted = useSortedList(sortHandler, results);

  const { renderDefaultBody, renderHeadCell } = useDefaultTable(
    sorted,
    sortHandler,
    "uuid",
    false
  );

  const handleItem = (rowItem: UsageEntityType) => {
    onItemClick(rowItem, value);
  };

  const optionName = useMemo(() => {
    return options.find((x) => x.id === value) ?? options[0];
  }, [value, options]);

  return (
    <Dialog
      primaryAction={handleClose}
      primaryActionTitle={t("buttons.close").toString()}
      fullWidth
      maxWidth={"sm"}
      title={title}
      open={open}
      handleClose={handleClose}
      inProgress={isLoading}
    >
      <Stack direction={"column"} rowGap={2}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <div style={{ width: 200 }}>
            <Selector
              label={t("formElement.in").toString()}
              fullWidth
              options={options}
              onChange={(e) => setValue(parseInt(e.target.value))}
              value={value}
              name={"Relationship"}
            />
          </div>
          {!isLoading ? (
            <Typography variant={"body1"}>
              {optionName.name} {t("viewUsage.modal.count")}: {totalElements}
            </Typography>
          ) : (
            <Typography variant={"body1"}>{t("search.loading")}.</Typography>
          )}
        </Stack>
        {isLoading ? null : (
          <DefaultTable
            headCells={headCells}
            renderDefaultBody={renderDefaultBody}
            renderHeadCell={renderHeadCell}
            onRowClick={handleItem}
            rows={sorted}
            disableRowGap
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
        )}
      </Stack>
    </Dialog>
  );
}

export default UsagesDialog;
