import { CardActionArea, Stack, SvgIconTypeMap } from "@mui/material";
import * as React from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import MenuItem from "./MenuItem";
import { ReactNode } from "react";
import {Link} from "react-router-dom";
import StorageInfoWidget from "../StorageInfoWidget";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface MenuItemType {
  name: string;
  path: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  items?: MenuItemType[];
}

interface SidebarProps {
  menuItems: MenuItemType[];
  expanded: boolean;
  isActive: (path: string) => boolean;
  toggleSidebar: () => void;
  logo: ReactNode;
  logoIcon: ReactNode;
  rootPath: string;
}

const btnStyles = {
  px: 1.5,
  display: "flex",
  py: 1.25,
  width: 44,
  borderRadius: 1,
  color: "primary.contrastText",
  fontWeight: "bold",
}

function Sidebar(props: SidebarProps) {
  const { menuItems, expanded, isActive, toggleSidebar, logo, rootPath, logoIcon } = props;

  return (
    <Stack
      direction={"column"}
      sx={{
        py: 2,
        px: 1.5,
        zIndex: 1200,
        bgcolor: "primary.main",
        width: expanded ? 240 : 68,
        flexShrink: 0,
        transition: "width 0.3s ease-in-out",
        overflowX: "hidden",
      }}
    >
      <Stack direction={"row"} mb={2} alignItems={"center"} columnGap={1} mt={-1}>
        <Link to={rootPath} style={{ textDecoration: "none" }}>
          <Stack sx={{ flex: 1, color: "primary.contrastText", height: "42px", width: "auto" }}>
            {expanded ? logo : logoIcon}
          </Stack>
        </Link>
      </Stack>

      {menuItems.map((menuItem) => {
        return (
          <MenuItem
            key={menuItem.path}
            item={menuItem}
            isActive={isActive}
            expanded={expanded}
          />
        );
      })}

      <Stack mt={"auto"} direction={"row"} alignItems={"flex-end"} columnGap={1}>
        {expanded && (
          <CardActionArea onClick={toggleSidebar} sx={{ ...btnStyles }}>
            <ChevronLeftIcon fontSize={"small"} />
          </CardActionArea>
        )}
        <StorageInfoWidget />
      </Stack>

      {!expanded && (
        <CardActionArea onClick={toggleSidebar} sx={{ ...btnStyles }}>
          <ChevronRightIcon fontSize={"small"} />
        </CardActionArea>
      )}
    </Stack>
  );
}

export default Sidebar;
