import {SvgIconProps} from "@mui/material/SvgIcon";
import MapIcon from "@mui/icons-material/Map";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import WebIcon from "@mui/icons-material/Web";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import TheatersOutlinedIcon from "@mui/icons-material/TheatersOutlined";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import IAMPermission, {
  AI_PERMISSIONS,
  GEO_PERMISSIONS, GUIDE_PERMISSIONS,
  PORTAL_PERMISSIONS,
  STORE_PERMISSIONS, STORY_PERMISSIONS, SURVEY_PERMISSIONS,
  XR_PERMISSIONS
} from "./IAMPermission";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import {StoreLogoIconOnly} from "../components/@lib/Graphics/StoreLogo";
import {GeoLogoIconOnly} from "../components/@lib/Graphics/GeoLogo";
import {GuideLogoIconOnly} from "../components/@lib/Graphics/GuideLogo";
import {SurveyLogoIconOnly} from "../components/@lib/Graphics/SurveyLogo";
import {StoriesLogoIconOnly} from "../components/@lib/Graphics/StoriesLogo";

export enum RepoxAddonType {
  surveys = "REPOX_SURVEY",
  guide = "REPOX_GUIDE",
  portal = "REPOX_PORTAL",
  geo = "REPOX_GEO",
  ai = "REPOX_AI",
  xr = "REPOX_XR",
  stories = "REPOX_STORY",
  store = "REPOX_STORE",
}

export interface RepoxAddonInfo {
  id: RepoxAddonType
  name: string
  icon: React.ElementType<SvgIconProps>;
  info: string
  color: string
  bgColor: string,
  path: string
  permissions: IAMPermission[]
}

const StoreAddon: RepoxAddonInfo = {
  id: RepoxAddonType.store,
  name: "Store",
  icon: StoreLogoIconOnly,
  color: "#396afc",
  bgColor: "e6f4ea",
  info: "",
  path: "/store/content",
  permissions: STORE_PERMISSIONS
}

const GeoAddon: RepoxAddonInfo = {
  id: RepoxAddonType.geo,
  name: "Geo",
  icon: GeoLogoIconOnly,
  color: "#BD2D87",
  bgColor: "e6f4ea",
  info: "",
  path: "/geo",
  permissions: GEO_PERMISSIONS
}

const AIAddon: RepoxAddonInfo = {
  id: RepoxAddonType.ai,
  name: "AI Assistant",
  icon: TipsAndUpdatesOutlinedIcon,
  color: "#77DD77",
  bgColor: "e6f4ea",
  info: "",
  path: "/store/chat-gpt",
  permissions: AI_PERMISSIONS
}

const XRAddon: RepoxAddonInfo = {
  id: RepoxAddonType.xr,
  name: "XR",
  icon: ExploreOutlinedIcon,
  color: "#2D99FF",
  bgColor: "e6f4ea",
  info: "",
  path: "/xr/applications",
  permissions: XR_PERMISSIONS
}

const PortalAddon: RepoxAddonInfo = {
  id: RepoxAddonType.portal,
  name: "Portal",
  icon: WebIcon,
  color: "#FF6C40",
  bgColor: "e6f4ea",
  info: "",
  path: "/portal",
  permissions: PORTAL_PERMISSIONS
}

const GuideAddon: RepoxAddonInfo = {
  id: RepoxAddonType.guide,
  name: "Guide",
  icon: GuideLogoIconOnly,
  color: "#2D99FF",
  bgColor: "e6f4ea",
  info: "",
  path: "/guide",
  permissions: GUIDE_PERMISSIONS
}

const SurveyAddon: RepoxAddonInfo = {
  id: RepoxAddonType.surveys,
  name: "Surveys",
  icon: SurveyLogoIconOnly,
  color: "#a250f5",
  bgColor: "f3e8fd",
  info: "",
  path: "/surveys",
  permissions: SURVEY_PERMISSIONS
}

const StoriesAddon: RepoxAddonInfo = {
  id: RepoxAddonType.stories,
  name: "Stories",
  icon: StoriesLogoIconOnly,
  color: "#009688",
  bgColor: "fcefe3",
  info: "",
  path: "/stories",
  permissions: STORY_PERMISSIONS
}

const addonsMap = new Map<RepoxAddonType, RepoxAddonInfo>()
addonsMap.set(RepoxAddonType.geo, GeoAddon)
addonsMap.set(RepoxAddonType.ai, AIAddon)
addonsMap.set(RepoxAddonType.xr, XRAddon)
addonsMap.set(RepoxAddonType.portal, PortalAddon)
addonsMap.set(RepoxAddonType.guide, GuideAddon)
addonsMap.set(RepoxAddonType.surveys, SurveyAddon)
addonsMap.set(RepoxAddonType.stories, StoriesAddon)

export {
  addonsMap,
  SurveyAddon,
  GeoAddon,
  PortalAddon,
  XRAddon,
  AIAddon,
  StoriesAddon,
  GuideAddon,
  StoreAddon,
}