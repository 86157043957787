import React, { ReactNode, useCallback, useState } from "react";
import ConfirmationDialog from "../../components/@lib/ConfirmationDialog";
import { deleteData } from "../../utils/utils";
import { useSnackbar } from "notistack";
import EntityType from "../../models/Core/EntityType";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../models/Core/AppRoute";
import { ApiEndpoint } from "../../models/Core/ApiEndpoint";
import {EntityTitle} from "../../models/Core/EntityTitle";
import {useTranslation} from "react-i18next";

type UseDeleteEntityOutput = [() => void, ReactNode];

export const useDeleteEntity = (
  entityType: EntityType,
  id: string | undefined,
  onDelete?: () => void,
): UseDeleteEntityOutput => {
  const [deleting, setDeleting] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDeleteConfirm = async () => {
    setDeleting(true);
    try {
      await deleteData(`${ApiEndpoint[entityType]}/${id}`);
      setDeleting(false);
      if (onDelete) {
        setOpenConfirmation(false);
        onDelete()
      } else {
        navigate(AppRoute[entityType]);
      }
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setDeleting(false);
  };

  const handleDelete = useCallback(() => {
    setOpenConfirmation(true);
  }, []);

  const renderDialog = () => (
    <ConfirmationDialog
      open={openConfirmation}
      handleClose={() => setOpenConfirmation(false)}
      title={t("deleteConfirmation.modal.title")}
      message={t("deleteConfirmation.modal.msg").replace(
        "{ENTITY_NAME}",
        t(EntityTitle[entityType])
      )}
      primaryAction={handleDeleteConfirm}
      inProgress={deleting}
      secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
      primaryActionTitle={t("buttons.delete").toString()}
    />
  );

  return [handleDelete, renderDialog()];
};
