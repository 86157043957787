import { AsyncOption } from "components/@lib/Inputs/AsyncSelect";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Dialog from "components/@lib/Dialog";
import { useDefaultTable } from "hooks/defaultTable/useDefaultTable";
import { useSortHandler } from "hooks/sort/useSortHandler";
import DefaultTable from "components/DefaultTable";
import { fetchData } from "utils/utils";
import TableSelectorButton from "components/@lib/Inputs/Common/TableSelectorButton";

type Props = {
  url: string;
  label: string;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  name: string;
  value?: string | string[];
  onChange: (selected: string[]) => void;
  multiSelect?: boolean;
  disabled?: boolean;
  defaultValue?: any[];
};

const headCells = [
  {
    id: "label",
    label: "Label",
  },
];

function sortByLabel(a: AsyncOption, b: AsyncOption) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  return 0;
}

function AsyncFlatTableSelect(props: Props) {
  const [open, setOpen] = useState(false);

  const {
    url,
    label,
    required,
    recommended,
    onChange,
    error,
    helperText,
    value,
    disabled,
    defaultValue,
    multiSelect,
  } = props;

  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState<AsyncOption[]>([]);

  const sortHandler = useSortHandler();
  const {
    renderDefaultBody,
    renderHeadCell,
    renderCheckAllCell,
    setSelected,
    selected,
  } = useDefaultTable(results, sortHandler, "uuid", true, multiSelect);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const results = await fetchData(url);
        setResults(
          results.sort((a: AsyncOption, b: AsyncOption) => sortByLabel(a, b))
        );
      } catch (ex) {
        setResults([]);
      }
      setLoading(false);
    })();
  }, [url]);

  useEffect(() => {
    if (open) {
      if (Array.isArray(value)) {
        // @ts-ignore
        setSelected(value);
      } else if (typeof value == "string") {
        // @ts-ignore
        setSelected([value]);
      }
    }
  }, [open, setSelected, value]);

  const handleConfirmSelection = () => {
    setOpen(false);
    onChange(selected);
  };

  const valueString = useMemo(() => {
    if (multiSelect && Array.isArray(value)) {
      if (results.length === 0) return undefined;
      const valueStringFallback = value.map((uuid) => {
        const item = results.find((x) => x.uuid === uuid);
        return item?.label ?? "";
      });
      return valueStringFallback.join(", ");
    } else {
      return results.find((x) => x.uuid === value)?.label;
    }
  }, [multiSelect, results, value]);

  const defaultValueString = useMemo(() => {
    if (multiSelect && Array.isArray(value)) {
      return defaultValue?.map((x) => x.label).join(", ");
    } else {
      if (defaultValue && defaultValue[0] && defaultValue[0].label) {
        return defaultValue[0].label;
      }
    }
  }, [defaultValue, multiSelect, value]);

  return (
    <Fragment>
      <TableSelectorButton
        handleClick={() => setOpen(true)}
        label={label}
        required={required}
        recommended={recommended}
        disabled={disabled}
        valueString={valueString ?? defaultValueString}
        error={error}
        helperText={helperText}
      />

      <Dialog
        primaryAction={handleConfirmSelection}
        maxWidth={"sm"}
        fullWidth
        open={open}
        handleClose={() => setOpen(false)}
        title={label}
      >
        <DefaultTable
          selectableRows
          renderCheckAllCell={renderCheckAllCell}
          headCells={headCells}
          renderDefaultBody={renderDefaultBody}
          renderHeadCell={renderHeadCell}
          isLoading={loading}
          rows={results}
        />
      </Dialog>
    </Fragment>
  );
}

export default AsyncFlatTableSelect;
