import * as React from "react";
import { FlatProperty } from "../../../models/FlatProperty";
import GroupProperty from "../ControlledProperty/GroupProperty";
import {ReactNode} from "react";

type PreviewProps = {
  property: FlatProperty;
  children?: ReactNode
};


function TextFieldPreview(props: PreviewProps) {
  const { property, children } = props;


  return (
    <GroupProperty
      property={property}
      children={children}
    />
  );
}

export default TextFieldPreview;
