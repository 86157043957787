import * as React from "react";
import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import { FlatProperty } from "../../../models/FlatProperty";
import DatastreamReferenceProperty from "components/Properties/ControlledProperty/DatastreamReferenceProperty";

type PreviewProps = {
  property: FlatProperty;
};

const initialState = "";

function DatastreamReferencePreview(props: PreviewProps) {
  const { property } = props;
  const [value, setValue] = useState(initialState);

  usePreviewDefaultValue<string>(property.defaultValue, setValue, initialState);

  return (
      <DatastreamReferenceProperty
        property={property}
        value={value}
        setValue={setValue}
        required={false}
        recommended={false}
      />
  );
}

export default DatastreamReferencePreview;
