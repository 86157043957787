import React from "react";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableLoader from "./table-loader";
import ErrorBoundary from "../ErrorBoundary";
import {useTranslation} from "react-i18next";

function DefaultTable(props) {
  const {
    renderCheckAllCell,
    headCells,
    renderHeadCell,
    renderDefaultBody,
    rows,
    selectableRows,
    onRowClick,
    isLoading,
    styleCell,
    totalPages,
    page,
    setPage,
    disableRowGap = false,
    size = "medium",
    handleAction,
    actions,
  } = props;

  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "background.neutral", borderRadius: 0.5, px: disableRowGap ? 0 : 1, pb: disableRowGap ? 0 : 1 }}
      >
        <Table
          size={size}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          className={disableRowGap ? "table-nogap" : "table-gap"}
          style={{
            borderCollapse: "separate",
            borderSpacing: disableRowGap ? 0 : "0px 8px",
          }}
        >

          <TableHead>
            {headCells &&
              <TableRow>
                {selectableRows && renderCheckAllCell && renderCheckAllCell()}
                {headCells.map((headCell) => renderHeadCell(headCell))}
              </TableRow>
            }
          </TableHead>

          {!isLoading && (
            <TableBody>
              {renderDefaultBody(
                rows,
                headCells,
                selectableRows,
                onRowClick,
                styleCell,
                actions,
                handleAction
              )}
              {rows.length === 0 && !isLoading && (
                <TableRow sx={{ backgroundColor: "background.paper" }}>
                  <TableCell
                    sx={{ p: 3 }}
                    colSpan={headCells.length + (selectableRows ? 1 : 0)}
                    align={"center"}
                  >
                    <Typography variant={"body2"}>{t("table.noResults")}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        {isLoading && <TableLoader rounded={!disableRowGap}/>}
      </TableContainer>

      {Boolean(totalPages && setPage) && (
        <Pagination
          count={totalPages}
          page={page + 1} // Increase by one due to pageable is 0 based
          onChange={(e, newPage) => setPage(newPage - 1)}
          sx={{ mt: 1 }}
        />
      )}
    </ErrorBoundary>
  );
}

export default DefaultTable;
//
// DefaultTable.propTypes = {
//   renderCheckAllCell: PropTypes.func,
//   styleCell: PropTypes.func,
//   headCells: PropTypes.array.isRequired,
//   rows: PropTypes.array.isRequired,
//   renderHeadCell: PropTypes.func.isRequired,
//   renderDefaultBody: PropTypes.func.isRequired,
//   onRowClick: PropTypes.func,
//   selectableRows: PropTypes.bool,
//   isLoading: PropTypes.bool,
// };
