import { create } from "zustand";
import { createCommonStore, Entity, useEntityList } from "./core";
import { API_URL } from "../utils/config";
import { Identifier } from "../models/Identier";

export const useIdentifierStore = create<Entity<Identifier>>(
  createCommonStore(`${API_URL}/cm/identifiers/all`, "uuid")
);

export const useIdentifierList = (): [Identifier[], boolean] => {
  const store = useIdentifierStore();
  return useEntityList(store);
};
