import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { useTranslation } from "react-i18next";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import Paging from "../../models/Pageable";
import CommentType from "../../models/Comment";
import {
  ActualDateTime,
  deleteData,
  fetchData,
  postData,
  updateData,
} from "../../utils/utils";
import { API_URL } from "../../utils/config";
import { useSnackbar } from "notistack";
import { useIAMPermission, useUserList } from "../../store/userStore";
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyle from "./mentionsInputStyle";
import { useMap } from "../../hooks/common/useMap";
import ContextActionType from "../../models/ContextActionType";
import IAMPermission from "../../models/IAMPermission";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import TableMenu from "../DefaultTable/TableMenu";
import EditIcon from "@mui/icons-material/Edit";
import mentionStyle from "./mentionStyle";
import { Link } from "react-router-dom";
import { styled } from "@mui/styles";
import { ObjectType } from "../../models/ObjectType";
import ConfirmationDialog from "../@lib/ConfirmationDialog";

type PopOverProps = {
  entityUuid: string;
  selectedObjectType?: ObjectType;
};

export const COMMENT_ACTIONS = [
  {
    name: "cm.actions.edit",
    type: ContextActionType.edit,
    Icon: EditIcon,
    textColor: "textPrimary",
    permission: IAMPermission.store_comment,
  },
  {
    name: "trash.actions.delete",
    type: ContextActionType.delete,
    Icon: DeleteForeverOutlinedIcon,
    textColor: "textPrimary",
    permission: IAMPermission.store_comment,
  },
];

const StyledLink = styled(Typography)(({ theme }) => ({
  // @ts-ignore
  color: theme.palette.primary.main,
  display: "inline",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const re = /@\[([a-zа-я\s]+)\]\(([-A-Z0-9]+)\)/imu;

const CommentsPopOver = ({ entityUuid, selectedObjectType }: PopOverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const iam = useIAMPermission();
  const { enqueueSnackbar } = useSnackbar();
  const [inProgress, setInProgress] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");
  const { t } = useTranslation();
  const [comments, setComments] = useState<Paging<CommentType>>(new Paging());
  const [addComment, setAddComment] = useState(false);
  const [textNew, setTextNew] = useState("");
  const [textEdit, setTextEdit] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);

  const [users] = useUserList();
  const userMap = useMap(users, "uuid");
  const usersDisplayData = users.map((x) => {
    return {
      id: x.uuid,
      display: x.username,
    };
  });

  const handleDeleteClick = (id: string) => {
    setCommentToDelete(id);
    setIsDialogOpen(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getComments = useCallback(async () => {
    const url =
      selectedObjectType === ObjectType.CONTAINER
        ? `${API_URL}/comments?type=CONTAINER`
        : `${API_URL}/comments?type=DATASTREAM`;
    try {
      const data = await fetchData(
        `${url}&id=${entityUuid}&sort=createdAt,desc`
      );
      setComments(data);
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  }, [enqueueSnackbar, entityUuid, selectedObjectType]);

  useEffect(() => {
    if (!open) return;
    setInProgress(true);
    getComments().then(() => setInProgress(false));
  }, [entityUuid, open, getComments]);

  // const usersMentioned = (text: string) => {
  //   return text.match(/[^(]+(?=\))/g);
  // };

  const handleAddComment = async () => {
    // const mentions = usersMentioned(value);
    const payload = {
      objectType: selectedObjectType,
      objectId: entityUuid,
      text: textNew,
      replyTo: "",
    };
    try {
      await postData(`${API_URL}/comments`, payload);
      setAddComment(false);
      setTextNew("");
      setInProgress(true);
      getComments().then(() => setInProgress(false));
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteData(`${API_URL}/comments/${commentToDelete}`, "");
      setInProgress(true);
      getComments().then(() => {
        setInProgress(false)
      })
      setIsDialogOpen(false);
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };

  const handleEditComment = async () => {
    try {
      await updateData(`${API_URL}/comments/${selectedComment}`, {
        text: textEdit,
      });
      setTextEdit("");
      setIsEditable(false);
      setInProgress(true);
      getComments().then(() => setInProgress(false));
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };

  const handleAction = (actionID: ContextActionType, entityID: string) => {
    setSelectedComment(entityID);
    switch (actionID) {
      case ContextActionType.delete:
        /*handleDelete(entityID);*/
        handleDeleteClick(entityID)
        break;
      case ContextActionType.edit:
        setIsEditable(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const selected =
      comments.content.find((x) => x.uuid === selectedComment) ??
      new CommentType();
    setTextEdit(selected.text);
  }, [selectedComment, comments.content]);


  return (
    <Stack flexDirection={"column"} alignItems={"flex-end"}>
      <Tooltip title={t("content.comments").toString()}>
        <IconButton onClick={handleClick} size={"small"}>
          <CommentOutlinedIcon color={"action"} fontSize={"small"} />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginTop: 8,
            width: 400,
            maxHeight: "80%",
          },
        }}
      >
        <Stack
          pl={2}
          py={1}
          pr={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Typography variant={"body1"}>{t("content.comments")}</Typography>
          {iam.has(IAMPermission.store_comment) && (
            <Tooltip title={t("content.comments.add").toString()}>
              <IconButton
                onClick={() => setAddComment(true)}
                disabled={isEditable}
              >
                <AddCommentOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Divider />
        {addComment && (
          <Stack>
            <div style={{ margin: 10 }}>
              <MentionsInput
                value={textNew}
                onChange={(e) => setTextNew(e.target.value)}
                style={mentionsInputStyle}
              >
                <Mention
                  trigger="@"
                  data={usersDisplayData}
                  style={mentionStyle}
                />
              </MentionsInput>
            </div>
            <Stack
              direction={"row"}
              columnGap={1}
              mr={1}
              mb={1}
              justifyContent={"flex-end"}
            >
              <Button
                onClick={() => {
                  setTextNew("");
                  setAddComment(false);
                }}
                size={"small"}
                sx={{ textTransform: "none" }}
              >
                {t("buttons.cancel.lowercase")}
              </Button>
              <Button
                onClick={handleAddComment}
                size={"small"}
                variant={"contained"}
                sx={{ textTransform: "none" }}
              >
                {t("buttons.comment")}
              </Button>
            </Stack>
          </Stack>
        )}
        <Divider />
        {inProgress ? (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Fragment>
            {comments.content.map((item, index) => (
              <Card sx={{ m: 1 }} key={index}>
                <Stack flexDirection={"column"} rowGap={1} m={1}>
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    columnGap={2}
                  >
                    <Avatar />

                    <Stack flexDirection={"column"} alignItems={"flex-start"}>
                      <Typography noWrap variant={"body1"}>
                        {userMap.get(item.createdBy)?.username}
                      </Typography>
                      <Typography noWrap color={"primary"} variant={"body2"}>
                        {ActualDateTime(item.createdAt)}
                      </Typography>
                    </Stack>
                    <div style={{ flexGrow: 1 }}></div>
                    {iam.has(IAMPermission.store_comment) && (
                      <TableMenu
                        handleMenuAction={handleAction}
                        actions={COMMENT_ACTIONS}
                        entityID={item.uuid}
                        entityName={item.uuid}
                        disabled={addComment}
                      />
                    )}

                  </Stack>
                  {isEditable && selectedComment === item.uuid ? (
                    <Stack>
                      <div style={{ margin: 10 }}>
                        <MentionsInput
                          value={textEdit}
                          onChange={(e) => setTextEdit(e.target.value)}
                          style={mentionsInputStyle}
                        >
                          <Mention
                            trigger="@"
                            data={usersDisplayData}
                            style={mentionStyle}
                          />
                        </MentionsInput>
                      </div>
                      <Stack
                        direction={"row"}
                        columnGap={1}
                        mr={1}
                        mb={1}
                        justifyContent={"flex-end"}
                      >
                        <Button
                          onClick={() => {
                            setTextEdit("");
                            setIsEditable(false);
                            setInProgress(true);
                            getComments().then(() => setInProgress(false));
                          }}
                          size={"small"}
                          sx={{ textTransform: "none" }}
                        >
                          {t("buttons.cancel.lowercase")}
                        </Button>
                        <Button
                          onClick={handleEditComment}
                          size={"small"}
                          variant={"contained"}
                          sx={{ textTransform: "none" }}
                        >
                          {t("buttons.submit.lowercase")}
                        </Button>
                      </Stack>
                    </Stack>
                  ) : (
                    <div>
                      {item.text.split(" ").map((x) => {
                        const match = x.match(re);
                        if (
                          match &&
                          Array.isArray(match) &&
                          match.length === 3
                        ) {
                          const [, , userId] = match;
                          const user = usersDisplayData.find(
                            (item) => item.id === userId
                          );
                          if (!user) {
                            return `${x} `;
                          }
                          return (
                            <StyledLink>
                              <Link
                                to={`/store/control-panel/user-management/users/${user.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                                target={"_blank"}
                              >
                                @{user.display}
                              </Link>{" "}
                            </StyledLink>
                          );
                        }
                        return `${x} `;
                      })}
                    </div>
                  )}
                </Stack>
              </Card>
            ))}
            {!comments.content.length && (
              <Stack flexDirection={"row"} justifyContent={"center"} p={2}>
                <Typography variant={"caption"} color={"textSecondary"}>
                  {t("noComments")}
                </Typography>
              </Stack>
            )}
          </Fragment>
        )}
      </Popover>
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        title={t("deleteConfirmation.modal.title").toString()}
        message={t("content.comments.deleteComment.text").toString()}
        primaryAction={handleDelete}
        secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
        primaryActionTitle={t("buttons.delete").toString()}
        inProgress={inProgress}
      />
    </Stack>
  );
};

export default CommentsPopOver;
