import { SetStateAction, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { SelectOption } from "../../../../../models/DataOptions";
import Typography from "@mui/material/Typography";
import { FlatProperty } from "../../../../../models/FlatProperty";
import { Alert } from "@mui/lab";
import { postData, sortBy } from "../../../../../utils/utils";
import slugify from "slugify";
import { API_URL } from "../../../../../utils/config";
import SelectOptionUsagesDialog from "./SelectOptionUsagesDialog";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../../../../components/@lib/ConfirmationDialog";

type OptionValuesTableProps = {
  values: any;
  setValues: (e: SetStateAction<any>) => void;
  allowEdit: boolean;
  variant: string;
  error?: string;
  required?: boolean;
};

function OptionValuesTable(props: OptionValuesTableProps) {
  const { values, setValues, variant, allowEdit, error, required } = props;
  const [openUsages, setOpenUsages] = useState(false);
  const [selectedPropertyUUID, setSelectedPropertyUUID] = useState("");
  const [selectedOptionValue, setSelectedOptionValue] = useState("");
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [valueToDelete, setValueToDelete] = useState<{index: number, value: string}>({index: 0, value: ""});

  const handleUpdatePropertyAtIndex =
    (index: number, prop: string) => (e: any) => {
      setValues((prevState: FlatProperty) => ({
        ...prevState,
        values: prevState.values.map((opt: any, i: number) => {
          if (i === index) {
            return {
              ...opt,
              [prop]: e.target.value,
            };
          }
          return opt;
        }),
      }));
    };

  const handleUpdatePropertyLabel =
    (index: number) => (e: any) => {
      setValues((prevState: FlatProperty) => ({
        ...prevState,
        values: prevState.values.map((opt: any, i: number) => {
          if (i === index) {
            return {
              ...opt,
              label: e.target.value,
              value:
                opt.id === -1
                  ? slugify(e.target.value).toLowerCase()
                  : opt.value,
            };
          }
          return opt;
        }),
      }));
    };
  console.log(values.values);
  const handleAddValue = () => {
    setValues((prevState: FlatProperty) => ({
      ...prevState,
      values: [...prevState.values, { label: "", value: "", id: -1 }],
    }));
  };

  const handleDeleteClick = (index: number, value: string) => {
    setValueToDelete({index, value})
    setIsDialogOpen(true)
  }

  const handleDeleteAtIndex = async () => {
    const option = values.values.find((x: SelectOption) => x.value === valueToDelete.value);

    if (isDialogOpen) setIsDialogOpen(false)

    if (option.id !== -1) {
      try {
        const usages_containers = await postData(
          `${API_URL}/cm/properties/${values.uuid}/dataOptions/${option.value}/usages_containers`
        );
        const usages_datastreams = await postData(
          `${API_URL}/cm/properties/${values.uuid}/dataOptions/${option.value}/usages_datastreams`
        );
        if (
          usages_containers.totalElements !== 0 ||
          usages_datastreams.totalElements !== 0
        ) {
          setSelectedOptionValue(option.value);
          setSelectedPropertyUUID(values.uuid);
          setOpenUsages(true);
          return;
        }
      } catch (ex) {}
    }

    setValues((prevState: FlatProperty) => ({
      ...prevState,
      values: [
        ...prevState.values.slice(0, valueToDelete.index),
        ...prevState.values.slice(valueToDelete.index + 1),
      ],
    }));
  };

  const getLabelError = (label: string) => {
    return (
      values.values.filter((x: SelectOption) => x.label === label).length > 1
    );
  };

  const getValueError = (value: string) => {
    return (
      values.values.filter((x: SelectOption) => x.value === value).length > 1
    );
  };

  const length = useMemo(()=>{
    return values.values.filter((x: SelectOption) => !x.id).length
  }, [values.values])

  if (!allowEdit) {
    return (
      <Stack direction={"column"} rowGap={2} alignItems={"flex-start"}>
        <Typography>Values {required && "*"}</Typography>
        <Grid container gap={1}>
          {values.values.sort((a: SelectOption, b: SelectOption) => sortBy(a, b, "label")).map((opt: SelectOption) => (
            <Chip label={opt.label} />
          ))}
        </Grid>
      </Stack>
    );
  }

  const Row = (opt: SelectOption, labelError: boolean, valueError: boolean, index: number ) => (
    <Stack
      direction={"row"}
      columnGap={2}
      alignItems={"flex-start"}
      style={{ width: "100%" }}
    >
      <TextField
        disabled={((opt.id !== -1) && labelError)}
        label={"Label"}
        size={"small"}
        autoFocus
        // @ts-ignore
        variant={variant}
        error={labelError}
        helperText={labelError ? "Labels must be unique" : ""}
        value={opt.label}
        style={{ flex: 1 }}
        fullWidth
        onChange={handleUpdatePropertyLabel(index)}
      />
      <TextField
        disabled={opt.id !== -1}
        label={"Value"}
        size={"small"}
        // @ts-ignore
        variant={variant}
        style={{ flex: 1 }}
        error={valueError}
        helperText={valueError ? "Values must be unique" : ""}
        fullWidth
        value={opt.value}
        onChange={handleUpdatePropertyAtIndex(index, "value")}
      />
      <IconButton
        size={"small"}
        onClick={() => handleDeleteClick(index, opt.value)}
      >
        <RemoveCircleOutlineIcon fontSize={"small"} />
      </IconButton>
    </Stack>
  );

  return (
    <Card variant={"outlined"}>
      <Typography sx={{ pt: 2, pl: 2 }}>{t("formElement.values")}</Typography>
      <Stack direction={"column"} rowGap={2} alignItems={"flex-start"} p={2}>
        {error && (
          <Alert style={{ width: "100%" }} severity="warning">
            {error}
          </Alert>
        )}
        {values.values
          .filter((x: SelectOption) => !x.id)
          .sort((a: SelectOption, b: SelectOption) => sortBy(a, b, "label"))
          .map((opt: SelectOption, index: number) => {
            const labelError = getLabelError(opt.label);
            const valueError = getValueError(opt.value);

            return Row(opt, labelError, valueError, index)
          })}

        {values.values
          .filter((x: SelectOption) => x?.id === -1)
          .map((opt: SelectOption, index: number) => {
            const labelError = getLabelError(opt.label);
            const valueError = getValueError(opt.value);

            return Row(opt, labelError, valueError, index + length)
          })}

        <Button
          size={"small"}
          onClick={handleAddValue}
          startIcon={<AddOutlinedIcon />}
        >
          Add Option
        </Button>
      </Stack>
      <SelectOptionUsagesDialog
        open={openUsages}
        handleClose={() => setOpenUsages(false)}
        propertyUUID={selectedPropertyUUID}
        optionValue={selectedOptionValue}
      />
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        title={t("deleteConfirmation.modal.title").toString()}
        message={t("control.panel.dataModeling.metadata.properties.validationOptions.deleteValue.message").toString()}
        primaryAction={handleDeleteAtIndex}
        secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
        primaryActionTitle={t("buttons.delete").toString()}
      />
    </Card>
  );
}

export default OptionValuesTable;
