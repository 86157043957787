import RepoxAppsMenu from "components/Navigation/NavigationActions/RepoxAppsNavItem/RepoxAppsMenu";
import MenuButton from "../../../MenuButton/MenuButton";
import React from "react";
import { useIAMPermission } from "../../../../store/userStore";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';

import {
  AIAddon,
  GeoAddon,
  GuideAddon,
  PortalAddon,
  StoreAddon,
  StoriesAddon,
  SurveyAddon,
  XRAddon
} from "../../../../models/RepoxAddonType";

const repoxApps = [
  StoreAddon,
  SurveyAddon,
  StoriesAddon,
  GuideAddon,
  PortalAddon,
  XRAddon,
  GeoAddon,
  AIAddon
]

const RepoxAppsNavItem = () => {
  const iam = useIAMPermission();
  const apps = repoxApps.filter((x) => iam.hasSome(x.permissions));

  if (apps.length === 1 && apps[0]?.name === "Store") {
    return null;
  }

  return (
    <MenuButton
      menu={<RepoxAppsMenu apps={apps} />}
      icon={<AppsOutlinedIcon />}
      menuMargin={8}
    />
  );
};

export default RepoxAppsNavItem;
