import Chip from "@mui/material/Chip";
import PublicIcon from "@mui/icons-material/Public";
import { Link } from "react-router-dom";
import * as React from "react";
import { emphasize, styled } from '@mui/material/styles';

export type Breadcrumb = {
  href: string;
  name: string;
  published?: boolean;
};

export const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[300]
      : theme.palette.grey[900];
  return {
    backgroundColor,
    height: theme.spacing(3.7),
    color: theme.palette.text.primary,
    cursor: "pointer",
    borderRadius: 8,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

type BreadCrumbProps = {
  breadcrumb: Breadcrumb;
};
const BreadCrumb = ({ breadcrumb }: BreadCrumbProps) => {
  return (
    <Link
      key={breadcrumb.href}
      to={breadcrumb.href}
      style={{ textDecoration: "none" }}
    >
      <StyledBreadcrumb
        avatar={
          breadcrumb.published ? (
            <PublicIcon color={"success"} fontSize={"small"} />
          ) : undefined
        }
        key={breadcrumb.href}
        label={breadcrumb.name}
      />
    </Link>
  );
};

export default BreadCrumb;