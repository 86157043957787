import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {ActualDateTime, updateData} from "../../../../utils/utils";
import {useNotificationList, useNotificationStore} from "../../../../store/notificationStore";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import {Notification} from "../../../../models/Notification";
import {useTranslation} from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import GppBadIcon from '@mui/icons-material/GppBad';
import InfoIcon from '@mui/icons-material/Info';
import {NotificationType} from "../../../../models/NotificationType";
import BasicPopper from "../../../Common/BasicPopper";
import {API_URL} from "../../../../utils/config";
import {useEffect} from "react";
import {Tooltip} from "@mui/material";

export function getNotificationIcon(type: NotificationType) {
  switch (type) {
    case NotificationType.ALERT:
      return <AssignmentTurnedInIcon color={"success"}/>
    case NotificationType.ERROR:
      return <GppBadIcon color={"error"}/>
    case NotificationType.WARNING:
      return <WarningIcon color={"warning"}/>
    default:
      return <InfoIcon color={"info"}/>
  }
}

const NotificationIcon = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notifications, unreadCount] = useNotificationList();
  const { getUnreadCount, getAll } = useNotificationStore();

  const handleAll = () => {
    handleClose()
    navigate("/store/notifications")
  }

  useEffect(() => {
    if (open && unreadCount > 0) {
      (async () => {
        try {
          await updateData(`${API_URL}/notifications/read`, {});
          getAll()
          getUnreadCount()
        } catch (ex: any) {
          console.log(ex)
        }
      })()
    }
  }, [getAll, getUnreadCount, open, unreadCount]);

  const handleNotificationClick = (notification: Notification) => {
    handleClose()
    navigate(`/store/notifications/${notification.uuid}`)
  }

  return (
    <Stack flexDirection={"column"} alignItems={"flex-end"}>
      <IconButton
        id="notificationsPopper"
        aria-controls={open ? "notificationsPopper-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsNoneOutlinedIcon />
        </Badge>
      </IconButton>
      <BasicPopper
        id={"notificationsPopper-menu"}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        title={t("toolbar.notifications")}
      >
        <MenuList>
          {notifications.map((item, index) => (
            <MenuItem onClick={() => handleNotificationClick(item)} key={index} selected={!item.readAt}>
              <Stack
                flexDirection={"row"}
                columnGap={2}
                alignItems={"center"}
                flexGrow={1}
                style={{ fontWeight: item.readAt ? "normal" : "bold", width: 400 }}
              >
                {getNotificationIcon(item.notificationType)}
                <Stack flexDirection={"column"} rowGap={0.5}
                  style={{
                    flex: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Tooltip title={item.label}>
                    <Typography noWrap variant={"body1"} style={{ fontWeight: "inherit" }}>
                      {item.label}
                    </Typography>
                  </Tooltip>
                  <Typography variant={"body2"} color={"textSecondary"} style={{ fontWeight: "inherit" }}>
                    {ActualDateTime(item.createdAt)}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>

          ))}
          {!notifications.length && (
            <MenuItem disabled={true}>
              <ListItemText sx={{ textAlign: "center" }}>
                <Typography variant={"caption"}>
                  {t("noNotifications.msg")}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
        </MenuList>
        <Divider/>
        <Stack m={1} direction={"row"} justifyContent={"flex-end"}>
          <Button onClick={handleAll}>{t("buttons.seeAll")}</Button>
        </Stack>
      </BasicPopper>
    </Stack>
  );
};

export default NotificationIcon;
