import { ClickAwayListener, Popper } from "@mui/material";
import GlobalSearchField from "components/GlobalSearch/GlobalSearchField";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/query/useQuery";
import { fetchData } from "utils/utils";
import { API_URL } from "utils/config";
import GeoSearchPopover from "extensions/geo/components/GeoHeader/GeoSearchPopover";
import useFilterArray from "hooks/common/useFilterArray";

interface Geometry {
  uuid: string;
  label: string;
  createdAt: string;
}

function GeoSearch() {
  const searchParams = useQuery();
  const textQuery = searchParams.get("text") ?? "";

  const [value, setValue] = useState<string>(textQuery);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [geometries, setGeometries] = useState<Geometry[]>([])
  const navigate = useNavigate();

  useEffect(() => {
    setValue(textQuery);
  }, [textQuery]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    if (!open) return;

    fetchData(`${API_URL}/geo/geometries/all`)
      .then(res => setGeometries(res))
      .catch(ex => console.log(ex))

  }, [open]);

  const routeChange = (parameters: string) => {
    navigate(`/store/search${parameters}`);
  };

  const filtered = useFilterArray(geometries, value, ["label"])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <GlobalSearchField
          value={value}
          setValue={setValue}
          handleClick={handleClick}
          handleClose={handleClose}
          onEnter={routeChange}
        />
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          sx={{ zIndex: 10000 }}
        >
          <GeoSearchPopover
            options={filtered}
            handleClose={handleClose}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export default GeoSearch