import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import IconButton from "@mui/material/IconButton";

type DeleteButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

function DeleteButton({ onClick, disabled }: DeleteButtonProps) {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      aria-label="add"
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        margin: 8
      }}
    >
      <DeleteForeverOutlinedIcon
        sx={{ color: "error.main" }}
      />
    </IconButton>
  );
}

export default DeleteButton;
