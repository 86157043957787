export enum PropertyType {
  TEXT_FIELD = "TEXT_FIELD",
  TEXT_AREA = "TEXT_AREA",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
  URL = "URL",
  SPATIAL = "SPATIAL",
  RICH_TEXT = "RICH_TEXT",
  REFERENCE = "REFERENCE",
  DATE = "DATE",
  VOCABULARY = "VOCABULARY_FLAT",
  SLIDER = "SLIDER",
  MEDIA_LIB_IMAGE = "MEDIA_LIB_IMAGE",
  DATASTREAM_REF = "DATASTREAM_REF",
  PROPERTY_GROUP = "PROPERTY_GROUP",
  IDENTIFIER = "IDENTIFIER"
}

export const PropertyTypes = [
  {
    id: PropertyType.TEXT_FIELD,
    name: "Text",
    description: "Titles, names",
  },
  {
    id: PropertyType.TEXT_AREA,
    name: "Text Area",
    description: "Paragraphs, descriptions",
  },
  {
    id: PropertyType.BOOLEAN,
    name: "Boolean",
    description: "Yes or no, true or false",
  },
  {
    id: PropertyType.NUMBER,
    name: "Number",
    description: "ID, order number, rating, quantity",
  },
  {
    id: PropertyType.DATE,
    name: "Date",
    description: "Event dates",
  },
  {
    id: PropertyType.SELECT,
    name: "Select",
    description: "List, colors, categories",
  },
  {
    id: PropertyType.URL,
    name: "URL",
    description: "Reference to an external url",
  },
  {
    id: PropertyType.SPATIAL,
    name: "Spatial",
    description: "Coordinates: latitude and longitude",
  },
  {
    id: PropertyType.RICH_TEXT,
    name: "Rich Text",
    description: "Text formatting with references and media",
  },
  {
    id: PropertyType.REFERENCE,
    name: "Reference",
    description: "Reference to a container or datastream",
  },
  {
    id: PropertyType.VOCABULARY,
    name: "Vocabulary",
    description: "Predefined lists, countries, dictionaries",
  },
  {
    id: PropertyType.SLIDER,
    name: "Slider",
    description: "Number between two numbers",
  },
  {
    id: PropertyType.IDENTIFIER,
    name: "Identifier",
    description: "Auto increment, literal identifiers"
  },
  {
    id: PropertyType.DATASTREAM_REF,
    name: "Datastream Reference",
    description: "Reference a datastream within a specific container",
  },
  {
    id: PropertyType.PROPERTY_GROUP,
    name: "Group",
    description: "Create a custom group of properties",
  },
];
