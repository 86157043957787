import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Button from "@mui/material/Button";
import * as React from "react";
import { ReactNode } from "react";
import {useTranslation} from "react-i18next";

type TableHeaderProps = {
  title: string;
  count: number;
  allowAll?: boolean;
  hideAllowAll?: boolean;
  handleAdd: () => void;
  handleEdit?: () => void;
  handleDelete?: () => void;
  handleCancel?: () => void;
  isEditable: boolean;
  hideDividers?: boolean;
  disableActions?: boolean;
  selected?: string[];
  query: string;
  setQuery: (q: string) => void;
  renderFilter?: () => ReactNode;
  editPermission?: boolean
};

function TableHeader({
  title,
  count,
  allowAll,
  hideAllowAll,
  handleAdd,
  handleEdit,
  handleDelete,
  isEditable,
  handleCancel,
  hideDividers,
  selected,
  query,
  setQuery,
  renderFilter,
  disableActions,
  editPermission = true
}: TableHeaderProps) {
  const {t} = useTranslation();
  return (
    <Stack direction={"column"}>
      {!hideDividers && <Divider />}
      <Stack direction={"row"} alignItems={"flex-end"} columnGap={2} pb={1}>
        <Stack
          direction={"row"}
          flexWrap={"nowrap"}
          alignItems={"center"}
          columnGap={1}
        >
          <Typography variant={"subtitle1"}>
            {title} ({allowAll ? (hideAllowAll ? "-" : "*") : count})
          </Typography>
          {editPermission && (
            <IconButton
              size={"small"}
              color={"primary"}
              onClick={handleAdd}
              disabled={allowAll || disableActions}
            >
              <AddCircleOutlinedIcon />
            </IconButton>
          )}
        </Stack>
        <div style={{ flex: 1 }} />
        {renderFilter && renderFilter()}
        <TextField
          size={"small"}
          // label="Search"
          variant="standard"
          placeholder={t("common.search").toString()}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            startAdornment: <SearchOutlinedIcon />,
            endAdornment: (
              <Fade in={Boolean(query)}>
                <IconButton size={"small"} onClick={() => setQuery("")}>
                  <CloseIcon />
                </IconButton>
              </Fade>
            ),
          }}
        />
        {editPermission && handleEdit &&
          <Stack>
            {!isEditable ? (
              <Button
                style={{ textTransform: "none" }}
                disabled={count === 0 || disableActions}
                variant={"outlined"}
                size={"small"}
                onClick={handleEdit}
              >
                {t("buttons.edit.lowercase")}
              </Button>
            ) : (
              <Stack direction={"row"} columnGap={1}>
                <Button
                  variant={"outlined"}
                  size={"small"}
                  style={{ textTransform: "none" }}
                  onClick={handleCancel}
                >
                  {t("buttons.done")}
                </Button>
                <Button
                  variant={"outlined"}
                  color={"error"}
                  size={"small"}
                  onClick={handleDelete}
                  style={{ textTransform: "none" }}
                  disabled={selected?.length === 0}
                >
                  {t("buttons.delete.lowercase")}
                </Button>
              </Stack>
            )}
          </Stack>
        }
      </Stack>
      <Divider />
    </Stack>
  );
}

export default TableHeader;
