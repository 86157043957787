import * as yup from "yup";
import useFormElements, {
  ElementType,
} from "../../../../../../hooks/form/useFormElements";
import Dialog from "../../../../../../components/@lib/Dialog";
import DeleteButton from "../../../../../../components/Common/DeleteButton";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import SchemaGroup from "../../../../../../models/SchemaGroup";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  schemaGroup?: SchemaGroup;
  handleDelete: (uuid: string) => void;
  handleCreate: (group: SchemaGroup) => void;
  handleUpdate: (group: SchemaGroup) => void;
  schemasLength: number;
};

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string(),
});

const formElements = [
  {
    id: "name",
    label: "formElement.name",
    type: ElementType.text,
    isRequired: true,
  },
  {
    id: "description",
    label: "formElement.description",
    type: ElementType.textArea,
  },
];

function SchemaGroupDialog({
  open,
  handleClose,
  schemaGroup,
  handleDelete,
  handleUpdate,
  handleCreate,
  schemasLength,
}: DialogProps) {
  const { t } = useTranslation();

  const {
    values,
    handleChange,
    setValues,
    isSubmitting,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: new SchemaGroup(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.uuid) {
        const group = new SchemaGroup();
        group.uuid = values.uuid;
        group.name = values.name;
        group.description = values.description;
        group.position = values.position;
        handleUpdate(group);
      } else {
        const group = new SchemaGroup();
        group.name = values.name;
        group.description = values.description;
        group.position = schemasLength;
        handleCreate(group);
      }
      setSubmitting(false);
      resetForm();
      handleClose();
    },
  });

  useEffect(() => {
    setValues(schemaGroup ?? new SchemaGroup());
  }, [schemaGroup, setValues]);

  const form = useFormElements({
    formElements,
    values,
    handleChange,
    inProgress: isSubmitting,
  });

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("propertyGroups.modal.title").toString()}
      secondaryAction={handleClose}
      onSubmit={handleSubmit}
      primaryActionTitle={
        schemaGroup?.uuid
          ? t("buttons.save").toString()
          : t("buttons.create").toString()
      }
      secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
      maxWidth={"xs"}
      fullWidth
      inProgress={isSubmitting}
    >
      <Stack rowGap={2} sx={{ mt: 1 }}>
        {form}

        {schemaGroup && (
          <DeleteButton onClick={() => handleDelete(schemaGroup.uuid)} />
        )}
      </Stack>
    </Dialog>
  );
}

export default SchemaGroupDialog;
