import * as React from "react";
import ModernLayout from "../../../../components/@lib/ModernLayout";
import GeoHeader from "../../components/GeoHeader";
import GeoLogo, { GeoLogoIconOnly } from "../../../../components/@lib/Graphics/GeoLogo";

type DefaultLayoutProps = {
  children: React.ReactNode;
};

function GeoLayout({ children }: DefaultLayoutProps) {
  return (
    <ModernLayout
      header={<GeoHeader />}
      menuItems={[]}
      logo={<GeoLogo />}
      logoIcon={<GeoLogoIconOnly/>}
      rootPath={"/geo"}
    >
      {children}
    </ModernLayout>
  );
}

export default GeoLayout;
