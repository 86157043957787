import Attribute from "../../../../../models/Attribute";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableHeader from "../../../../../components/Common/TableHeader";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import useFilterArray from "../../../../../hooks/common/useFilterArray";
import { useSortHandler } from "../../../../../hooks/sort/useSortHandler";
import { useDefaultTable } from "../../../../../hooks/defaultTable/useDefaultTable";
import DefaultTable from "../../../../../components/DefaultTable";
import AddAttributeDialog from "./AddAttributeDialog";
import AttributeDialog from "../Attributes/AttributeDialog";
import { useMap } from "../../../../../hooks/common/useMap";
import { UseType } from "../../../../../models/UseType";
import Selector from "components/@lib/Inputs/Selector";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ConfirmationDialog from "../../../../../components/@lib/ConfirmationDialog";
import {
  PromptMessage,
  usePrompt,
} from "../../../../../hooks/navigationPrompt/usePrompt";
import { useIAMPermission } from "../../../../../store/userStore";
import IAMPermission from "../../../../../models/IAMPermission";
import { useTranslation } from "react-i18next";

type PropertyAttributesProps = {
  propertyAttributes: Attribute[];
  allAttributes: Attribute[];
  getAllAttributes: () => void;
  inDialog?: boolean;
  disableActions?: boolean;
  handleUpdateAttributes: (attributes: Attribute[]) => void;
};

const headCells = [
  {
    id: "name",
    label: "table.name",
  },
  {
    id: "attributeType",
    label: "table.type",
  },
  {
    id: "use",
    label: "table.use",
  },
  {
    id: "edit",
  },
];

function PropertyAttributes({
  propertyAttributes,
  allAttributes,
  getAllAttributes,
  handleUpdateAttributes,
  inDialog,
  disableActions,
}: PropertyAttributesProps) {
  const attributeMap = useMap(allAttributes, "uuid");
  const [openAdd, setOpenAdd] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [query, setQuery] = useState("");
  const [attribute, setAttribute] = useState<Attribute | undefined>(undefined);
  const [openDeleteAttributes, setOpenDeleteAttributes] = useState(false);

  const iam = useIAMPermission();
  const { t } = useTranslation();

  usePrompt(PromptMessage, isEditable);

  const joinedPropertyAttributes = useMemo(() => {
    return propertyAttributes.map((attribute) => {
      return {
        uuid: attribute.uuid,
        name: attributeMap.get(attribute.uuid)?.name ?? "",
        type: attributeMap.get(attribute.uuid)?.attributeType ?? "",
        use: attribute.use,
      };
    });
  }, [attributeMap, propertyAttributes]);

  const filteredAttributes = useFilterArray(joinedPropertyAttributes, query, [
    "name",
  ]);

  // permissions table configuration
  const sortHandler = useSortHandler();

  const {
    renderHeadCell,
    renderCheckAllCell,
    selected,
    handleClick,
    renderBodyCell,
    renderCheckRowCell,
    setSelected,
    isSelected,
  } = useDefaultTable(filteredAttributes, sortHandler, "uuid", false);

  const renderDefaultBody = () => {
    return filteredAttributes.map((item, index) => {
      const isItemSelected = isSelected(item.uuid);
      const labelId = `enhanced-table-checkbox-${index}`;

      const selector = (
        <Selector
          options={[
            { id: UseType.OPTIONAL, name: "Optional" },
            { id: UseType.RECOMMENDED, name: "Recommended" },
            { id: UseType.REQUIRED, name: "Required" },
          ]}
          value={item.use ?? UseType.OPTIONAL}
          disabled={!isEditable}
          onChange={(e) => handleUpdateUseType(item.uuid ?? "", e.target.value)}
          name={"use"}
        />
      );

      const editor = (
        <IconButton
          color={"primary"}
          size={"small"}
          onClick={() => {
            // @ts-ignore
            setAttribute(item);
            setOpenNew(true);
          }}
        >
          <EditOutlinedIcon />
        </IconButton>
      );

      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={item.uuid}
          selected={isItemSelected}
          sx={{ backgroundColor: "background.paper" }}
        >
          {/* @ts-ignore */}
          {isEditable &&
            renderCheckRowCell(isItemSelected, labelId, (event) =>
              handleClick(event, item.uuid ?? "")
            )}
          {renderBodyCell(item.name)}
          {renderBodyCell(item.type)}
          {renderBodyCell(selector)}
          {isEditable && renderBodyCell(editor)}
        </TableRow>
      );
    });
  };

  useEffect(() => {
    if (openAdd) setSelected([]);
  }, [openAdd, setSelected]);

  const handleRemove = () => {
    setOpenDeleteAttributes(false);
    handleUpdateAttributes(
      // @ts-ignore
      joinedPropertyAttributes.filter((x) => !selected.includes(x.uuid))
    );
    setSelected([]);
  };

  const handleAddSelected = (uuids: string[]) => {
    setOpenAdd(false);
    const attributesToBeAdded = uuids.map((uuid) => ({
      ...attributeMap.get(uuid),
      use: UseType.OPTIONAL,
    }));
    // @ts-ignore
    handleUpdateAttributes([
      ...joinedPropertyAttributes,
      ...attributesToBeAdded,
    ]);
    setSelected([]);
  };

  const handleUpdateUseType = (uuid: string, use: string) => {
    // @ts-ignore
    handleUpdateAttributes([
      ...joinedPropertyAttributes.map((attr) => {
        if (attr.uuid === uuid) {
          return {
            ...attr,
            use,
          };
        }
        return attr;
      }),
    ]);
  };

  const notAddedAttributes = useMemo(() => {
    const addedUUIDs = propertyAttributes.map((x) => x.uuid);
    return allAttributes.filter((x) => !addedUUIDs.includes(x.uuid));
  }, [allAttributes, propertyAttributes]);

  return (
    <Stack style={{ marginTop: -1 }}>
      <TableHeader
        title={t("properties.titleAddedAttributes")}
        count={propertyAttributes.length}
        handleAdd={() => setOpenAdd(true)}
        handleEdit={() => setIsEditable(true)}
        handleDelete={() => setOpenDeleteAttributes(true)}
        handleCancel={() => setIsEditable(false)}
        isEditable={isEditable}
        selected={selected}
        query={query}
        hideDividers={inDialog}
        setQuery={setQuery}
        disableActions={disableActions}
        editPermission={iam.has(
          IAMPermission.store_settings_contentModel_attributes_edit
        )}
      />

      <DefaultTable
        headCells={headCells.filter((item) => (isEditable ? true : item.label))}
        renderDefaultBody={renderDefaultBody}
        renderHeadCell={renderHeadCell}
        rows={filteredAttributes}
        disableRowGap
        renderCheckAllCell={renderCheckAllCell}
        selectableRows={isEditable}
        onRowClick={(row: Attribute) => {
          setAttribute(row);
          setOpenNew(true);
        }}
      />

      <AddAttributeDialog
        open={openAdd}
        handleClose={() => setOpenAdd(false)}
        attributes={notAddedAttributes}
        handleCreateNew={() => {
          setAttribute(undefined);
          setOpenNew(true);
        }}
        handleAddAttributes={handleAddSelected}
      />

      <AttributeDialog
        open={openNew}
        handleClose={() => setOpenNew(false)}
        attribute={attribute}
        onCreate={getAllAttributes}
        onUpdate={getAllAttributes}
      />

      <ConfirmationDialog
        open={openDeleteAttributes}
        handleClose={() => setOpenDeleteAttributes(false)}
        title={"Delete Warning"}
        primaryAction={handleRemove}
        message={"Are you sure you want to remove the selected attributes?"}
      />
    </Stack>
  );
}

export default PropertyAttributes;
