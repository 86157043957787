import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import SchemaGroupTabs from "./SchemaGroupTabs";
import PropertyList from "./PropertyList";
import {
  usePropertyList,
  usePropertyStore,
} from "../../../../../../store/propertyStore";
import PropertyForm from "./PropertyForm";
import PropertyConfig from "./PropertyConfig";
import { DragDropContext } from "react-beautiful-dnd";
import Schema, { SchemaProperty } from "../../../../../../models/Schema";
import {useNavigate, useParams} from "react-router-dom";
import {
  deleteData,
  exportFile,
  fetchData,
  postData,
  updateData,
} from "../../../../../../utils/utils";
import { API_URL } from "../../../../../../utils/config";
import SchemaGroupDialog from "./SchemaGroupDialog";
import ConfirmationDialog from "../../../../../../components/@lib/ConfirmationDialog";
import Property from "../../../../../../models/Property";
import { useSnackbar } from "notistack";
import PropertyDialog from "../../Properties/PropertyDialog";
import { useDeleteEntity } from "../../../../../../hooks/common/useDeleteEntity";
import EntityType from "../../../../../../models/Core/EntityType";
import SchemaDialog from "../SchemaDialog";
import ContentHeader from "../../../../../../components/@lib/ContentHeader";
import SchemaGroup from "../../../../../../models/SchemaGroup";
import SchemaUsagesDialog from "../SchemaUsagesDialog";
import ContextActionType from "../../../../../../models/ContextActionType";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { DataUsage, Edit } from "@mui/icons-material";
import Box from "@mui/material/Box";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SchemaActivityDialog from "../SchemaActivityDialog";
import axios from "axios";
import ToolbarProgressIndicator from "../../../../../../components/Common/ToolbarProgressIndicator";
import DeletePropertyDialog from "./DeletePropertyDialog";
import withAuth from "../../../../../../auth/withAuth";
import IAMPermission from "../../../../../../models/IAMPermission";
import { useIAMPermission } from "../../../../../../store/userStore";
import { useTranslation } from "react-i18next";
import PropertyListDialog from "../PropertyListDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export enum GroupSchemaAction {
  remove,
  edit,
  moveLeft,
  moveRight,
  create,
}

export enum SchemaDragDestination {
  groups = "GROUPS",
  form = "FORM",
  list = "LIST",
}

const BREADCRUMB_ACTIONS = [
  {
    name: "cm.actions.edit",
    type: ContextActionType.edit,
    Icon: Edit,
    textColor: "textPrimary",
    permission: IAMPermission.store_settings_contentModel_schemas_edit,
  },
  {
    name: "cm.actions.viewUsage",
    type: ContextActionType.viewUsage,
    disabled: false,
    Icon: DataUsage,
    textColor: "textPrimary",
  },
  {
    name: "cm.actions.activity",
    type: ContextActionType.activity,
    disabled: false,
    Icon: InfoOutlinedIcon,
    textColor: "textPrimary",
  },
  {
    name: "cm.actions.duplicate",
    type: ContextActionType.duplicate,
    disabled: false,
    Icon: ContentCopyIcon,
    textColor: "textPrimary",
    permission: IAMPermission.store_settings_contentModel_schemas_edit,
  },
  {
    name: "cm.actions.export",
    type: ContextActionType.export,
    disabled: false,
    Icon: FileUploadOutlinedIcon,
    textColor: "textPrimary",
    permission: IAMPermission.store_settings_contentModel_schemas_export,
  },
  {
    name: "cm.actions.delete",
    type: ContextActionType.delete,
    disabled: false,
    Icon: DeleteForeverOutlinedIcon,
    textColor: "error",
    permission: IAMPermission.store_settings_contentModel_schemas_edit,
  },
];

function SchemaDetails() {
  const { uuid } = useParams();

  const [schema, setSchema] = useState(new Schema());
  const [openAddPropertyToSchemaDialog, setOpenAddPropertyToSchemaDialog] = useState(false);
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState<SchemaGroup | undefined>(undefined);
  const [openRemoveGroupWarning, setOpenRemoveGroupWarning] = useState(false);
  const [openPropertyDialog, setOpenPropertyDialog] = useState(false);
  const [openUsageDialog, setOpenUsageDialog] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property | undefined>(undefined);
  const [selectedElementUUID, setSelectedElementUUID] = useState<string | undefined>(undefined);
  const [openEdit, setOpenEdit] = useState(false);
  const [openActivityDialog, setOpenActivityDialog] = useState(false);
  const [openAddPropertyDialog, setOpenAddPropertyDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [handleDelete, deleteDialog] = useDeleteEntity(EntityType.schema, uuid);
  const [isSaving, setIsSaving] = useState(false);
  const [savedPropertiesWithError, setSavedPropertyWithError] = useState<boolean | undefined>(undefined);
  const [pendingOperation, setPendingOperation] = useState(false);
  const [deleteConfirmationSchemaProperty, setDeleteConfirmationSchemaProperty,] = useState<SchemaProperty | undefined>(undefined);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState<string>("");
  const [loadingSchema, setLoadingSchema] = useState(false);
  const iam = useIAMPermission();
  const { t } = useTranslation();
  const disabled = !iam.has(IAMPermission.store_settings_contentModel_schemas_edit) || !schema.managed;
  const navigate = useNavigate();

  const { getAll: getAllProperties } = usePropertyStore();
  const [properties] = usePropertyList();

  // Initialize Schema
  useEffect(() => {
    setLoadingSchema(true);
    fetchData(`${API_URL}/cm/schemas/${uuid}`)
      .then((res: Schema) => {
        setSchema(res);
      })
      .catch((ex) => console.log(ex))
      .finally(() => setLoadingSchema(false));
  }, [uuid]);

  // Get lists of groups and properties
  useEffect(() => {
    getAllProperties();
  }, [getAllProperties]);

  useEffect(() => {
    if (activeGroupIndex > schema.schemaGroups.length - 1) {
      setActiveGroupIndex(0);
    }
  }, [activeGroupIndex, schema.schemaGroups.length]);

  // Properties that are already added
  const reservedPropertyUUIDS = useMemo((): string[] => {
    const array: string[] = [];
    schema.schemaGroups.forEach((group) => {
      group.properties.forEach((property) => {
        array.push(property.property.uuid!);
      });
    });
    return array;
  }, [schema.schemaGroups]);

  const handleSortProperties = async (
    groupUUID: string,
    schemaProperties: SchemaProperty[]
  ) => {
    try {
      const sorted = schemaProperties.map((property, index) => ({
        schemaPropertyUuid: property.uuid,
        position: index,
      }));
      await updateData(
        `${API_URL}/cm/schemas/${schema.uuid}/schema_groups/${groupUUID}/schema_properties/sort`,
        sorted
      );
    } catch (ex) {
      enqueueSnackbar("Failed to sort properties", { variant: "warning" });
    }
  };

  const handleSortGroups = async (schemaGroups: SchemaGroup[]) => {
    try {
      const sorted = schemaGroups.map((group, index) => ({
        schemaGroupUuid: group.uuid,
        position: index,
      }));
      await updateData(
        `${API_URL}/cm/schemas/${schema.uuid}/schema_groups/sort`,
        sorted
      );
    } catch (ex) {
      enqueueSnackbar("Failed to sort groups", { variant: "warning" });
    }
  };

  const onAddPropertyFromDialogToGroupProperty = async (uuid: string) => {
    setPendingOperation(true);
    try {
      const fullProperty: Property = await fetchData(`${API_URL}/cm/properties/${uuid}`);
      const defaultSchemaProperty = new SchemaProperty(fullProperty);
      const schemaGroupUUID = schema.schemaGroups[activeGroupIndex].uuid;
      const toIndex = schema.schemaGroups[activeGroupIndex].properties.length
      defaultSchemaProperty.property = fullProperty;
      defaultSchemaProperty.schemaGroupUuid = schemaGroupUUID;
      defaultSchemaProperty.position = toIndex;
      defaultSchemaProperty.parent = selectedElementUUID!
      const payload = SchemaProperty.createPayload(defaultSchemaProperty);

      const schemaProperty = await postData(`${API_URL}/cm/schemas/${schema.uuid}/schema_properties`, payload);

      setSchema((prevState) => {
        return {
          ...prevState,
          schemaGroups: prevState.schemaGroups.map((group, index) => {
            if (index === activeGroupIndex) {
              const groupProperties = [...group.properties];
              const newProperty = {
                ...schemaProperty,
                ...fullProperty,
                uuid: schemaProperty.uuid,
              };

              groupProperties.splice(toIndex, 0, newProperty);

              handleSortProperties(group.uuid, groupProperties);

              return {
                ...group,
                properties: groupProperties,
              };
            }

            return group;
          }),
        };
      });
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }

    setPendingOperation(false);
  }

  const onDragPropertyFromLibraryToSchemaForm = async (
    uuid: string,
    toIndex: number
  ) => {
    setPendingOperation(true);
    try {
      const fullProperty: Property = await fetchData(
        `${API_URL}/cm/properties/${uuid}`
      );
      const defaultSchemaProperty = new SchemaProperty(fullProperty);
      const schemaGroupUUID = schema.schemaGroups[activeGroupIndex].uuid;
      defaultSchemaProperty.property = fullProperty;
      defaultSchemaProperty.schemaGroupUuid = schemaGroupUUID;
      defaultSchemaProperty.position = toIndex;
      const payload = SchemaProperty.createPayload(defaultSchemaProperty);

      const schemaProperty = await postData(`${API_URL}/cm/schemas/${schema.uuid}/schema_properties`, payload);

      setSchema((prevState) => {
        return {
          ...prevState,
          schemaGroups: prevState.schemaGroups.map((group, index) => {
            if (index === activeGroupIndex) {
              const groupProperties = [...group.properties];
              const newProperty = {
                ...schemaProperty,
                ...fullProperty,
                uuid: schemaProperty.uuid,
              };

              groupProperties.splice(toIndex, 0, newProperty);

              handleSortProperties(group.uuid, groupProperties);

              return {
                ...group,
                properties: groupProperties,
              };
            }

            return group;
          }),
        };
      });
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }

    setPendingOperation(false);
  };

  const onDragPropertyFromLibraryToSchemaTab = async (
    destinationUUID: string,
    propertyUUID: string
  ) => {
    setPendingOperation(true);

    try {
      const fullProperty: Property = await fetchData(
        `${API_URL}/cm/properties/${propertyUUID}`
      );
      const defaultSchemaProperty = new SchemaProperty(fullProperty);
      defaultSchemaProperty.property = fullProperty;
      defaultSchemaProperty.schemaGroupUuid = destinationUUID;
      const payload = SchemaProperty.createPayload(defaultSchemaProperty);
      const schemaProperty = await postData(
        `${API_URL}/cm/schemas/${schema.uuid}/schema_properties`,
        payload
      );

      setSchema((prevState) => {
        return {
          ...prevState,
          schemaGroups: prevState.schemaGroups.map((group) => {
            if (group.uuid === destinationUUID) {
              const newProperty = {
                ...schemaProperty,
                ...fullProperty,
              };

              const groupProperties = [...group.properties, newProperty].sort(
                (a, b) => a.position - b.position
              );

              handleSortProperties(group.uuid, groupProperties);

              return {
                ...group,
                properties: groupProperties,
              };
            }

            return group;
          }),
        };
      });

      const activeTabIndex = schema.schemaGroups.findIndex(
        (x) => x.uuid === destinationUUID
      );

      setActiveGroupIndex(activeTabIndex);
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setPendingOperation(false);
  };

  const onDragAndDropToReArrange = (startIndex: number, endIndex: number) => {
    setSchema((prevState) => {
      return {
        ...prevState,
        schemaGroups: prevState.schemaGroups.map((group, index) => {
          if (index === activeGroupIndex) {
            const groupProperties = [...group.properties];
            const [removed] = groupProperties.splice(startIndex, 1);
            groupProperties.splice(endIndex, 0, removed);

            const properties = groupProperties.map((prop, index) => ({
              ...prop,
              position: index,
            }));

            handleSortProperties(group.uuid, properties);

            return { ...group, properties };
          }

          return group;
        }),
      };
    });
  };

  const onDragAndDropOfSchemaPropertyToOtherSchemaGroup = async (
    groupUUID: string,
    schemaPropertyUUID: string
  ) => {
    const activeTabIndex = schema.schemaGroups.findIndex(
      (x) => x.uuid === groupUUID
    );

    const group = schema.schemaGroups.find((x) =>
      x.properties.find((z) => z.uuid === schemaPropertyUUID)
    );
    const schemaProperty = group?.properties.find(
      (x) => x.uuid === schemaPropertyUUID
    )!;
    schemaProperty.schemaGroupUuid = groupUUID;

    setPendingOperation(true);

    try {
      const payload = SchemaProperty.createPayload(schemaProperty);
      await updateData(
        `${API_URL}/cm/schemas/${schema.uuid}/schema_properties/${schemaPropertyUUID}`,
        payload
      );

      setActiveGroupIndex(activeTabIndex);

      setSchema((prevState) => {
        return {
          ...prevState,
          schemaGroups: prevState.schemaGroups
            .map((group) => {
              return {
                ...group,
                properties: group.properties.filter(
                  (x) => x.uuid !== schemaPropertyUUID
                ),
              };
            })
            .map((group) => {
              if (group.uuid === groupUUID && schemaProperty) {
                const properties = [
                  ...group.properties,
                  { ...schemaProperty, position: group.properties.length },
                ].sort((a, b) => a.position - b.position);

                handleSortProperties(group.uuid, properties);

                return { ...group, properties };
              }
              return group;
            }),
        };
      });
    } catch (ex) {}

    setPendingOperation(false);
  };

  async function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    // In Case of dragging a property from the library to the schema
    if (result.source?.droppableId === SchemaDragDestination.list) {
      const destination = result.destination?.droppableId?.split("_");
      const destinationType = destination[0];

      // In case of dropping the property to the form under a specific group
      if (destinationType === SchemaDragDestination.form) {
        const uuid = result.draggableId.split("_")[0];
        const toIndex = result.destination.index;
        return onDragPropertyFromLibraryToSchemaForm(uuid, toIndex);
      }

      // In case of dropping the property to the group tab
      if (destinationType === SchemaDragDestination.groups) {
        const destinationUUID = destination[1];
        const uuid = result.draggableId.split("_")[0];

        return onDragPropertyFromLibraryToSchemaTab(destinationUUID, uuid);
      }

      return;
    }

    // In case of dragging from the form elements
    if (result.source?.droppableId === SchemaDragDestination.form) {
      // In case of re-arrange of elements inside form
      if (result.destination.droppableId === SchemaDragDestination.form) {
        // order is not changed so skip
        if (result.destination.index === result.source.index) {
          return;
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        return onDragAndDropToReArrange(startIndex, endIndex);
      }

      // In case of dragging a form element to another group
      // GROUPS_9128853f-0c7c-46a2-b442-5f0ff69e5bd3
      // keys[0] = GROUP
      // keys[1] = 9128853f-0c7c-46a2-b442-5f0ff69e5bd3
      const keys = result.destination.droppableId.split("_");

      if (keys[0] === SchemaDragDestination.groups) {
        const groupUUID = keys[1];
        const schemaPropertyUUID = result.draggableId.split("_")[1];

        return onDragAndDropOfSchemaPropertyToOtherSchemaGroup(
          groupUUID,
          schemaPropertyUUID
        );
      }
    }
  }

  // Callback to create new group or open the list to select one of the existing
  const handleCreateOrUpdateGroup = (group?: SchemaGroup) => {
    setSelectedGroup(group);
    setOpenAddPropertyToSchemaDialog(true);
  };

  // This action removes the given group from the view and
  // also clears the properties on the schema that have it as a displayGroup
  const handleRemoveGroupFormSchema = async (groupUUID: string) => {
    try {
      await deleteData(
        `${API_URL}/cm/schemas/${uuid}/schema_groups/${groupUUID}`
      );
      setSchema((prevState) => ({
        ...prevState,
        schemaGroups: prevState.schemaGroups.filter(
          (x) => x.uuid !== groupUUID
        ),
      }));
      setOpenRemoveGroupWarning(false);
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };

  // Check for properties connected to the group that is going to be removed from the schema
  const handleRemoveGroupFormSchemaCheck = (groupUUID: string) => {
    const groupToDeleteProperties =
      schema.schemaGroups.find((x) => x.uuid === groupUUID)?.properties || [];
    if (groupToDeleteProperties.length > 0)
      return setOpenRemoveGroupWarning(true);
    handleRemoveGroupFormSchema(groupUUID);
  };

  const handleRemoveProperty = async (
    schemaProperty: SchemaProperty,
    force = false,
    reason = ""
  ) => {
    const schemaPropertyUUID = schemaProperty.uuid;
    try {
      await axios.delete(
        `${API_URL}/cm/schemas/${schema.uuid}/schema_properties/${schemaPropertyUUID}`,
        {
          data: { force, reason },
        }
      );

      setSchema((prevState) => ({
        ...prevState,
        schemaGroups: prevState.schemaGroups.map((group) => {
          return {
            ...group,
            properties: group.properties.filter(
              (x) => x.uuid !== schemaPropertyUUID
            ),
          };
        }),
      }));
      setDeleteConfirmationSchemaProperty(undefined);
    } catch (ex: any) {
      const message = ex.response.data.message;
      if (ex.response.status === 409) {
        setDeleteConfirmationMessage(message);
        setDeleteConfirmationSchemaProperty(schemaProperty);
        return;
      }

      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const handleRearrangeGroup = async (groupUUID: string, direction: number) => {
    const groups = [...schema.schemaGroups];
    const startIndex = schema.schemaGroups.findIndex(
      (x) => x.uuid === groupUUID
    );
    const [removed] = groups.splice(startIndex, 1);
    const endIndex = startIndex + direction;
    groups.splice(endIndex, 0, removed);

    try {
      await handleSortGroups(groups);
      setSchema((prevState) => ({ ...prevState, schemaGroups: groups }));
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };

  // Handler of actions for the groups
  const handleDispatchGroupAction = (
    action: GroupSchemaAction,
    group: SchemaGroup
  ) => {
    switch (action) {
      case GroupSchemaAction.remove:
        handleRemoveGroupFormSchemaCheck(group.uuid);
        break;
      case GroupSchemaAction.edit:
        handleCreateOrUpdateGroup(group);
        break;
      case GroupSchemaAction.create:
        setOpenAddPropertyToSchemaDialog(true);
        break;
      case GroupSchemaAction.moveLeft:
        handleRearrangeGroup(group.uuid, -1);
        break;
      case GroupSchemaAction.moveRight:
        handleRearrangeGroup(group.uuid, 1);
        break;
      default:
        break;
    }
  };

  const handleCreateOrUpdateProperty = (property?: Property) => {
    setSelectedProperty(property || undefined);
    setOpenPropertyDialog(true);
  };

  const handleCreateGroup = useCallback(
    async (group: SchemaGroup) => {
      try {
        const newGroup = await postData(
          `${API_URL}/cm/schemas/${uuid}/schema_groups`,
          group
        );
        setSchema((prevState) => ({
          ...prevState,
          schemaGroups: [
            ...prevState.schemaGroups,
            {
              ...newGroup,
              position: prevState.schemaGroups.length,
            },
          ],
        }));
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, uuid]
  );

  const handleUpdateGroup = useCallback(
    async (updatedGroup: SchemaGroup) => {
      try {
        await updateData(
          `${API_URL}/cm/schemas/${uuid}/schema_groups/${updatedGroup.uuid}`,
          updatedGroup
        );

        setSchema((prevState) => ({
          ...prevState,
          schemaGroups: prevState.schemaGroups.map((group) => {
            if (updatedGroup.uuid === group.uuid) {
              return {
                ...group,
                name: updatedGroup.name,
                description: updatedGroup.description,
                position: updatedGroup.position,
              };
            }
            return group;
          }),
        }));
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, uuid]
  );

  const handleUpdateProperty = (property: SchemaProperty) => {
    setIsSaving(true);
    const payload = SchemaProperty.createPayload(property);
    updateData(
      `${API_URL}/cm/schemas/${schema.uuid}/schema_properties/${selectedElementUUID}`,
      payload
    )
      .then(() => setSavedPropertyWithError(false))
      .catch(() => setSavedPropertyWithError(true))
      .finally(() => setIsSaving(false));
  };

  const configTimoutRef = useRef<ReturnType<typeof setInterval>>();

  const triggerUpdate = (updated: SchemaProperty) => {
    configTimoutRef.current = setTimeout(() => {
      handleUpdateProperty(updated);
    }, 1000);
  };
  const handleUpdateConfigOfFocusedProperty = (
    prop: keyof SchemaProperty,
    value: any
  ) => {
    clearTimeout(configTimoutRef.current);
    setSavedPropertyWithError(undefined);

    setSchema((prevState) => ({
      ...prevState,
      schemaGroups: prevState.schemaGroups.map((group) => {
        return {
          ...group,
          properties: group.properties.map((property) => {
            if (property.uuid === selectedElementUUID) {
              const updated = {
                ...property,
                [prop]: value,
              };

              if ((prop === "minOccurs" || prop === "maxOccurs") && !value) {
                return updated;
              }

              triggerUpdate(updated);

              return updated;
            }
            return property;
          }),
        };
      }),
    }));
  };

  const handleExport = async () => {
    try {
      await exportFile(`${API_URL}/cm/schemas/${uuid}/export`, {
        format: "JSON",
      });
    } catch (ex) {
      // @ts-ignore
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };

  const handleDuplicate = async (uuid: string) => {
    setLoadingSchema(true);
    try {
      await fetchData(`${API_URL}/cm/schemas/${uuid}/duplicate`)
          .then((res: Schema) => {
            setSchema(res);
          })

      enqueueSnackbar(t("notifications.generic.success"), {
        variant: "success",
      });
      navigate(
        `/store/control-panel/data-modeling/metadata-schemas/schemas/${schema.uuid}`
      );
    } catch (ex) {
      enqueueSnackbar(t("notifications.generic.error"), { variant: "error" });
    }
    setLoadingSchema(false)
  };

  const selectedElement = useMemo(() => {
    const group = schema.schemaGroups.find((x) =>
      x.properties.find((z) => z.uuid === selectedElementUUID)
    );
    return group?.properties.find((x) => x.uuid === selectedElementUUID);
  }, [schema.schemaGroups, selectedElementUUID]);

  const handleEditPropertyFromSchemaProperty = (schemaProperty: SchemaProperty) => {
    const property = properties?.find((x) => x.uuid === schemaProperty.property.uuid);
    if (property) {
      setSelectedElementUUID(property?.uuid);
      setSelectedProperty(property)
      setOpenPropertyDialog(true);
    }
  }

  return (
    <Container maxWidth={false} style={{ height: "100%", overflow: "hidden" }}>
      <ToolbarProgressIndicator loading={pendingOperation || loadingSchema} />
      <Stack direction={"row"} mb={3} alignItems={"center"} columnGap={2}>
        <ContentHeader
          title={schema.name}
          dropDownActions={BREADCRUMB_ACTIONS}
          handleDropDownAction={(action) => {
            switch (action) {
              case ContextActionType.delete:
                return handleDelete();
              case ContextActionType.edit:
                return setOpenEdit(true);
              case ContextActionType.export:
                return handleExport();
              case ContextActionType.activity:
                return setOpenActivityDialog(true);
              case ContextActionType.duplicate:
                return handleDuplicate(schema.uuid)
              case ContextActionType.viewUsage:
                return setOpenUsageDialog(true);
              default:
                return;
            }
          }}
          breadcrumbs={[
            {
              href: "/store/control-panel",
              name: t("toolbar.account.adminConsole"),
            },
            {
              href: "/store/control-panel/data-modeling",
              name: t("admin.control.panel.dataModeling"),
            },
            {
              href: "/store/control-panel/data-modeling/metadata-schemas",
              name: t("sidenav.settings.ms"),
            },
            {
              href: "/store/control-panel/data-modeling/metadata-schemas/schemas",
              name: t("ms.schemas"),
            },
          ]}
        />
        <div style={{ flex: 1 }} />
      </Stack>

      {/*</Stack>*/}
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack direction={"column"} style={{ height: "100%" }}>
          {/*  Property Groups  */}
          <SchemaGroupTabs
            activeIndex={activeGroupIndex}
            setActiveIndex={setActiveGroupIndex}
            dispatchAction={handleDispatchGroupAction}
            propertyGroups={schema.schemaGroups.sort(
              (a, b) => a.position - b.position
            )}
            hasGroup={schema.schemaGroups.length > 0}
            disabled={disabled}
          />

          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: "primary.main",
            }}
          />
          <Stack direction={"row"}>
            {/*  SideNavigation  */}
            <PropertyList
              properties={properties}
              reservedPropertyUUIDS={reservedPropertyUUIDS}
              createOrUpdate={handleCreateOrUpdateProperty}
              isDragDisabled={schema.schemaGroups.length === 0}
            />

            {/*  Form  */}
            <PropertyForm
              properties={
                schema.schemaGroups[activeGroupIndex]?.properties ?? []
              }
              hasGroup={schema.schemaGroups.length > 0}
              selectedElementUUID={selectedElementUUID}
              setSelectedElementUUID={setSelectedElementUUID}
              handleRemoveProperty={handleRemoveProperty}
              loading={loadingSchema}
              disabled={disabled}
              onAddProperty={() => setOpenAddPropertyDialog(true)}
            />

            {/*  Configuration  */}
            <PropertyConfig
              selectedElement={selectedElement}
              updateConfig={handleUpdateConfigOfFocusedProperty}
              isSaving={isSaving}
              disabled={disabled}
              hasError={savedPropertiesWithError}
              handleEditProperty={handleEditPropertyFromSchemaProperty}
            />
          </Stack>
        </Stack>
      </DragDropContext>

      <SchemaGroupDialog
        open={openAddPropertyToSchemaDialog}
        handleClose={() => setOpenAddPropertyToSchemaDialog(false)}
        schemaGroup={selectedGroup}
        handleDelete={handleRemoveGroupFormSchemaCheck}
        handleCreate={handleCreateGroup}
        handleUpdate={handleUpdateGroup}
        schemasLength={schema?.schemaGroups?.length ?? 0}
      />

      <ConfirmationDialog
        open={openRemoveGroupWarning}
        handleClose={() => setOpenRemoveGroupWarning(false)}
        title={t("deleteConfirmation.modal.title")}
        secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
        message={t("schemas.modal.removeGroup.msg")}
      />

      <DeletePropertyDialog
        open={deleteConfirmationSchemaProperty !== undefined}
        handleClose={() => setDeleteConfirmationSchemaProperty(undefined)}
        schemaProperty={deleteConfirmationSchemaProperty}
        message={deleteConfirmationMessage}
        handleDelete={handleRemoveProperty}
        handleUsage={() => {
          setDeleteConfirmationSchemaProperty(undefined);
          setOpenUsageDialog(true);
        }}
      />

      <PropertyDialog
        open={openPropertyDialog}
        handleClose={() => setOpenPropertyDialog(false)}
        property={selectedProperty}
        onCreate={async () => {
          getAllProperties();
        }}
        onUpdate={async () => {
          getAllProperties();
        }}
      />

      <SchemaDialog
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        schema={schema}
        onUpdate={async (updateSchema) => {
          setSchema(updateSchema);
        }}
      />

      <SchemaUsagesDialog
        open={openUsageDialog}
        handleClose={() => setOpenUsageDialog(false)}
        schemaUUID={uuid}
      />

      <SchemaActivityDialog
        open={openActivityDialog}
        handleClose={() => setOpenActivityDialog(false)}
        schemaUuid={uuid}
      />

      <PropertyListDialog
        reservedPropertyUUIDS={reservedPropertyUUIDS}
        creteProperty={onAddPropertyFromDialogToGroupProperty}
        handleClose={() => setOpenAddPropertyDialog(false)}
        open={openAddPropertyDialog}
      />

      {deleteDialog}
    </Container>
  );
}

export default withAuth(SchemaDetails, [
  IAMPermission.store_settings_contentModel_schemas_view,
]);
