import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React from "react";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useTranslation } from "react-i18next";

type PropertyReadOnlyPreviewProps = {
  name: string;
  value?: string;
  required?: boolean;
  recommended?: boolean;
  copyText?: string;
};

function PropertyReadOnlyPreview({
  value,
  name,
  required,
  recommended,
  copyText,
}: PropertyReadOnlyPreviewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const handleCopy = async () => {
    await navigator.clipboard.writeText(copyText ?? "");
    enqueueSnackbar("Link copied to clipboard", {
      variant: "info",
    });
  };
  return (
    <Stack rowGap={0.5} flex={1}>
      <Typography variant={"caption"} color={"textSecondary"}>
        {name} {required && "*"} {recommended && <sup>(Rec)</sup>}
      </Typography>
      <Stack
        display={"flex"}
        alignItems={"flex-end"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        columnGap={1}
      >
        <Typography variant={"body1"}>{value || "-"}</Typography>
        {copyText && (
          <Tooltip title={t("share.modal.copy")} placement="top" arrow>
            <IconButton onClick={handleCopy} size={"small"}>
              <ContentCopyOutlinedIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
export default PropertyReadOnlyPreview;
