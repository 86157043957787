const AudioFileIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 74H65C67.2091 74 69 72.2091 69 70V35L40 6H15C12.7909 6 11 7.79086 11 10V70C11 72.2091 12.7909 74 15 74Z"
      fill="#EA1DB0"
    />
    <path d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z" fill="#EA1DB0" />
    <path
      d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z"
      fill="black"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 39H34V42V45V55.416C33.3875 55.1484 32.7111 55 32 55C29.2386 55 27 57.2386 27 60C27 62.7614 29.2386 65 32 65C34.7614 65 37 62.7614 37 60V45H48V52.416C47.3875 52.1484 46.7111 52 46 52C43.2386 52 41 54.2386 41 57C41 59.7614 43.2386 62 46 62C48.7614 62 51 59.7614 51 57V45V42V39Z"
      fill="white"
    />
  </svg>
);
export default AudioFileIcon;
