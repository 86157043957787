import {useEffect, useState} from "react";
import {postData} from "../../utils/utils";
import {API_URL} from "../../utils/config";

export interface SettingResult {
  uuid: string;
  name: string;
  containerTypes: UsageEntityType[];
  datastreamTypes: UsageEntityType[];
}

export interface UsageEntityType {
  uuid: string;
  name: string;
  parent?: string;
}

const useSchemaUsage = (open: boolean, schemaUUID?: string): [SettingResult, boolean] => {
  const [result, setResult] = useState<SettingResult>({
    uuid: "",
    name: "",
    containerTypes: [],
    datastreamTypes: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!schemaUUID || !open) return;
    setIsLoading(true);
    postData(`${API_URL}/cm/schemas/${schemaUUID}/usages_cm`, {})
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [schemaUUID, open]);

  return [result, isLoading];
};

export default useSchemaUsage
