import Dialog from "../../../../../components/@lib/Dialog";
import * as yup from "yup";
import Schema from "../../../../../models/Schema";
import { useFormik } from "formik";
import { postData, updateData } from "../../../../../utils/utils";
import { API_URL } from "../../../../../utils/config";
import { useNavigate } from "react-router-dom";
import useFormElements from "../../../../../hooks/form/useFormElements";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSchemaFormElements } from "./form-elements";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  onUpdate?: (updatedSchema: Schema) => void;
  onCreate?: () => void;
  schema?: Schema;
};

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string(),
});

function SchemaDialog({
  open,
  handleClose,
  schema,
  onUpdate,
  onCreate,
}: DialogProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: new Schema(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const payload = {
          name: values.name,
          slug: values.slug,
          description: values.description,
          rootElement: values.rootElement,
          namespaces: values.namespaces,
          managed: values.managed,
        };
        if (schema) {
          const result = await updateData(
            `${API_URL}/cm/schemas/${schema.uuid}`,
            payload
          );
          onUpdate && onUpdate(result);
        } else {
          const result = await postData(`${API_URL}/cm/schemas`, payload);
          onCreate && onCreate();
          navigate(
            `/store/control-panel/data-modeling/metadata-schemas/schemas/${result.uuid}`
          );
        }
        handleClose();
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!open) return;
    setValues(schema ?? new Schema());
  }, [schema, setValues, open]);

  const formElements = useSchemaFormElements(schema?.uuid);
  const form = useFormElements({
    formElements,
    values,
    handleChange,
    inProgress: isSubmitting,
    setValues
  });

  const onClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      title={t("schemas.modal.title").toString()}
      secondaryAction={onClose}
      onSubmit={handleSubmit}
      primaryActionTitle={
        schema ? t("buttons.update").toString() : t("buttons.create").toString()
      }
      secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
      maxWidth={"xs"}
      fullWidth
      inProgress={isSubmitting}
    >
      <Stack rowGap={2} sx={{ mt: 1 }}>
        {form}
      </Stack>
    </Dialog>
  );
}

export default SchemaDialog;
