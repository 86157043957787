import Dialog from "../../../../../../components/@lib/Dialog";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import Activity from "../../../../../Content/EntitySideBar/Activity";
import { useEntityActivity } from "../../../../../../hooks/common/useEntityActivity";
import { API_URL } from "../../../../../../utils/config";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../../../../../utils/utils";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  schemaUuid?: string;
};

function SchemaActivityDialog({ open, handleClose, schemaUuid }: DialogProps) {
  const [events, setEvents] = useState<string[]>([]);
  const [filters, setFilters] = useState<string[]>([]);
  const [activityPage, setActivityPage] = useState(0);

  const [activityData, loadMore, isLoading] = useEntityActivity(
    schemaUuid,
    `${API_URL}/cm/schemas`,
    open,
    events,
    filters
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) return;
    fetchData(`${API_URL}/cm/schemas/${schemaUuid}/activity/event_types`)
      .then((res) => {
        setEvents(res);
      })
      .catch((ex) => console.log(ex));
  }, [schemaUuid, open]);

  useEffect(() => {
    setActivityPage(0);
  }, [schemaUuid]);

  useEffect(() => {
    if (!activityPage) return;
    loadMore(activityPage);
  }, [activityPage, loadMore]);

  const loadPage = () => {
    setActivityPage((prevState) => prevState + 1);
  };

  const onClose = () => {
    setEvents([]);
    setActivityPage(0);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      title={t("schemas.activity.modal.title").toString()}
      primaryAction={onClose}
      primaryActionTitle={t("buttons.close").toString()}
      maxWidth={"xs"}
      inProgress={isLoading}
      fullWidth
    >
      <Stack sx={{ mt: 1 }} alignItems={"center"}>
        <Activity
          activityData={activityData}
          loadPage={loadPage}
          options={events}
          setFilters={setFilters}
          setActivityPage={setActivityPage}
        />
      </Stack>
    </Dialog>
  );
}

export default SchemaActivityDialog;
