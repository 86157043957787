import React from "react";
import "../../App.css";
import { Datastream } from "../../models/Datastream";
import DatastreamCover, {getDatastreamIcon} from "../DatastreamCover";
import ContentCardBox from "../@lib/ContentCardBox";

type ContainerCardProps = {
  isActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLElement>;
  onContextMenu?: React.MouseEventHandler<HTMLElement>;
  isSelected?: boolean;
  onSelect?: React.MouseEventHandler<HTMLElement>;
  selectable?: boolean;
  datastream: Datastream;
  isParentShared?: boolean;
};

const DatastreamCardBox = React.memo((props: ContainerCardProps) => {
  const { isParentShared, datastream} = props;
  
  const isShared = isParentShared || datastream.shared;

  return (
    <ContentCardBox
      {...props}
      cover={<DatastreamCover size={60} datastream={datastream} />}
      uuid={datastream.uuid}
      isShared={isShared}
      label={datastream.label}
      type={Datastream.getTypeName(datastream)}
      publishedAt={datastream.publishedAt}
      icon={getDatastreamIcon(datastream)}
    />
  );
});

export default DatastreamCardBox;
