import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useRunningJobs } from "../../../../store/runningJobsStore";
import Badge from "@mui/material/Badge";

function MyActivityNavItem() {
  const [runningJobs] = useRunningJobs();
  const hasRunningJobs = runningJobs.length > 0;

  return (
    <IconButton component={Link} to={"/store/my-activity"}>
      <Badge color="info" variant="dot" invisible={!hasRunningJobs}>
        <AccessTimeIcon />
      </Badge>
    </IconButton>
  );
}

export default MyActivityNavItem;
