import {Stack} from "@mui/material";
import IAMPermission from "../../models/IAMPermission";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import * as React from "react";
import {useIAMPermission} from "../../store/userStore";
import {API_URL} from "../../utils/config";
import {downloadFile} from "../../utils/utils";
import {Datastream} from "../../models/Datastream";
import {useState} from "react";
import CheckIcon from '@mui/icons-material/Check';

function useImagePreviewActions(datastream: Datastream) {
  const iam = useIAMPermission()
  const [editAnnotations, setEditAnnotations] = useState(false)

  const handleDownload = () => {
    const url = `${API_URL}/datastreams/${datastream.uuid}/download`;
    const filename = datastream.bitstreamFile?.filename;

    downloadFile(url, filename);
  };

  const fileType = datastream?.bitstreamFile?.mimeType ?? ""
  const supportAnnotations = fileType.includes("image/png") || fileType.includes("image/jpeg")

  const toolbar = () => (
    <Stack direction={"row"} columnGap={1}>

      {iam.has(IAMPermission.store_datastream_update) && supportAnnotations && (
        <Tooltip
          title={editAnnotations ? "Close Annotations" : "Edit Annotations"}
          placement={"bottom"}
          arrow
        >
          <IconButton
            size={"small"}
            aria-label="close"
            onClick={() => setEditAnnotations(!editAnnotations)}
          >
            {editAnnotations ? <CheckIcon fontSize={"small"}/> : <LocalOfferIcon fontSize={"small"} />}
          </IconButton>
        </Tooltip>
      )}

      {iam.has(IAMPermission.store_datastream_download) && (
        <Tooltip
          title={"Download Datastream"}
          placement={"bottom"}
          arrow
        >
          <IconButton
            size={"small"}
            aria-label="close"
            onClick={handleDownload}
          >
            <DownloadOutlinedIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )

  return {
    toolbar,
    editAnnotations
  }
}

export default useImagePreviewActions