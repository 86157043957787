import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { deleteData, postData } from "../../utils/utils";
import { API_URL } from "../../utils/config";
import { useSnackbar } from "notistack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import BasicPopper from "./BasicPopper";
import ListItemIcon from "@mui/material/ListItemIcon";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Person } from "@mui/icons-material";
const pluralize = require("pluralize");

type PopOverProps = {
  watched: boolean;
  watchCount: number;
  containerUuid: string;
  handleRefreshContainer: () => void;
};

function WatchContainer({
  watched,
  watchCount,
  containerUuid,
  handleRefreshContainer,
}: PopOverProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = async () => {
    try {
      handleClose();
      if (watched) {
        await deleteData(`${API_URL}/watch/containers/${containerUuid}`);
        enqueueSnackbar("You stopped watching this container", {
          variant: "info",
        });
      } else {
        await postData(`${API_URL}/watch/containers/${containerUuid}`, "");
        enqueueSnackbar("You are watching this container", {
          variant: "success",
        });
      }
      handleRefreshContainer();
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Tooltip title={t("myContent.watch").toString()}>
        <IconButton
          color={watched ? "primary" : "default"}
          id="watch-button"
          // size={"small"}
          // sx={{
          //   backgroundColor: watched ? "primary.main" : "default",
          //   color: watched ? "primary.contrastText" : "default",
          //   "&:hover": {
          //     backgroundColor: watched ? "primary.light" : "default",
          //   }
          // }}
          aria-controls={open ? "watch-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size={"small"}
        >
          <VisibilityIcon fontSize={"small"} />
        </IconButton>
      </Tooltip>
      <BasicPopper
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        title={t("myContent.watch")}
      >
        {/* TODO: List all users*/}
        <MenuList>
          <MenuItem>
            <ListItemIcon>
              <Avatar>
                <Person />
              </Avatar>
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: 1 }}>
              <b>{pluralize("others", watchCount, true)}</b> also watching this
            </ListItemText>
          </MenuItem>
        </MenuList>
        <Stack p={1}>
          {watched && (
            <Button
              startIcon={<VisibilityOffIcon />}
              color={"error"}
              onClick={handleToggle}
            >
              {t("myContent.actions.unwatch")}
            </Button>
          )}
          {!watched && (
            <Button
              startIcon={<VisibilityIcon />}
              color={"primary"}
              onClick={handleToggle}
            >
              {t("myContent.actions.watch")}
            </Button>
          )}
        </Stack>
      </BasicPopper>
    </Fragment>
  );
}

export default WatchContainer;
