import Dialog from "../../../components/@lib/Dialog";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { useEffect, useMemo, useState } from "react";
import { API_URL } from "../../../utils/config";
import { SavedSearch } from "../../../models/GlobalSearch";
import {
  ActualDateTime,
  buildParameters,
  deleteData,
} from "../../../utils/utils";
import { useDefaultTable } from "../../../hooks/defaultTable/useDefaultTable";
import DefaultTable from "../../../components/DefaultTable";
import { useNavigate } from "react-router-dom";
import ReservedUUID from "../../../models/ReservedUUID";
import ContextActionType from "../../../models/ContextActionType";
import IAMPermission from "../../../models/IAMPermission";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import contextActionType from "../../../models/ContextActionType";
import ConfirmationDialog from "../../../components/@lib/ConfirmationDialog";
import { useSavedSearches } from "../../../hooks/search/useSavedSearches";
import { useSortHandler } from "../../../hooks/sort/useSortHandler";
import useSortedList from "../../../hooks/sort/useSortedList";
import EditIcon from "@mui/icons-material/Edit";
import EditSearchDialog from "./EditSearchDialog";

type SaveSearchDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const headCells = [
  {
    id: "label",
    label: "table.label",
    isSortable: true,
  },
  {
    id: "createdAt",
    label: "table.createdAt",
    isSortable: true,
  },
  {
    id: "updatedAt",
    label: "table.updatedAt",
    isSortable: true,
  },
];

const TABLE_ACTIONS = [
  {
    name: "cm.actions.edit",
    type: ContextActionType.edit,
    Icon: EditIcon,
    textColor: "textPrimary",
    permission: IAMPermission.store_settings_users_edit,
  },
  {
    name: "cm.actions.delete",
    type: ContextActionType.delete,
    Icon: DeleteForeverOutlinedIcon,
    textColor: "error",
    permission: IAMPermission.store_settings_users_edit,
  },
];

const ViewSavedSearchDialog = ({
  open,
  handleClose,
}: SaveSearchDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [selectedUUID, setSelectedUUID] = useState<string | undefined>(
    undefined
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const sortHandler = useSortHandler();
  const [page, setPage] = useState(0);

  const { results, getAll } = useSavedSearches<SavedSearch>(
    `${API_URL}/my/searches`,
    page
  );

  useEffect(() => {
    if (!open) return;
    (async () => {
      setIsLoading(true);
      await getAll();
      setIsLoading(false);
    })();
  }, [getAll, open]);

  const searches = useMemo(() => {
    return results.content.map((item) => {
      return {
        ...item,
        createdAt: ActualDateTime(item.createdAt),
        updatedAt: ActualDateTime(item.updatedAt),
      };
    });
  }, [results.content]);

  const sorted = useSortedList(sortHandler, searches);
  const { renderDefaultBody, renderHeadCell } = useDefaultTable(
    sorted,
    sortHandler,
    "uuid"
  );

  const onRowClick = (search: SavedSearch) => {
    const parameters = buildParameters({
      text: search.requestDetails.value,
      caseSensitive: search.requestDetails.caseSensitive
        ? search.requestDetails.caseSensitive
        : null,
      location:
        search.requestDetails.location !== ReservedUUID.MyContentRoot
          ? search.requestDetails.location
          : null,
      type: search.requestDetails.type ? search.requestDetails.type : null,
      owner: search.requestDetails.owner ? search.requestDetails.owner : null,
      publishStatus: search.requestDetails.publishStatus
        ? search.requestDetails.publishStatus
        : null,
      filetype: search.requestDetails?.fileType
        ? search.requestDetails.fileType
        : null,
      containerTypes: search.requestDetails?.containerTypes?.length
        ? search.requestDetails.containerTypes.toString()
        : null,
      datastreamTypes: search.requestDetails?.datastreamTypes?.length
        ? search.requestDetails.datastreamTypes.toString()
        : null,
      isTrashed: search.requestDetails.isTrashed
        ? search.requestDetails.isTrashed
        : null,
      fullText: search.requestDetails.fulltextSearch
        ? search.requestDetails.fulltextSearch
        : null,
      properties: search.requestDetails.propertyValueFilters
        ? search.requestDetails.propertyValueFilters
        : null,
    });
    handleClose();
    navigate(`/store/search${parameters}`);
  };

  const handleAction = (actionID: contextActionType, entityID: string) => {
    setSelectedUUID(entityID);
    switch (actionID) {
      case ContextActionType.delete:
        setOpenDeleteDialog(true);
        break;
      case ContextActionType.edit:
        setOpenEditDialog(true);
        break;
      default:
        break;
    }
  };

  const handleDeteleSearch = async () => {
    await deleteData(`${API_URL}/my/searches/${selectedUUID}`);
    setOpenDeleteDialog(false);
    await handleRefresh();
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    await getAll();
    setIsLoading(false);
  };

  const selectedSearch = useMemo(() => {
    return sorted.find((x) => x.uuid === selectedUUID) ?? new SavedSearch();
  }, [selectedUUID, sorted]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("viewSavedSearch.modal.title").toString()}
      primaryAction={handleClose}
      primaryActionTitle={t("buttons.close").toString()}
      maxWidth={"md"}
      fullWidth
    >
      <Stack>
        <DefaultTable
          headCells={headCells}
          renderDefaultBody={renderDefaultBody}
          renderHeadCell={renderHeadCell}
          isLoading={loading}
          rows={sorted}
          onRowClick={onRowClick}
          totalPages={results.totalPages}
          page={page}
          setPage={setPage}
          actions={TABLE_ACTIONS}
          handleAction={handleAction}
          size={"small"}
        />
      </Stack>
      <ConfirmationDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        title={t("deleteConfirmation.modal.title")}
        primaryAction={handleDeteleSearch}
        message={t("deleteSavedSearch.confirmation.msg")}
        secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
        primaryActionTitle={t("buttons.delete").toString()}
      />
      <EditSearchDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        search={selectedSearch}
        onUpdate={handleRefresh}
      />
    </Dialog>
  );
};

export default ViewSavedSearchDialog;
