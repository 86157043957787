import Dialog from "../../../components/@lib/Dialog";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useFormElements, {
  ElementType,
} from "../../../hooks/form/useFormElements";
import { useFormik } from "formik";
import {updateData} from "../../../utils/utils";
import { API_URL } from "../../../utils/config";
import { useSnackbar } from "notistack";
import Stack from "@mui/material/Stack";
import {SavedSearch} from "../../../models/GlobalSearch";
import {useEffect} from "react";

type EditSearchDialogProps = {
  open: boolean;
  handleClose: () => void;
  search: SavedSearch;
  onUpdate: () => void;
};

const formElements = [
  {
    id: "label",
    label: "formElement.label",
    type: ElementType.text,
    columns: 12,
    isRequired: true,
  },
];

const initialState = {
  label: "",
};

const EditSearchDialog = ({ open, handleClose, search, onUpdate }: EditSearchDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { values, setValues, handleChange, isSubmitting, handleSubmit, resetForm } =
    useFormik({
      initialValues: initialState,
      enableReinitialize: true,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: async (values, { setSubmitting }) => {
        const payload = {
          label: values.label,
          requestDetails: search.requestDetails,
        }
        setSubmitting(true);
        try {
          await updateData(`${API_URL}/my/searches/${search.uuid}`, payload);
          handleClose();
          onUpdate();
          enqueueSnackbar(t("notification.saveSearch"), { variant: "success" });
        } catch (ex: any) {
          enqueueSnackbar(ex.message, { variant: "error" });
        }
        setSubmitting(false);
        resetForm();
      },
    });

  useEffect(()=>{
    if (!open) return
    setValues({label: search.label})
  }, [open, search, setValues])

  const form = useFormElements({
    formElements,
    values: values,
    handleChange,
    inProgress: isSubmitting,
  });

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("renameContainer.modal.title").toString()}
      secondaryAction={handleClose}
      onSubmit={handleSubmit}
      primaryActionTitle={t("buttons.save").toString()}
      secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
      maxWidth={"xs"}
      fullWidth
    >
      <Stack sx={{ mt: 1, mb: 2 }}>{form}</Stack>
    </Dialog>
  );
};

export default EditSearchDialog;
