const SpreadsheetIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 74H65C67.2091 74 69 72.2091 69 70V35L40 6H15C12.7909 6 11 7.79086 11 10V70C11 72.2091 12.7909 74 15 74Z"
      fill="#277A00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 64C22.4477 64 22 63.5523 22 63L22 60C22 59.4477 22.4477 59 23 59L31 59C31.5523 59 32 59.4477 32 60L32 63C32 63.5523 31.5523 64 31 64L23 64ZM20 57V59V64V65C20 65.5523 20.4477 66 21 66H22H32H34H44H46H56H57C57.5523 66 58 65.5523 58 65V64V59V57V52V50V45L58 40C58 39.4477 57.5523 39 57 39H56H46H44H34H32H22H21C20.4477 39 20 39.4477 20 40L20 45V50V52V57ZM23 52C22.4477 52 22 52.4477 22 53L22 56C22 56.5523 22.4477 57 23 57L31 57C31.5523 57 32 56.5523 32 56V53C32 52.4477 31.5523 52 31 52L23 52ZM35 52C34.4477 52 34 52.4477 34 53V56C34 56.5523 34.4477 57 35 57H43C43.5523 57 44 56.5523 44 56V53C44 52.4477 43.5523 52 43 52L35 52ZM44 49C44 49.5523 43.5523 50 43 50L35 50C34.4477 50 34 49.5523 34 49V46C34 45.4477 34.4477 45 35 45L43 45C43.5523 45 44 45.4477 44 46V49ZM47 52C46.4477 52 46 52.4477 46 53V56C46 56.5523 46.4477 57 47 57L55 57C55.5523 57 56 56.5523 56 56V53C56 52.4477 55.5523 52 55 52L47 52ZM56 49C56 49.5523 55.5523 50 55 50L47 50C46.4477 50 46 49.5523 46 49V46C46 45.4477 46.4477 45 47 45L55 45C55.5523 45 56 45.4477 56 46V49ZM32 49C32 49.5523 31.5523 50 31 50L23 50C22.4477 50 22 49.5523 22 49L22 46C22 45.4477 22.4477 45 23 45L31 45C31.5523 45 32 45.4477 32 46V49ZM56 60C56 59.4477 55.5523 59 55 59L47 59C46.4477 59 46 59.4477 46 60V63C46 63.5523 46.4477 64 47 64L55 64C55.5523 64 56 63.5523 56 63V60ZM44 60C44 59.4477 43.5523 59 43 59H35C34.4477 59 34 59.4477 34 60L34 63C34 63.5523 34.4477 64 35 64H43C43.5523 64 44 63.5523 44 63V60Z"
      fill="white"
    />
    <path d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z" fill="#277A00" />
    <path
      d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z"
      fill="black"
      fillOpacity="0.3"
    />
  </svg>
);
export default SpreadsheetIcon;
