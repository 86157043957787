import {useEffect, useRef, useState} from "react";
import Paging from "models/Pageable";
import {useSnackbar} from "notistack";
import axios from "axios";
import {buildParameters} from "utils/utils";

const usePaginatedSearch = <T>(endpoint?: string) => {
  const [results, setResults] = useState(new Paging<T>())
  const [page, setPage] = useState(0)
  const [term, setTerm] = useState("")
  const [loading, setLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const cancel = useRef(undefined)


  useEffect(() => {
    if (!endpoint) return

    const config = {
      cancelToken: new axios.CancelToken(function executor(c: any) {
        cancel.current = c; // keep reference for cancellation
      }),
    };

    (async () => {
      setLoading(true)
      try {
        const params = buildParameters({ page, q: term, size: 50 }, endpoint.includes("?"))
        const response = await axios.get(`${endpoint}${params}`, config);
        setResults(response.data)
      } catch (ex: any) {
        if (!axios.isCancel(ex)) {
          enqueueSnackbar(ex.message, { variant: "error" })
        }
      }
      setLoading(false)
    })()

    return () => {
      if (cancel.current) {
        // @ts-ignore
        cancel.current()
      }
    }

  }, [endpoint, enqueueSnackbar, page, term]);


  return {
    results,
    page,
    setPage,
    term,
    setTerm,
    loading
  }

}

export default usePaginatedSearch