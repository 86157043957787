import {PropertyType} from "../models/PropertyType";
import {SelectOption} from "../models/DataOptions";

export function clearDataOptionValues(type: PropertyType, values?: any) {
  if (!values || type !== PropertyType.SELECT) return values
  return values.map((opt: SelectOption) => ({
    ...opt,
    id: undefined
  }))
}
