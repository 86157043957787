import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";

export const typeOptions = [
  {
    icon: ClearAllOutlinedIcon,
    title: "search.type.all",
    keyValue: "type",
    query: "ALL",
  },
  {
    icon: FolderOpenOutlinedIcon,
    title: "search.type.containers",
    keyValue: "type",
    query: "CONTAINER",
  },
  {
    icon: InsertDriveFileOutlinedIcon,
    title: "search.type.datastreams",
    id: "Datastreams",
    query: "DATASTREAM",
    keyValue: "type",
  },
];

export const filetypeOptions = [
  {
    icon: FolderZipOutlinedIcon,
    title: "search.type.archive",
    id: "Archive",
    keyValue: "filetype",
    query: "ARCHIVE",
    color: "#ff6699",
  },
  {
    icon: AudioFileOutlinedIcon,
    title: "search.type.audio",
    id: "Audio",
    keyValue: "filetype",
    query: "AUDIO",
    color: "#77773c",
  },
  {
    icon: TextSnippetOutlinedIcon,
    title: "search.type.document",
    id: "Word",
    keyValue: "filetype",
    query: "DOCUMENT",
    color: "#ff4da6",
  },
  {
    icon: ImageOutlinedIcon,
    title: "search.type.image",
    id: "Image",
    keyValue: "filetype",
    query: "IMAGE",
    color: "#FF6C40",
  },
  {
    icon: ViewInArIcon,
    title: "search.type.3d",
    id: "3D",
    keyValue: "filetype",
    query: "MODEL_3D",
    color: "#b33c00",
  },
  {
    icon: PictureAsPdfOutlinedIcon,
    title: "search.type.pdf",
    id: "PDF",
    keyValue: "filetype",
    query: "PDF",
    color: "#33ccff",
  },
  {
    icon: FilePresentOutlinedIcon,
    title: "search.type.presentation",
    id: "Presentation",
    keyValue: "filetype",
    query: "PRESENTATION",
    color: "#d24dff",
  },
  {
    icon: SummarizeOutlinedIcon,
    title: "search.type.spreadsheet",
    id: "Excel",
    keyValue: "filetype",
    query: "SPREADSHEET",
    color: "#ffd11a",
  },
  {
    icon: NoteAltOutlinedIcon,
    title: "search.type.text",
    id: "Text",
    keyValue: "filetype",
    query: "TEXT",
    color: "#33cc33",
  },
  {
    icon: OndemandVideoOutlinedIcon,
    title: "search.type.video",
    id: "Video",
    keyValue: "filetype",
    query: "VIDEO",
    color: "#669900",
  },
];

export const ownerOptions = [
  {
    icon: GroupOutlinedIcon,
    title: "search.owner.all",
    keyValue: "owner",
    query: "ANYONE",
  },
  {
    icon: Person2OutlinedIcon,
    title: "search.owner.me",
    keyValue: "owner",
    query: "ME",
  },
];

export const publishStatusOptions = [
  {
    icon: ClearAllOutlinedIcon,
    title: "search.publishStatus.all",
    keyValue: "publishStatus",
    query: "ALL",
  },
  {
    icon: PublicIcon,
    title: "search.publishStatus.published",
    keyValue: "publishStatus",
    query: "PUBLISHED",
  },
  {
    icon: PublicOffIcon,
    title: "search.publishStatus.unpublished",
    keyValue: "publishStatus",
    query: "UNPUBLISHED",
  },
];

export const operatorOptions = [
  {
    id: "EQUALS",
    name: "EQUALS",
    disabled: false,
  },
  {
    id: "NOT_EQUALS",
    name: "NOT_EQUALS",
    disabled: false,
  },
  {
    id: "CONTAINS",
    name: "CONTAINS",
    disabled: false,
  },
  {
    id: "BEFORE",
    name: "BEFORE",
    disabled: false,
  },
  {
    id: "AFTER",
    name: "AFTER",
    disabled: false,
  },
];

export const logicalOperatorOptions = [
  {
    id: "AND",
    name: "AND",
  },
  {
    id: "OR",
    name: "OR",
  },
];
