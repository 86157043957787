import {styled} from "@mui/material/styles";
import {CardActionArea, Stack, Typography} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormHelperText from "@mui/material/FormHelperText";
import React from "react";

const StyledButton = styled(CardActionArea)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.action.disabled}`,
  // backgroundColor: theme.palette.background.paper,
  position: "relative",
  paddingBottom: theme.spacing(0.7),
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textTransform: "none", // to remove uppercase text transformation of Button
  "&:hover": {
    backgroundColor: "none",
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
  },
}));

interface Props {
  disabled?: boolean
  handleClick: () => void
  label: string
  required?: boolean
  recommended?: boolean
  valueString?: string
  helperText?: string
  error?: boolean
}

function TableSelectorButton(props: Props) {

  const {
    required,
    recommended,
    handleClick,
    label,
    valueString,
    error,
    helperText,
    disabled
  } = props

  return (
    <Stack sx={{width: '100%'}}>
      <Stack alignItems={"flex-start"} direction={"column"} rowGap={0.4}>
        <Typography
          variant={"caption"}
          color={"textSecondary"}
          sx={{ fontSize: "0.75rem" }}
        >
          {label} {required && "*"} {recommended && <sup>(Rec)</sup>}
        </Typography>
        <StyledButton disabled={disabled} onClick={handleClick}>
          <Typography color={!valueString ? "textSecondary" : "textPrimary"}>{valueString ?? ""}</Typography>
          <ArrowDropDownIcon color={"inherit"} />
        </StyledButton>
      </Stack>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </Stack>
  )
}

export default TableSelectorButton