import React from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Option } from "../../../hooks/form/useFormElements";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { FlatProperty } from "../../../models/FlatProperty";
import { SelectOption } from "../../../models/DataOptions";
import { sortByLabel } from "../../../utils/utils";

type PreviewProps = {
  value: string;
  setValue: (newValue: any) => void;
  property: FlatProperty;
};

function SelectValueField({ value, setValue, property }: PreviewProps) {
  const options = property.values
    .map((x: SelectOption) => ({
      id: x.value,
      name: x.label,
    }))
    .sort((a: SelectOption, b: SelectOption) => sortByLabel(a, b, "name"));
  return (
    <FormControl fullWidth sx={{ flex: 1 }}>
      <InputLabel id="demo-simple-select-label" size={"small"}>
        Property Value
      </InputLabel>
      <Select
        label={"Property Value"}
        MenuProps={{
          disablePortal: true,
          sx: { maxHeight: "350px" },
        }}
        onChange={setValue}
        value={value}
        size={"small"}
      >
        {options.map((opt: Option) => (
          <MenuItem key={opt.id} value={opt.id}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {opt.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectValueField;
