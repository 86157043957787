const PresentationIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 74H65C67.2091 74 69 72.2091 69 70V35L40 6H15C12.7909 6 11 7.79086 11 10V70C11 72.2091 12.7909 74 15 74Z"
      fill="#FF6B00"
    />
    <path d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z" fill="#FF6B00" />
    <path
      d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z"
      fill="black"
      fillOpacity="0.3"
    />
    <path
      d="M39.5385 66.9998C45.911 66.9998 51.0769 61.8338 51.0769 55.4613H39.5385V43.9229C33.1659 43.9229 28 49.0888 28 55.4613C28 61.8338 33.1659 66.9998 39.5385 66.9998Z"
      fill="white"
    />
    <path
      d="M53 53.5385C53 47.1659 47.8341 42 41.4615 42L41.4615 53.5385L53 53.5385Z"
      fill="white"
    />
  </svg>
);
export default PresentationIcon;
