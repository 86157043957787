import React, { useEffect, useState } from "react";
import Dialog from "../../../../../../components/@lib/Dialog";
import { usePropertyGroups } from "../../../../../../store/propertyGroupStore";
import { usePropertyList } from "../../../../../../store/propertyStore";
import { useMap } from "../../../../../../hooks/common/useMap";
import useFilterArray from "../../../../../../hooks/common/useFilterArray";
import { useSortHandler } from "../../../../../../hooks/sort/useSortHandler";
import { useDefaultTable } from "../../../../../../hooks/defaultTable/useDefaultTable";
import { Stack } from "@mui/material";
import SearchField from "../../../../../../components/@lib/Inputs/SearchField";
import DefaultTable from "../../../../../../components/DefaultTable";
import Selector from "../../../../../../components/@lib/Inputs/Selector";
import { useTranslation } from "react-i18next";
import { useArrayOptions } from "../../../../../../hooks/common/useArrayOptions";
import { PropertyType } from "../../../../../../models/PropertyType";

type PropertyListProps = {
  creteProperty: (uuid: string) => Promise<void>;
  reservedPropertyUUIDS: string[];
  open: boolean;
  handleClose: () => void;
};

const headCells = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "groupName",
    label: "Group",
  },
];

export default function PropertyListDialog(props: PropertyListProps) {
  const { reservedPropertyUUIDS, creteProperty, open, handleClose } = props;
  const [groups] = usePropertyGroups();
  const [properties] = usePropertyList();
  const groupMap = useMap(groups, "uuid")
  const [selectedGroup, setSelectedGroup] = useState("")
  const { t } = useTranslation();
  const groupOptions = useArrayOptions(groups, "uuid", "name")
  const [inProgress, setInProgress] = useState(false)

  const options = properties
    .filter(x => x.propertyGroup === selectedGroup)
    .filter(x => x.propertyType !== PropertyType.PROPERTY_GROUP)
    .filter(x => !reservedPropertyUUIDS.includes(x.uuid!))
    .map(property => ({
    name: property.name,
    id: property.uuid!,
    groupName: groupMap.get(property.propertyGroup)?.name,
    type: property.propertyType
  }))

  const [search, setSearch] = useState("")

  const filtered = useFilterArray(options, search, ["name"])

  const sortHandler = useSortHandler();
  const {
    renderDefaultBody,
    renderHeadCell,
    renderCheckAllCell,
    selected,
    setSelected
  } = useDefaultTable(filtered, sortHandler, "id", true, false);

  useEffect(() => {
    if (open) {
      setSelected([])
      setSelectedGroup(groups[0]?.uuid ?? "")
    }
  }, [groups, open, setSelected])

  const handleConfirmSelection = async () => {
    setInProgress(true)
    await creteProperty(selected[0])
    setInProgress(false)
    handleClose()
  };


  return (
    <Dialog
      primaryAction={handleConfirmSelection}
      maxWidth={"sm"}
      fullWidth
      open={open}
      handleClose={handleClose}
      title={"Select Property"}
      inProgress={inProgress}
      disablePrimaryAction={selected.length === 0}
    >
      <Stack rowGap={2}>
        <Stack direction={"row"} gap={2}>
          <Stack flex={1} direction={"row"}>
            <SearchField value={search} setValue={setSearch}/>
          </Stack>
          <Selector
            options={groupOptions}
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
            name={"group"}
            label={t("formElement.type").toString()}
            variant={"filled"}
            size={"small"}
            sx={{ width: 200 }}
          />
        </Stack>
        <DefaultTable
          selectableRows
          renderCheckAllCell={renderCheckAllCell}
          headCells={headCells}
          renderDefaultBody={renderDefaultBody}
          renderHeadCell={renderHeadCell}
          rows={filtered}
        />
      </Stack>
    </Dialog>
  )
}
