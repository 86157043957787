import * as React from "react";
import { ChangeEvent, SetStateAction, useEffect, useMemo } from "react";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Slider,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Selector from "components/@lib/Inputs/Selector";
import { createMap } from "../../utils/utils";
import { ColorPicker } from "material-ui-color";
import PropertyReadOnlyPreview from "../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import PasswordField from "components/Common/PasswordField";
import AsyncFlatSelect from "components/@lib/Inputs/AsyncFlatSelect";
import StyledLink from "../../components/Common/StyledLink";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import AssetPicker from "../../components/Common/MediaLibrary/AssetPicker";
import SpatialDefaultValue from "../../pages/ControlPanel/DataModeling/MetadataSchemas/Properties/SpatialDefaultValue";
import AsyncTableSelect from "components/@lib/Inputs/AsyncTableSelect";
import TableSelect from "components/@lib/Inputs/TableSelect";

const Palette = [
  "#ffffff",
  "#ff1a1a",
  "#b30000",
  "#ff99ff",
  "#e60073",
  "#ccffcc",
  "#70db70",
  "#00b33c",
  "#b3e0ff",
  "#4db8ff",
  "#0066ff",
  "#b3b3b3",
  "#8c8c8c",
  "#ffcc80",
  "#cc6600",
  "#ff8000",
];

export enum ElementType {
  text,
  number,
  textArea,
  boolean,
  password,
  select,
  multiSelect,
  nativeSelect,
  custom,
  color,
  group,
  date,
  asyncSelect,
  asyncMultiSelect,
  asyncFlatSelect,
  slider,
  mediaAssetPicker,
  spatial,
}

type UseFormElementsInput = {
  formElements: FormElement[];
  values: any;
  handleChange?: (e: ChangeEvent) => void;
  setValues?: (e: SetStateAction<any>) => void;
  inProgress?: boolean;
  variant?: "filled" | "standard" | "outlined" | undefined;
  allowEdit?: boolean;
  errors?: any;
  smallSize?: true;
  initializeTabValue?: boolean;
};

function getPreviewData(element: FormElement, values: any) {
  const timezone = localStorage.getItem("timezone") || "Europe/Athens";
  const options = element?.options || [];
  const optionMap = createMap(options);
  const value = values[element.id];
  let label = value;
  let path = element?.path ?? "";

  if (
    element.type === ElementType.select ||
    element.type === ElementType.nativeSelect
  ) {
    label = optionMap.get(value)?.name;
    const optionPath = optionMap.get(value)?.path;
    if (optionPath) path = optionPath;
  } else if (element.type === ElementType.multiSelect) {
    if (Array.isArray(value)) {
      label = value
        .map((value: string) => optionMap.get(value)?.name)
        .join(", ");
    } else {
      label = "";
    }
  } else if (element.type === ElementType.boolean) {
    label = values[element.id] ? "Yes" : "No";
  } else if (element.type === ElementType.password) {
    label = "*************************************";
  } else if (element.type === ElementType.date) {
    label = values[element.id]
      ? moment.utc(values[element.id]).tz(timezone).format("ll")
      : "";
  } else if (element.type === ElementType.number) {
    label = value?.toString();
  }

  return path ? <StyledLink path={path} label={label} /> : label;
}

const useFormElements = ({
  formElements,
  handleChange,
  inProgress = false,
  values,
  variant = "standard",
  allowEdit = true,
  setValues,
  errors,
  smallSize,
  initializeTabValue,
}: UseFormElementsInput) => {
  const { t } = useTranslation();
  const groups = formElements.filter((el) => el.type === ElementType.group);
  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!initializeTabValue) return;
    setTabValue(0);
  }, [initializeTabValue]);

  const elements = useMemo(() => {
    const renderFormElements = (elements: FormElement[]) => {
      return elements.map((element, index) => {
        // if (element.groupID && groupExpanded !== element.groupID) {
        //   return null
        // }

        if (
          !allowEdit &&
          element.type !== ElementType.custom &&
          element.type !== ElementType.group &&
          !element.hasReadonly
        ) {
          const dataLabel = getPreviewData(element, values);

          return (
            <Grid key={element.id} item xs={12} sm={element.columns}>
              <PropertyReadOnlyPreview
                name={t(element.label ?? "")}
                value={dataLabel}
                copyText={!!element.copyText ? values[element.id] : undefined}
              />
            </Grid>
          );
        }

        switch (element.type) {
          case ElementType.mediaAssetPicker: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <AssetPicker
                  value={values[element.id]}
                  setValue={(ids) => {
                    setValues &&
                      setValues((prevState: any) => ({
                        ...prevState,
                        [element.id]: ids,
                      }));
                  }}
                  label={t(element.label ?? "") as string}
                  filetype={"IMAGE"}
                  maxFiles={element.maxValue as number}
                  fullWidth
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                />
              </Grid>
            );
          }
          case ElementType.textArea: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <TextField
                  id={element.id}
                  fullWidth
                  required={element.isRequired}
                  name={element.id}
                  label={t(element.label ?? "")}
                  multiline
                  disabled={element.disabled || inProgress || !allowEdit}
                  rows={element.rows ? element.rows : 3}
                  size={"small"}
                  variant={variant}
                  value={values[element.id]}
                  onChange={handleChange}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                />
              </Grid>
            );
          }
          case ElementType.number: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <TextField
                  fullWidth
                  required={element.isRequired}
                  id={element.id}
                  name={element.id}
                  label={t(element.label ?? "")}
                  disabled={element.disabled || inProgress || !allowEdit}
                  type={"number"}
                  InputProps={{
                    endAdornment: element?.inputAdornment ? (
                      <InputAdornment position="end">
                        {element.inputAdornment}
                      </InputAdornment>
                    ) : undefined,
                  }}
                  size={"small"}
                  variant={variant}
                  value={values[element.id]}
                  onChange={handleChange}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                />
              </Grid>
            );
          }
          case ElementType.slider: {
            const valueArray = values[element.id] ?? [0, 0];
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <Stack direction={"column"} rowGap={1} flex={1}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    {t(element.label ?? "")} {element.isRequired && "*"}
                  </Typography>
                  <Stack px={1}>
                    <Slider
                      valueLabelDisplay="auto"
                      disabled={element.disabled || inProgress || !allowEdit}
                      value={
                        element.multipleValues ? valueArray : valueArray[0]
                      }
                      onChange={(
                        event: Event,
                        newNumber: number | number[]
                      ) => {
                        if (Array.isArray(newNumber)) {
                          // @ts-ignore
                          setValues((prevState) => ({
                            ...prevState,
                            [element.id]: newNumber,
                          }));
                        } else {
                          // @ts-ignore
                          setValues((prevState) => ({
                            ...prevState,
                            [element.id]: [newNumber],
                          }));
                        }
                      }}
                      name={element.id}
                      min={element.minValue as number}
                      max={element.maxValue as number}
                    />
                  </Stack>
                </Stack>
              </Grid>
            );
          }
          case ElementType.spatial: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                {setValues && (
                  <SpatialDefaultValue
                    values={values}
                    setValues={setValues}
                    readonly={!allowEdit}
                  />
                )}
              </Grid>
            );
          }
          case ElementType.password: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <PasswordField
                  required={element.isRequired}
                  id={element.id}
                  label={t(element.label ?? "")}
                  disabled={element.disabled || inProgress || !allowEdit}
                  variant={variant}
                  value={values[element.id]}
                  onChange={handleChange}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                  copyText={!!element.copyText}
                />
              </Grid>
            );
          }
          case ElementType.boolean: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <FormControlLabel
                  control={
                    <Switch
                      size={smallSize ? "small" : undefined}
                      checked={values[element.id]}
                      onChange={handleChange}
                      disabled={element.disabled || inProgress || !allowEdit}
                      name={element.id}
                      id={element.id}
                      required={element.isRequired}
                    />
                  }
                  label={t(element.label ?? "")}
                />
              </Grid>
            );
          }
          case ElementType.group: {
            return (
              <TabPanel
                index={index}
                value={tabValue}
                formElements={element.formElements ?? []}
                renderFormElements={renderFormElements}
              />
            );
          }
          case ElementType.custom: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                {element.control(
                  values,
                  setValues,
                  allowEdit,
                  variant,
                  errors ? errors[element.id] : ""
                )}
              </Grid>
            );
          }
          case ElementType.asyncSelect: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <AsyncTableSelect
                  url={element.url!}
                  value={values[element.id]}
                  onChange={(uuid) =>
                    // @ts-ignore
                    setValues((prevState) => ({
                      ...prevState,
                      [element.id]: uuid[0],
                    }))
                  }
                  multiSelect={false}
                  required={element.isRequired}
                  name={element.id}
                  disabled={element.disabled || inProgress || !allowEdit}
                  label={t(element.label ?? "").toString()}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                />
              </Grid>
            );
          }
          case ElementType.asyncFlatSelect: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <AsyncFlatSelect
                  url={element.url}
                  value={values[element.id]}
                  onChange={(e) =>
                    // @ts-ignore
                    setValues((prevState) => ({
                      ...prevState,
                      [element.id]: e.target.value,
                    }))
                  }
                  labelProp={element.labelProp}
                  valueProp={element.valueProp}
                  required={element.isRequired}
                  name={element.id}
                  disabled={
                    element.disabled || inProgress || !element.url || !allowEdit
                  }
                  fullWidth
                  label={t(element.label ?? "").toString()}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                />
              </Grid>
            );
          }
          case ElementType.asyncMultiSelect: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <AsyncTableSelect
                  url={element.url!}
                  value={values[element.id]}
                  onChange={(uuid) =>
                    // @ts-ignore
                    setValues((prevState) => ({
                      ...prevState,
                      [element.id]: uuid,
                    }))
                  }
                  multiSelect
                  required={element.isRequired}
                  name={element.id}
                  disabled={element.disabled || inProgress || !allowEdit}
                  label={t(element.label ?? "").toString()}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                />
              </Grid>
            );
          }
          case ElementType.select: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <Selector
                  options={element.options ?? []}
                  value={values[element.id]}
                  onChange={handleChange}
                  size={"small"}
                  required={element.isRequired}
                  name={element.id}
                  disabled={element.disabled || inProgress || !allowEdit}
                  hasNone={element.hasNone}
                  fullWidth
                  label={t(element.label ?? "").toString()}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                />
              </Grid>
            );
          }
          case ElementType.multiSelect: {
            let value = values[element.id];
            if (!Array.isArray(value)) {
              value = [];
            }

            const handleUpdate = (newValue: string[]) => {
              if (setValues) {
                setValues((prevState: any) => ({
                  ...prevState,
                  [element.id]: newValue,
                }));
              }
            };

            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <TableSelect
                  options={element.options ?? []}
                  label={t(element.label ?? "").toString()}
                  value={value}
                  onChange={handleUpdate}
                  name={element.id}
                  required={element.isRequired}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                  multiSelect
                />
              </Grid>
            );
          }
          case ElementType.nativeSelect: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    {t(element.label ?? "")}
                  </InputLabel>
                  <NativeSelect
                    disabled={element.disabled || inProgress || !allowEdit}
                    value={values[element.id]}
                    onChange={handleChange}
                    size={"small"}
                    required={element.isRequired}
                    inputProps={{
                      name: element.id,
                      id: element.id,
                    }}
                  >
                    <option value="" disabled />
                    {element.options?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
            );
          }
          case ElementType.color: {
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                {setValues && (
                  <Stack rowGap={2} alignItems={"flex-start"}>
                    <Typography variant={"body1"} color={"textSecondary"}>
                      {t(element.label ?? "")}
                    </Typography>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(8, 1fr)"
                      gap={1}
                      rowGap={1}
                      justifyContent={"center"}
                      sx={{ ml: 0.5 }}
                    >
                      {Palette.map((color, index) => (
                        <Card
                          key={index}
                          id={color}
                          onClick={() =>
                            // @ts-ignore
                            setValues((prevState) => ({
                              ...prevState,
                              [element.id]: color,
                            }))
                          }
                          sx={{
                            backgroundColor: color,
                            width: "30px",
                            height: "30px",
                            borderRadius: 0.5,
                            boxShadow: 2,
                            border: values.color === color ? "ridge" : "none",
                          }}
                        />
                      ))}
                    </Box>
                    <ColorPicker
                      value={values.color}
                      onChange={(color) =>
                        // @ts-ignore
                        setValues((prevState) => ({
                          ...prevState,
                          [element.id]: color.css.backgroundColor,
                        }))
                      }
                    />
                  </Stack>
                )}
              </Grid>
            );
          }
          case ElementType.date:
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <TextField
                  fullWidth
                  size={"small"}
                  type={"date"}
                  required={element.isRequired}
                  id={element.id}
                  name={element.id}
                  label={t(element.label ?? "")}
                  disabled={element.disabled || inProgress || !allowEdit}
                  variant={variant}
                  value={values[element.id]}
                  onChange={handleChange}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                  InputProps={{
                    inputProps: {
                      min: element.minValue,
                      max: element.maxValue,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            );
          case ElementType.text:
          default:
            return (
              <Grid key={element.id} item xs={12} sm={element.columns}>
                <TextField
                  fullWidth
                  size={"small"}
                  required={element.isRequired}
                  id={element.id}
                  name={element.id}
                  label={t(element.label ?? "")}
                  disabled={element.disabled || inProgress || !allowEdit}
                  variant={variant}
                  // autoComplete={"off"}
                  value={values[element.id]}
                  onChange={handleChange}
                  helperText={errors ? errors[element.id] ?? "" : ""}
                  error={errors && errors[element.id]}
                  inputProps={{
                    autoComplete: "new-value",
                  }}
                />
              </Grid>
            );
        }
      });
    };

    return renderFormElements(formElements);
  }, [
    formElements,
    allowEdit,
    values,
    inProgress,
    variant,
    handleChange,
    errors,
    smallSize,
    tabValue,
    setValues,
    t,
  ]);

  if (groups.length > 0) {
    return (
      <Stack>
        {groups.length > 0 && (
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            {groups
              .filter(
                (group) => group.formElements && group.formElements.length > 0
              )
              .map((group, index) => (
                <Tab label={t(group.label ?? "")} {...a11yProps(index)} />
              ))}
          </Tabs>
        )}
        {elements}
      </Stack>
    );
  }

  return (
    <Grid container spacing={2} alignItems={"flex-end"}>
      {elements}
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  formElements: FormElement[];
  renderFormElements: (elements: FormElement[]) => any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, renderFormElements, formElements, ...other } =
    props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/*{value === index && (*/}
      <Box p={2}>
        <Grid container spacing={2} alignItems={"flex-end"}>
          {renderFormElements(formElements)}
        </Grid>
      </Box>
      {/*)}*/}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default useFormElements;

export type Option = {
  id: any;
  name: string;
  path?: string;
};

export type FormElement = {
  id: string;
  label?: string;
  type: ElementType;
  placeholder?: string;
  columns?: number;
  disabled?: boolean;
  isRequired?: boolean;
  copyText?: boolean;
  hasNone?: boolean;
  options?: Option[];
  control?: any;
  expanded?: boolean;
  path?: string;
  formElements?: FormElement[];
  in?: string[];
  url?: string;
  labelProp?: string;
  valueProp?: string;
  minValue?: string | number;
  maxValue?: string | number;
  hasReadonly?: boolean;
  rows?: number;
  multipleValues?: boolean;
  inputAdornment?: string;
};
