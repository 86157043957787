import Breadcrumbs from "@mui/material/Breadcrumbs";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import MenuList from "@mui/material/MenuList";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { ReactNode, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { StickyHeader } from "../Common/StickyHeader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ExpandMore, Public } from "@mui/icons-material";
import { ContextAction } from "../Common/ContextMenu/ContextMenuList";
import ActionList from "../Common/ContextMenu/ActionList";
import ContextActionType from "../../models/ContextActionType";
import GridOnIcon from "@mui/icons-material/GridOn";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { ListViewHandler } from "../../hooks/common/useListView";
import SortPopOver from "../Common/SortPopOver";
import { SortHandler } from "../../hooks/sort/useSortHandler";
import BreadCrumb, { Breadcrumb, StyledBreadcrumb } from "../Common/BreadCrumb";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import CommentsPopOver from "../Comments/CommentsPopOver";
import WatchContainer from "../Common/WatchContainer";
import IAMPermission from "../../models/IAMPermission";
import PublicIcon from "@mui/icons-material/Public";
import { useIAMPermission } from "../../store/userStore";
import contextActionType from "../../models/ContextActionType";
import { ObjectType } from "../../models/ObjectType";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import Button from "@mui/material/Button";

type SettingsHeaderProps = {
  title?: string;
  textSearch?: string | null;
  breadcrumbs: Breadcrumb[];
  handleInfo?: () => void;
  containerUuid?: string;
  published?: boolean;
  hasCoverFile?: boolean;
  showDownloadDatastreams?: boolean;
  watched?: boolean;
  watchCount?: number;
  selectable?: boolean;
  onDeleteMultiple?: () => void;
  selectedContainerUUIDs?: string[];
  selectedDatastreamUUIDs?: string[];
  handleSelectMultiple?: () => void;
  handleDownloadMultipleDatastreams?: () => void;
  showWatchDetails?: boolean;
  showPublishFunctionality?: boolean;
  handleRefreshContainer?: () => void;
  showInfoIcon?: boolean;
  listViewHandler?: ListViewHandler;
  sortHandler?: SortHandler;
  children?: ReactNode;
  entityUuid?: string;
  selectedObjectType?: ObjectType;
  query?: string;
  setQuery?: (newValue: string) => void;
  dropDownActions?: ContextAction[];
  handleDropDownAction?: (action: ContextActionType, entityID: string) => void;
};

function ContentHeader({
  breadcrumbs,
  title,
  textSearch,
  handleInfo,
  containerUuid,
  published,
  hasCoverFile,
  watched,
  watchCount,
  showWatchDetails,
  showPublishFunctionality,
  handleRefreshContainer,
  showInfoIcon,
  listViewHandler,
  sortHandler,
  children,
  entityUuid,
  selectedObjectType,
  query,
  setQuery,
  dropDownActions,
  handleDropDownAction,
  selectable,
  onDeleteMultiple,
  selectedDatastreamUUIDs,
  selectedContainerUUIDs,
  handleSelectMultiple,
  handleDownloadMultipleDatastreams,
}: SettingsHeaderProps) {
  const { t } = useTranslation();
  const iam = useIAMPermission();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorReducedBreadcrumb, setAnchorReducedBreadcrumb] =
    React.useState<null | HTMLDivElement>(null);

  const open = Boolean(anchorEl);
  const openReducedBreadcrumb = Boolean(anchorReducedBreadcrumb);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorReducedBreadcrumb(null);
  };

  const reducedBreadcrumbs = useMemo(() => {
    return breadcrumbs.length >= 4;
  }, [breadcrumbs]);

  useEffect(() => {
    setAnchorReducedBreadcrumb(null);
  }, [breadcrumbs]);

  const listViewTitleTooltip = t("myContent.listView");
  const gridViewTitleTooltip = t("myContent.gridView");

  const reducedActions = useMemo(() => {
    let actions = dropDownActions;
    if (!hasCoverFile) {
      actions = actions?.filter(
        (x) => x.name !== "myContent.actions.deleteCover"
      );
    }
    if (published) {
      actions = actions?.filter((x) => x.name !== "myContent.actions.publish");
    } else {
      actions = actions?.filter(
        (x) => x.name !== "myContent.actions.unPublish"
      );
    }
    return actions;
  }, [published, dropDownActions, hasCoverFile]);

  const totalSelectedUUIDs = useMemo(() => {
    if (selectedDatastreamUUIDs && selectedContainerUUIDs) {
      return [...selectedContainerUUIDs, ...selectedDatastreamUUIDs];
    }
    if (!selectedDatastreamUUIDs && selectedContainerUUIDs) {
      return selectedContainerUUIDs;
    }
    return [];
  }, [selectedDatastreamUUIDs, selectedContainerUUIDs]);

  return (
    <StickyHeader style={{ width: "100%" }}>
      <Stack rowGap={2} pb={1}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          style={{ minHeight: 20 }}
          columnGap={1}
        >
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs && !reducedBreadcrumbs ? (
              breadcrumbs.map((breadcrumb) => (
                <BreadCrumb key={breadcrumb.href} breadcrumb={breadcrumb} />
              ))
            ) : (
              <Breadcrumbs aria-label="breadcrumb">
                <BreadCrumb breadcrumb={breadcrumbs[0]} />
                <StyledBreadcrumb
                  onClick={(event) =>
                    setAnchorReducedBreadcrumb(event.currentTarget)
                  }
                  label={<MoreHorizIcon sx={{ mt: 0.5 }} />}
                  clickable
                />
                <BreadCrumb breadcrumb={breadcrumbs[breadcrumbs.length - 1]} />
              </Breadcrumbs>
            )}

            {title && (
              <Stack flexDirection={"row"} alignItems={"center"} gap={0.5}>
                <StyledBreadcrumb
                  avatar={
                    published ? (
                      <Public color={"success"} fontSize={"small"} />
                    ) : undefined
                  }
                  onDelete={
                    dropDownActions && handleDropDownAction && handleClick
                  }
                  deleteIcon={<ExpandMore />}
                  label={title}
                />
              </Stack>
            )}
          </Breadcrumbs>

          {handleDropDownAction && reducedActions && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  marginTop: "4px",
                },
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <ActionList
                handleAction={handleDropDownAction}
                handleClose={handleClose}
                actions={reducedActions}
                entityName={title}
              />
            </Menu>
          )}

          {reducedBreadcrumbs && (
            <Menu
              id="basic-menu"
              anchorEl={anchorReducedBreadcrumb}
              open={openReducedBreadcrumb}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuList>
                {breadcrumbs.slice(1, -1).map((breadcrumb) => (
                  <Link
                    key={breadcrumb.href}
                    to={breadcrumb.href}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem>
                      <ListItemText>
                        <Typography variant={"caption"} color={"textPrimary"}>
                          {breadcrumb.name}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </Link>
                ))}
              </MenuList>
            </Menu>
          )}

          <div style={{ flexGrow: 1 }} />

          {handleSelectMultiple && onDeleteMultiple && (
            <Collapse orientation="horizontal" in={selectable}>
              <Stack flexDirection={"row"} alignItems={"center"} columnGap={1}>
                <Button
                  onClick={handleSelectMultiple}
                  style={{ textTransform: "none" }}
                >
                  <Typography variant={"caption"}>
                    {t("buttons.cancel.lowercase")}
                  </Typography>
                </Button>
                <Tooltip title={t("myContent.delete").toString()}>
                  <Button
                    onClick={onDeleteMultiple}
                    color={"error"}
                    style={{ textTransform: "none" }}
                    disabled={!totalSelectedUUIDs.length}
                  >
                    <Typography variant={"caption"}>
                      {t("buttons.deleteSelected")}{" "}
                      {totalSelectedUUIDs.length > 0 && (
                        <>({totalSelectedUUIDs.length})</>
                      )}
                    </Typography>
                  </Button>
                </Tooltip>
                {handleDownloadMultipleDatastreams &&
                  selectedDatastreamUUIDs && (
                    <Tooltip title={t("myContent.downloadDt").toString()}>
                      <Button
                        onClick={handleDownloadMultipleDatastreams}
                        style={{ textTransform: "none" }}
                        disabled={!selectedDatastreamUUIDs.length}
                      >
                        <Typography variant={"caption"}>
                          {t("myContent.actions.download")}{" "}
                          {selectedDatastreamUUIDs.length > 0 && (
                            <>({selectedDatastreamUUIDs.length})</>
                          )}
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}
              </Stack>
            </Collapse>
          )}
          <Collapse orientation="horizontal" in={!selectable}>
            <Stack flexDirection={"row"} alignItems={"center"} columnGap={1}>
              {handleSelectMultiple && onDeleteMultiple && (
                <Tooltip
                  title={t("myContent.enableMultiSelection.tooltip").toString()}
                >
                  <Button
                    onClick={handleSelectMultiple}
                    style={{ textTransform: "none" }}
                  >
                    <Typography variant={"caption"}>
                      {t("buttons.select")}
                    </Typography>
                  </Button>
                </Tooltip>
              )}
              {sortHandler && (
                <SortPopOver
                  sortHandler={sortHandler}
                  sortOptions={[
                    { label: "myContent.sortBy.label", by: "label" },
                    { label: "myContent.sortBy.createdAt", by: "createdAt" },
                    { label: "myContent.sortBy.updatedAt", by: "updatedAt" },
                    { label: "myContent.sortBy.size", by: "filesize" },
                    { label: "myContent.sortBy.mimeType", by: "mimeType" },
                  ]}
                />
              )}
              {listViewHandler && listViewHandler.listView && (
                <Tooltip title={gridViewTitleTooltip}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      localStorage.setItem("listView", JSON.stringify(false));
                      listViewHandler.handleListView(false);
                    }}
                    size={"small"}
                  >
                    <GridOnIcon color={"action"} fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              )}
              {listViewHandler && !listViewHandler.listView && (
                <Tooltip title={listViewTitleTooltip}>
                  <IconButton
                    onClick={() => {
                      localStorage.setItem("listView", JSON.stringify(true));
                      listViewHandler.handleListView(true);
                    }}
                    size={"small"}
                  >
                    <FormatListBulletedOutlinedIcon
                      color={"action"}
                      fontSize={"small"}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {showPublishFunctionality &&
                iam.has(IAMPermission.store_container_publish) &&
                !published &&
                containerUuid &&
                handleDropDownAction && (
                  <Tooltip
                    title={t("containerInfo.status.tooltip1").toString()}
                  >
                    <IconButton
                      size={"small"}
                      onClick={() =>
                        handleDropDownAction(
                          contextActionType.publish,
                          containerUuid
                        )
                      }
                    >
                      <PublicIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                )}
              {showPublishFunctionality &&
                iam.has(IAMPermission.store_container_publish) &&
                published &&
                containerUuid &&
                handleDropDownAction && (
                  <Tooltip
                    title={t("containerInfo.status.tooltip2").toString()}
                  >
                    <IconButton
                      size={"small"}
                      onClick={() =>
                        handleDropDownAction(
                          contextActionType.unPublish,
                          containerUuid
                        )
                      }
                    >
                      <PublicOffIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                )}
              {/*{showDownloadDatastreams && (*/}
              {/*  <DownloadMultipleDatastreams*/}
              {/*    containerUuid={containerUuid ?? ""}*/}
              {/*  />*/}
              {/*)}*/}
              {showWatchDetails && handleRefreshContainer && (
                <WatchContainer
                  watchCount={watchCount ?? 0}
                  watched={watched ?? false}
                  containerUuid={containerUuid ?? ""}
                  handleRefreshContainer={handleRefreshContainer}
                />
              )}
              {entityUuid && selectedObjectType && (
                <CommentsPopOver
                  entityUuid={entityUuid ?? ""}
                  selectedObjectType={selectedObjectType}
                />
              )}
              {showInfoIcon && handleInfo && (
                <Tooltip title={t("content.info.tooltip").toString()}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInfo();
                    }}
                    size={"small"}
                  >
                    <InfoOutlinedIcon color={"action"} fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Collapse>
          {children && children}
        </Stack>

        {setQuery && (
          <TextField
            placeholder={textSearch ? `${textSearch}` : `Search for ${title}`}
            variant={"standard"}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        )}
      </Stack>
    </StickyHeader>
  );
}

export default ContentHeader;
