import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import React from "react";

type SettingsContainerProps = {
  children: any;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

function SettingsContainer({ children, onClick }: SettingsContainerProps) {
  return (
    <Container maxWidth={false} sx={{ pb: 5 }}>
      <Stack onClick={onClick} flexDirection={"column"} sx={{ minHeight: "calc(100vh - 150px)" }} id={"settings-container"}>
        {/*<Stack flexDirection={"column"} onClick={e => e.stopPropagation()}>*/}
          {children}
        {/*</Stack>*/}
      </Stack>
    </Container>
  )
}

export default SettingsContainer
