import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import React from "react";
import ContextActionType from "../../../models/ContextActionType";
import { ContextAction } from "./ContextMenuList";
import { useIAMPermission } from "../../../store/userStore";
import { useTranslation } from "react-i18next";
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
type MenuListProps = {
  handleAction: (actionType: ContextActionType, entityID: string) => void;
  handleClose: () => void;
  actions: ContextAction[];
  entityID?: string;
  entityName?: string;
  handlePopoverOpen?: (event: any) => void;
  handlePopoverClose?: () => void;
  setChildActions?: React.Dispatch<React.SetStateAction<ContextAction[] | undefined>>;
  openChildActions?: boolean;
};

function ActionList({
  actions,
  handleClose,
  handleAction,
  entityID,
  entityName,
  handlePopoverOpen,
  handlePopoverClose,
  setChildActions,
  openChildActions,
}: MenuListProps) {
  const iam = useIAMPermission();
  const { t } = useTranslation();

  const handleMouseEnter = (
    children: ContextAction[] | undefined
  ) => {
    if (!handlePopoverOpen && !setChildActions) return undefined;
    if (children && handlePopoverOpen && setChildActions) {
      return (event: any) => {
        handlePopoverOpen(event);
        setChildActions(children);
      };
    }
    if (!children && handlePopoverClose && setChildActions) {
      return () => {
        handlePopoverClose();
        setChildActions(undefined);
      };
    }
  };
  return (
    <MenuList>
      {actions.map((action, index) => {
        const {
          Icon,
          type,
          name,
          divider,
          disabled,
          textColor,
          permissions,
          children,
        } = action;

        if (permissions && !iam.hasSome(permissions)) return null;

        if (divider) {
          return <Divider key={index} />;
        }
        const nameTranslated = t(name ?? "");
        return (
          <MenuItem
            disabled={disabled}
            onClick={
              children
                ? undefined
                : (e) => {
                    e.stopPropagation();
                    handleClose();
                    // @ts-ignore
                    handleAction(type, entityID);
                  }
            }
            key={index}
            sx={{
              pointerEvents: "fill",
            }}
            onMouseEnter={handleMouseEnter(children)}
            aria-owns={openChildActions ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
          >
            {Boolean(Icon) && (
              <ListItemIcon>
                {/* @ts-ignore */}
                <Icon fontSize={"small"} color={textColor} />
              </ListItemIcon>
            )}
            <ListItemText>
              <Typography variant={"caption"} color={textColor}>
                {nameTranslated?.replace("{ENTITY_NAME}", entityName ?? "")}
              </Typography>
            </ListItemText>
            {children && <ArrowRightOutlinedIcon fontSize={"small"} sx={{color: textColor, marginRight:-1.5}}/>}
          </MenuItem>
        );
      })}
    </MenuList>
  );
}

export default ActionList;
