import React from "react";
import TextField from "@mui/material/TextField";

type PreviewProps = {
  value: string;
  setValue: (newValue: any) => void;
};

function NumberField({ value, setValue }: PreviewProps) {

  return (
    <TextField
      label={"Property Value"}
      size={"small"}
      type={"number"}
      value={value}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        autoComplete: "off",
      }}
      sx={{ flex: 1 }}
      onChange={setValue}
    />
  );
}

export default NumberField;
