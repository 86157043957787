import { PropertyType } from "./PropertyType";
import DataOptions, {TextTransform} from "./DataOptions";
import DisplayOptions from "./DisplayOptions";
import ValidationOptions from "./ValidationOptions";
import Attribute from "./Attribute";
import {FlatProperty} from "./FlatProperty";
import {TagValueType} from "./TagValueType";

class Property {
  uuid?: string;
  name: string = "";
  slug: string = "";
  description: string = "";
  propertyType: PropertyType = PropertyType.BOOLEAN;
  propertyGroup: string = ""
  dataOptions: DataOptions = {}
  displayOptions: DisplayOptions = {}
  validationOptions: ValidationOptions = {}
  namespace: string = "";
  tagName: string = "";
  tagValueType: TagValueType = TagValueType.ALL;
  attributes: Attribute[] = [];

  static toFlatProperty(property: Property): FlatProperty {
    return {
      uuid: property.uuid!,
      propertyType: property.propertyType,
      attributes: property.attributes,
      namespace: property.namespace,
      description: property.description,
      tagName: property.tagName,
      tagValueType: property.tagValueType,
      name: property.name,
      slug: property.slug,
      // Validation options
      maxLength: property.validationOptions?.maxLength?.toString() ?? "",
      minLength: property.validationOptions?.minLength?.toString() ?? "",
      minValue: property.validationOptions?.minValue,
      maxValue: property.validationOptions?.maxValue,
      // Display options
      suffix: property.displayOptions?.suffix ?? "",
      prefix: property.displayOptions?.prefix ?? "",
      tooltip: property.displayOptions?.tooltip ?? "",
      placeholder: property.displayOptions?.placeholder || "",
      showCharCounter: property.displayOptions?.showCharCounter ?? false,
      showWordCounter: property.displayOptions?.showWordCounter ?? false,
      minRows: property.displayOptions?.minRows ?? 0,
      maxRows: property.displayOptions?.maxRows ?? 0,
      delimeter: property.displayOptions?.delimeter ?? false,
      requireDecimal: property.displayOptions?.requireDecimal ?? false,
      decimalLimit: property.displayOptions?.decimalLimit ?? 0,
      mask: property.displayOptions?.mask,
      // data options
      defaultValue: property.dataOptions?.defaultValue,
      textCase: property.dataOptions?.textCase ?? TextTransform.NONE,
      enableStaticSearch: property.dataOptions?.enableStaticSearch ?? false,
      multipleValues: property.dataOptions?.multipleValues ?? false,
      values: property.dataOptions?.values ?? [],
      marks: property.dataOptions?.marks ?? [],
      dataSource: property.dataOptions?.dataSource,
      propertyGroup: property.propertyGroup,
      vocabularyId: property.dataOptions?.vocabularyId,
      identifierId: property.dataOptions?.identifierId,
      step: property.dataOptions?.step,
      containerType: property.dataOptions?.containerType,
      datastreamTypes: property.dataOptions?.datastreamTypes
    };
  }
}


export default Property;
