import React, { useMemo } from "react";
import { combineStrings } from "../../../utils/utils";
import { TextTransform } from "../../../models/DataOptions";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { FlatProperty } from "../../../models/FlatProperty";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

type Props = {
  property: FlatProperty;
  value: string;
  setValue: (s: string) => void;
  readonly?: boolean;
  required: boolean;
  recommended: boolean;
  errorMessage?: string;
};

function TextAreaProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
  errorMessage,
}: Props) {
  const helperText = useMemo(() => {
    let charChars, wordCounts;
    if (property.showCharCounter) {
      let count = value.length;
      charChars = `Characters: ${count}`;
    }

    if (property.showWordCounter) {
      let count = value
        .trim()
        .split(" ")
        .filter((x) => x.length > 0).length;
      wordCounts = `Words: ${count}`;
    }
    return combineStrings(", ", charChars, wordCounts);
  }, [property.showCharCounter, property.showWordCounter, value]);

  const formattedValue = useMemo(() => {
    switch (property.textCase) {
      case TextTransform.LOWERCASE:
        return value.toLowerCase();
      case TextTransform.UPPERCASE:
        return value.toUpperCase();
      default:
        return value;
    }
  }, [property.textCase, value]);

  if (readonly) {
    return (
      <Stack rowGap={0.5}>
        <Typography variant={"caption"} color={"textSecondary"}>
          {property.name} {required && "*"} {recommended && <sup>(Rec)</sup>}
        </Typography>
        <TextField
          variant={"standard"}
          fullWidth
          size={"small"}
          value={formattedValue}
          placeholder={property.placeholder}
          multiline
          minRows={property.minRows}
          maxRows={property.maxRows}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      </Stack>
    );
  }

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <TextField
        variant={"standard"}
        // required
        fullWidth
        required={required}
        size={"small"}
        value={formattedValue}
        onKeyPress={(event) => {
          if (event.key === "enter") {
            event.preventDefault();
          }
        }}
        onChange={(e) => setValue(e.target.value)}
        label={<>{property.name} {recommended && <sup>(Rec)</sup>}</>}
        placeholder={property.placeholder}
        // rows={property.minRows}
        multiline
        minRows={property.minRows}
        maxRows={property.maxRows}
        InputProps={{
          startAdornment: property.prefix && (
            <InputAdornment position="start">{property.prefix}</InputAdornment>
          ),
          endAdornment: property.suffix && (
            <InputAdornment position="end">{property.suffix}</InputAdornment>
          ),
        }}
        helperText={errorMessage ?? helperText}
        error={Boolean(errorMessage)}
      />
    </Tooltip>
  );
}

export default TextAreaProperty;
