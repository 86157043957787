import { create } from "zustand";
import { API_URL } from "../utils/config";
import Job, {JobState} from "../models/Job";
import {fetchData} from "../utils/utils";
import {useEffect} from "react";


interface StoreState {
  list: Job[];
  initialized: boolean,
  isLoading: boolean,
  getAll: () => Promise<void>
}

export const useRunningJobsStore = create<StoreState>((set) => ({
  list: [],
  initialized: false,
  isLoading: false,
  getAll: async () => {
    set({ isLoading: true })
    try {
      const result = await fetchData(`${API_URL}/jobs?state=${JobState.PROCESSING}&size=1`)
      set({ list: result.content, initialized: true })
    } catch (ex) {
    }
    set({ isLoading: false })
  },
}));


export const useRunningJobs = (): [Job[], boolean] => {
  const store = useRunningJobsStore()

  const { list, initialized, isLoading, getAll } = store

  useEffect(() => {
    let time = setTimeout(() => {
      if (!initialized && !isLoading) {
        getAll();
      }
    }, 100)

    return () => {
      clearTimeout(time)
    }

  }, [getAll, initialized, isLoading]);

  return [list, isLoading];
};
