import Paging from "../../models/Pageable";
import Container from "../../models/Container";
import {API_URL} from "../../utils/config";
import {Datastream} from "../../models/Datastream";
import {useCallback, useEffect, useMemo, useState} from "react";
import {buildParameters, fetchData} from "../../utils/utils";
import useQuery from "../query/useQuery";

const useContainerChildren = (
  containerUUID?: string,
  requestOnInit: boolean = true,
  token?: string,
  withProperties?: boolean) => {

  const [containerResult, setContainerResult] = useState<Paging<Container>>(new Paging<Container>())
  const [datastreamResult, setDatastreamResult] = useState<Paging<Datastream>>(new Paging<Datastream>())
  const [loadingContainers, setLoadingContainers] = useState(false)
  const [loadingDatastreams, setLoadingDatastreams] = useState(false)

  const sortQuery = useQuery().get("sort") ?? "label,asc"

  const containerSortQuery = useMemo(()=>{
    if (sortQuery.includes('filesize') || sortQuery.includes('mimeType')) return 'label,asc'
    return sortQuery
  }, [sortQuery])

  const getContainers = useCallback(async (page: number) => {
    if (!containerUUID) return

    const parameters = buildParameters({
      page,
      sort: containerSortQuery,
      tk: token,
      p: withProperties
    })
    const url = `${API_URL}/containers/${containerUUID}/children${parameters}`
    setLoadingContainers(true)
    try {
      const result = await fetchData(url)
      setContainerResult(result)
    } catch (ex: any) {}
    setLoadingContainers(false)

  }, [containerUUID, containerSortQuery, token, withProperties])

  const getDatastreams = useCallback(async (page: number) => {
    if (!containerUUID) return
    const parameters = buildParameters({
      page,
      sort: sortQuery,
      tk: token
    })
    const url = `${API_URL}/containers/${containerUUID}/datastreams${parameters}`
    setLoadingDatastreams(true)
    try {
      const result = await fetchData(url)
      setDatastreamResult(result)
    } catch (ex: any) {}
    setLoadingDatastreams(false)
  }, [containerUUID, sortQuery, token])

  useEffect(() => {
    if (requestOnInit) {
      getDatastreams(0)
    }
  }, [getDatastreams, requestOnInit])
  useEffect(() => {
    if (requestOnInit) {
      getContainers(0)
    }
  }, [getContainers, requestOnInit])

  return {
    containerResult,
    datastreamResult,
    getContainers,
    getDatastreams,
    loadingContainers,
    loadingDatastreams
  }
}

export default useContainerChildren