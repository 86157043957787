import { SingleVocSelector } from "components/@lib/Inputs/Selector";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { Option } from "hooks/form/useFormElements";
import { fetchData, sortByLabel } from "utils/utils";
import { API_URL } from "../../../utils/config";
import { FlatProperty } from "../../../models/FlatProperty";
import { useMap } from "../../../hooks/common/useMap";

type DatasourceSelectorProps = {
  property: FlatProperty;
  label?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  name: string;
  labelProp?: string;
  valueProp?: string;
  disablePortal?: boolean;
};

function VocabularyValueField(props: DatasourceSelectorProps) {
  const {
    property,
    label,
    value,
    onChange,
    name,
    labelProp = "label",
    valueProp = "uuid",
    disablePortal
  } = props;

  const [options, setOptions] = useState<Option[]>([]);
  const optionMap = useMap(options);
  const selectedValue = value ? optionMap.get(value) || { id: value, name: value } : null;

  const url = useMemo(() => {
    const vocID = property?.vocabularyId;
    return (
      vocID && `${API_URL}/cm/vocabularies/${property.vocabularyId}/terms/all`
    );
  }, [property.vocabularyId]);

  useEffect(() => {
    if (!url) return;
    fetchData(url)
      .then((res) =>
        setOptions(
          res
            .map((item: any) => ({
              ...item,
              id: item[valueProp],
              name: item[labelProp],
            }))
            .sort((a: any, b: any) => sortByLabel(a, b, "name"))
        )
      )
      .catch((ex) => console.log(ex));
  }, [labelProp, url, valueProp]);


  return (
    <SingleVocSelector
      options={options}
      label={label}
      value={selectedValue}
      // @ts-ignore
      onChange={onChange}
      name={name}
      disablePortal={disablePortal}
    />
  );
}

export default VocabularyValueField;
