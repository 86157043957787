import { useEffect, useMemo, useState } from "react";
import Paging from "../../models/Pageable";
import { buildParameters, postData } from "../../utils/utils";
import { useSnackbar } from "notistack";

export const usePaginatedEntityUsage = <T>(
  endpoint: string | null,
  page: number
) => {
  const [results, setResults] = useState(new Paging<T>());
  const [isLoading, setLoading] = useState(false);
  const queryParameters = useMemo(() => {
    return buildParameters({ page: page, size: 10 });
  }, [page]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!endpoint) return;
    setLoading(true);
    (async () => {
      try {
        const response = await postData(`${endpoint}${queryParameters}`, {});
        setResults(response);
        setLoading(false);
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
        setLoading(false);
      }
    })();
  }, [endpoint, enqueueSnackbar, queryParameters]);

  return {
    results,
    isLoading,
  };
};
