import React, {useEffect, useMemo} from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import PropertyReadOnlyPreview from "../../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import { FlatProperty } from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
  value: number[];
  setValue: (s: number[]) => void;
  readonly?: boolean;
  required: boolean;
  recommended: boolean;
};

function SelectProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
}: PreviewProps) {

  const min = (property.minValue as number) || 0;
  const max = (property.maxValue as number) || 100;
  const isMulti = property.multipleValues

  const safeValue = useMemo(() => {
    if (!isMulti) return (value && value[0]) || min
    return value || [min, max]
  }, [isMulti, max, min, value])

  useEffect(() => {
    if (readonly) return
    if (isMulti) {
      if (!value) {
        setValue([min, max])
      } else {
        if (value.length !== 2) {
          setValue([value[0], value[0]])
        }
      }
    } else {
      if (!value) {
        setValue([min])
      } else if (value.length > 1){
        setValue([value[0]])
      }
    }
  }, [isMulti, max, min, readonly, setValue, value]);

  if (readonly) {
    return (
      <PropertyReadOnlyPreview
        name={property.name}
        value={value && value.join(" to ")}
        required={required}
        recommended={recommended}
      />
    );
  }
  
  const marks = property.marks

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setValue(newValue)
    } else {
      setValue([newValue])
    }
  };

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <Stack direction={"column"} rowGap={1} flex={1}>
        <Typography variant={"body2"} color={"textSecondary"}>
          {property.name} {required && "*"} {recommended && <sup>(Rec)</sup>}
        </Typography>
        <Stack px={1}>
          <Slider
            valueLabelDisplay="auto"
            value={safeValue}
            onChange={handleChange}
            step={property.step || 1}
            marks={marks}
            name={property.name}
            min={min}
            max={max}
          />
        </Stack>
      </Stack>
    </Tooltip>
  );
}

export default SelectProperty;
