import React, {useEffect, useMemo, useRef, useState} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { API_URL } from "../../../utils/config";
import { FlatProperty } from "../../../models/FlatProperty";
import { fetchData } from "../../../utils/utils";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import axios from "axios";
import AsyncTableSelect from "../../@lib/Inputs/AsyncTableSelect";

type PreviewProps = {
  property: FlatProperty;
  value: string;
  required: boolean;
  recommended: boolean;
  setValue: (s: string) => void;
  readonly?: boolean;
  errorMessage?: string;
  containerUuid?: string;
  datastreamUuid?: string;
};


type SearchResult = {
  uuid: string;
  label: string;
  parent: string;
};

function DatastreamReferenceProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
  errorMessage,
  containerUuid,
}: PreviewProps) {

  const url = useMemo(() => {
    if (!containerUuid || !property.datastreamTypes) return null
    return `${API_URL}/containers/${containerUuid}/datastreams/search?type=${property.datastreamTypes?.toString()}`
  }, [containerUuid, property.datastreamTypes]);

  const [selected, setSelected] = useState<SearchResult | null>(null);
  const cancel = useRef(undefined)

  useEffect(() => {
    if (Boolean(value)) {
      (async () => {

        if (cancel.current) {
          // @ts-ignore
          cancel.current();
        }

        const config = {
          cancelToken: new axios.CancelToken(function executor(c: any) {
            // @ts-ignore
            cancel.current = c; // keep reference for cancellation
          }),
        };

        try {
          const url = `${API_URL}/datastreams/${value}/base`
          // @ts-ignore
          const result: SearchResult = await fetchData(url, config);
          setSelected(result)
        } catch (e) {
        }
      })();
    } else {
      setSelected(null)
    }
  }, [property.dataSource, value]);

  const options = useMemo(() => {
    if (!selected) return []
    return [selected]
  }, [selected])

  if (readonly) {

    const destination = `/store/content/${containerUuid}?datastream=${value}`

    return (
      <Stack rowGap={0.5}>
        <Typography variant={"caption"} color={"textSecondary"}>
          {property.name} {required && "*"} {recommended &&  <sup>(Rec)</sup>}
        </Typography>
        {selected ? (
          <Link
            to={destination}
            target={"_blank"}
            style={{ textDecoration: "none" }}
          >
            <Button
              endIcon={<LinkIcon />}
              color={"primary"}
              sx={{ textTransform: "none" }}
              variant={"text"}
            >
              {selected.label}
            </Button>
          </Link>
        ) : (
          <Typography variant={"body1"}>{"-"}</Typography>
        )}
      </Stack>
    );
  }

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <AsyncTableSelect
        url={url!}
        defaultValue={options}
        label={property.name}
        required={required}
        recommended={recommended}
        name={"value"}
        multiSelect={false}
        value={value}
        onChange={(newValue) => setValue(newValue[0] || "")}
        helperText={errorMessage}
        error={Boolean(errorMessage)}
      />
    </Tooltip>
  );
}

export default DatastreamReferenceProperty;
