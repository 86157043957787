import { SetStateAction, useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { SelectOption } from "../../../../../models/DataOptions";
import Typography from "@mui/material/Typography";
import { FlatProperty } from "../../../../../models/FlatProperty";
import { Alert } from "@mui/lab";
import { postData, sortBy } from "../../../../../utils/utils";
import { API_URL } from "../../../../../utils/config";
import SelectOptionUsagesDialog from "./SelectOptionUsagesDialog";
import { useTranslation } from "react-i18next";

type OptionValuesTableProps = {
  values: any;
  setValues: (e: SetStateAction<any>) => void;
  allowEdit: boolean;
  variant: string;
  error?: string;
  required?: boolean;
};

function MarksTable(props: OptionValuesTableProps) {
  const { values, setValues, variant, allowEdit, error, required } = props;
  const [sortPositions, setSortPositions] = useState<String[]>([]);
  const [openUsages, setOpenUsages] = useState(false);
  const [selectedPropertyUUID, setSelectedPropertyUUID] = useState("");
  const [selectedOptionValue, setSelectedOptionValue] = useState("");
  const { t } = useTranslation();

  // Trigger sorting
  useEffect(() => {
    if (sortPositions.length > 0 && allowEdit) return;
    const array = values.marks.map((x: any) => x.label).sort();
    setSortPositions(array);
  }, [sortPositions.length, values.marks, allowEdit]);

  const handleUpdatePropertyAtIndex =
    (index: number, prop: string) => (e: any) => {
      const pos = values.marks.findIndex(
        (x: SelectOption) => x.label === sortPositions[index]
      );

      setValues((prevState: FlatProperty) => ({
        ...prevState,
        marks: prevState.marks.map((opt: any, i: number) => {
          if (i === pos) {
            return {
              ...opt,
              [prop]: e.target.value,
            };
          }
          return opt;
        }),
      }));
    };

  const handleUpdatePropertyLabel = (index: number) => (e: any) => {
    setSortPositions(
      sortPositions.map((opt: any, i: number) => {
        if (i === index) return e.target.value;
        return opt;
      })
    );

    const pos = values.marks.findIndex(
      (x: SelectOption) => x.label === sortPositions[index]
    );

    setValues((prevState: FlatProperty) => ({
      ...prevState,
      marks: prevState.marks.map((opt: any, i: number) => {
        if (i === pos) {
          return {
            ...opt,
            label: e.target.value,
          };
        }
        return opt;
      }),
    }));
  };

  const handleAddValue = () => {
    setSortPositions((prevState) => [...prevState, ""]);
    setValues((prevState: FlatProperty) => ({
      ...prevState,
      marks: [...prevState.marks, { label: "", value: "", id: -1 }],
    }));
  };

  const handleDeleteAtIndex = async (index: number, value: string) => {
    const option = values.marks.find((x: SelectOption) => x.value === value);

    if (option.id !== -1) {
      try {
        // @ts-ignore
        const result = await postData(
          `${API_URL}/cm/properties/${values.uuid}/dataOptions/${option.value}/usages`
        );
        if (result.inUse) {
          setSelectedOptionValue(option.value);
          setSelectedPropertyUUID(values.uuid);
          setOpenUsages(true);
          return;
        }
      } catch (ex) {}
    }

    setSortPositions((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);

    const pos = values.marks.findIndex(
      (x: SelectOption) => x.label === sortPositions[index]
    );

    setValues((prevState: FlatProperty) => ({
      ...prevState,
      marks: [
        ...prevState.marks.slice(0, pos),
        ...prevState.marks.slice(pos + 1),
      ],
    }));
  };

  const errors = useMemo(() => {
    return values.marks.map((opt: SelectOption) => {
      if (!opt.value || !opt.label) {
        return {
          error: false,
          errorMessage: "",
        };
      }
      const hasSameValue =
        values.marks.filter((x: SelectOption) => x.value === opt.value).length >
        1;
      const hasSameLabel =
        values.marks.filter((x: SelectOption) => x.label === opt.label).length >
        1;
      const hasError = hasSameLabel || hasSameValue;

      return {
        error: hasError,
        labelError: hasSameLabel && "Labels must me unique",
        valueError: hasSameValue && "Values must me unique",
      };
    });
  }, [values.marks]);

  if (!allowEdit) {
    return (
      <Stack direction={"column"} rowGap={2} alignItems={"flex-start"}>
        <Typography>Marks {required && "*"}</Typography>
        <Grid container gap={1}>
          {sortPositions.map((opt) => (
            <Chip label={opt} />
          ))}
        </Grid>
      </Stack>
    );
  }

  return (
    <Card variant={"outlined"}>
      <Typography sx={{ pt: 2, pl: 2 }}>{t("formElement.marks")}</Typography>
      <Stack direction={"column"} rowGap={2} alignItems={"flex-start"} p={2}>
        {error && (
          <Alert style={{ width: "100%" }} severity="warning">
            {error}
          </Alert>
        )}
        {values.marks
          .map((item: SelectOption) => {
            const index = sortPositions.findIndex((x) => x === item.label);
            const position = index !== -1 ? index : values.marks.length;
            return { ...item, position };
          })
          .sort((a: SelectOption, b: SelectOption) => sortBy(a, b, "position"))
          .map((opt: SelectOption, index: number) => (
            <Stack
              direction={"row"}
              columnGap={2}
              alignItems={"flex-start"}
              style={{ width: "100%" }}
            >
              <TextField
                label={"Label"}
                size={"small"}
                autoFocus
                // @ts-ignore
                variant={variant}
                error={errors[index].error}
                helperText={errors[index].labelError}
                value={opt.label}
                style={{ flex: 1 }}
                fullWidth
                onChange={handleUpdatePropertyLabel(index)}
              />
              <TextField
                disabled={opt.id !== -1}
                label={"Value"}
                size={"small"}
                type={"number"}
                // @ts-ignore
                variant={variant}
                style={{ flex: 1 }}
                error={errors[index].error}
                helperText={errors[index].valueError}
                fullWidth
                value={opt.value}
                onChange={handleUpdatePropertyAtIndex(index, "value")}
              />
              <IconButton
                size={"small"}
                onClick={() => handleDeleteAtIndex(index, opt.value)}
              >
                <RemoveCircleOutlineIcon fontSize={"small"} />
              </IconButton>
            </Stack>
          ))}

        <Button
          size={"small"}
          onClick={handleAddValue}
          startIcon={<AddOutlinedIcon />}
        >
          Add Option
        </Button>
      </Stack>
      <SelectOptionUsagesDialog
        open={openUsages}
        handleClose={() => setOpenUsages(false)}
        propertyUUID={selectedPropertyUUID}
        optionValue={selectedOptionValue}
      />
    </Card>
  );
}

export default MarksTable;
