import {CardActionArea, Grid, IconButton, Stack, Typography} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import {RepoxAddonInfo} from "models/RepoxAddonType";
import Card from "@mui/material/Card";

type Props = {
  apps: RepoxAddonInfo[];
};
export default function RepoxAppsMenu({ apps }: Props) {
  return (
    <Grid container spacing={1} sx={{ px: 2, pb: 2, pt: 1 }}>
      <Grid item xs={12}>
        <Typography variant={"body2"} color={"textSecondary"}>Repox Addons</Typography>
      </Grid>
      {apps.map((app) => (
        <Grid item xs={4} key={app.id} sx={{ width: 50 }}>
          <Card sx={{ border: 0 }}>
            <CardActionArea
              sx={{ py: 1 }}
              color={"primary"}
              target={"_blank"}
              component={Link}
              to={app.path}
            >
              <Stack direction={"column"} gap={1} alignItems={"center"}>
                <IconButton sx={{ backgroundColor: app.color, "&:hover": { backgroundColor: app.color, color: "white" } }}>
                  <Stack sx={{ flex: 1, color: "primary.contrastText", height: "42px", width: "42px", alignItems: "center", justifyContent: "center" }}>
                    {<app.icon fontSize={"large"} style={{ color: "inherit" }}/>}
                  </Stack>
                </IconButton>
                <Typography variant={"caption"}>{app.name}</Typography>
              </Stack>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
