import * as React from "react";
import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import {FlatProperty} from "../../../models/FlatProperty";
import SpatialProperty, {SpatialValue} from "../ControlledProperty/SpatialProperty";

type PreviewProps = {
    property: FlatProperty;
};

const initialState = new SpatialValue();

function SpatialPreview(props: PreviewProps) {
    const { property } = props;
    const [value, setValue] = useState(initialState);

    usePreviewDefaultValue<SpatialValue>(property.defaultValue, setValue, initialState);

    return (
        <SpatialProperty property={property} value={value} required={false}  recommended={false} setValue={setValue}/>
    );
}

export default SpatialPreview;
