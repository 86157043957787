import * as React from "react";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SettingsContainer from "components/Common/SettingsContainer";
import ContentHeader from "components/@lib/ContentHeader";
import Job, { JobActionDetailType, JobState } from "models/Job";
import { useUserList } from "store/userStore";
import { ActualDateTime, fetchData } from "utils/utils";
import { API_URL } from "utils/config";
import { useMap } from "hooks/common/useMap";
import withAuth from "auth/withAuth";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { useRunningJobsStore } from "../../../store/runningJobsStore";
import ProgressLoader from "../../../components/@lib/ProgressLoader";
import {transformEnum} from "../../../hooks/jobs/useJobsTableBody";

type RecordProps = {
  label: string;
  value?: string;
};

function Record(props: RecordProps) {
  const { label, value } = props;

  return (
    <Stack
      flexDirection={"column"}
      rowGap={0.5}
      alignItems={"flex-start"}
      width={"100%"}
    >
      <Typography variant={"subtitle2"} color={"textSecondary"}>
        {label}
      </Typography>
      {value && <Typography variant={"subtitle2"}>{value}</Typography>}
    </Stack>
  );
}

const ActivityDetails = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [job, setJob] = useState(new Job());
  const [users] = useUserList();
  const userMap = useMap(users, "uuid");
  const [inProgress, setInProgress] = useState(false)

  const { enqueueSnackbar } = useSnackbar();


  const fetchJob = useCallback(async (uuid: string) => {
    try {
      const res = await fetchData(`${API_URL}/jobs/${uuid}`)
      setJob(res)
      if (res.state === JobState.PROCESSING) {
        await new Promise(resolve => setTimeout(resolve, 15000))
        await fetchJob(uuid)
      }
    } catch (ex: any) {
      enqueueSnackbar(ex.message)
    }
  }, [enqueueSnackbar])

  const timeoutRef = useRef() as MutableRefObject<number>

  useEffect(() => {
    if (job.state !== JobState.PROCESSING) {
      clearInterval(timeoutRef.current)
    }
  }, [job.state]);

  useEffect(() => {
    if (!params.uuid) return;
    (async () => {
      setInProgress(true)
      await fetchJob(params.uuid!)
      setInProgress(false)
    })()
  }, [fetchJob, params.uuid]);

  const createdByName = useMemo(() => {
    const firstname = userMap.get(job.createdBy)?.firstname ?? "";
    const lastname = userMap.get(job.createdBy)?.lastname ?? "";
    return `${firstname} ${lastname}`;
  }, [job, userMap]);
  
  const downloadAction = job.jobActionDetails?.actionDetails.find(x => x.type === JobActionDetailType.download)
  const redirectAction = job.jobActionDetails?.actionDetails.find(x => x.type === JobActionDetailType.redirect)

  const { getAll: getAllRunningJobs } = useRunningJobsStore()

  useEffect(() => {
    getAllRunningJobs()
  }, [getAllRunningJobs]);

  return (
    <SettingsContainer>

      <ProgressLoader inProgress={inProgress} />

      <ContentHeader
        title={transformEnum(job.jobType)}
        breadcrumbs={[
          {
            href: "/store/my-activity",
            name: t("jobs.title"),
          },
        ]}
      />

      <Stack rowGap={3} pt={2}>
        <Card>
          <Stack rowGap={2} p={2}>
            <Typography variant={"subtitle1"}>General Info</Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Record
                  label={t("table.createdAt")}
                  value={ActualDateTime(job.createdAt)}
                />
              </Grid>
              <Grid item xs={4}>
                <Record
                  label={t("table.startAt")}
                  value={ActualDateTime(job.startAt)}
                />
              </Grid>
              <Grid item xs={4}>
                <Record
                  label={t("table.completedAt")}
                  value={ActualDateTime(job.completedAt)}
                />
              </Grid>
              <Grid item xs={4}>
                <Record label={t("table.state")} value={job.state === JobState.PROCESSING ? `${job.state} - ${job.progress}%` : job.state ?? "Unknown"} />
              </Grid>
              <Grid item xs={4}>
                <Record label={t("table.createdBy")} value={createdByName} />
              </Grid>
            </Grid>
          </Stack>
          {(downloadAction || redirectAction) && <Divider />}
          {(downloadAction || redirectAction) && (
            <CardActions sx={{ p: 1 }}>
              {downloadAction && (
                <Button
                  startIcon={<DownloadIcon />}
                  size={"small"}
                  variant={"contained"}
                  component={"a"}
                  href={downloadAction.value}
                  target={"_blank"}
                >
                  {t("buttons.download")}
                </Button>
              )}
              {redirectAction && (
                <Button
                  variant={"outlined"}
                  size={"small"}
                  component={Link}
                  to={`/store/${redirectAction.value.replace("/containers", "content")}`}
                  target={"_blank"}
                >
                  {t("buttons.open")}
                </Button>
              )}
            </CardActions>
          )}
        </Card>
        <Card sx={{ p: 2 }}>
          <Stack rowGap={2}>
            <Typography variant={"subtitle1"}>Details</Typography>
            <Divider />
            <Record
              label={"Job Parameters"}
              value={job.jobParams ? JSON.stringify(job.jobParams) : "-"}
            />
            <Record
              label={"Job Results"}
              value={job.jobResult ? JSON.stringify(job.jobResult) : "-"}
            />
          </Stack>
        </Card>
      </Stack>
    </SettingsContainer>
  );
};

export default withAuth(ActivityDetails);
