const FolderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"100%"}
    height={"100%"}
    fill="none"
    viewBox={"0 0 80 80"}
  >
    <path
      fill="#396AFC"
      d="M12 64h56a4 4 0 0 0 4-4V26.118a4 4 0 0 0-4-4H38.363a4 4 0 0 1-2.829-1.172l-3.774-3.774A4 4 0 0 0 28.93 16H12a4 4 0 0 0-4 4v40a4 4 0 0 0 4 4Z"
    />
    <rect width={60} height={32} x={10} y={25} fill="#fff" rx={2} />
    <path
      fill="#6A8FFF"
      d="M68 64H12a4 4 0 0 1-4-4V31.118a4 4 0 0 1 4-4h29.637a4 4 0 0 0 2.829-1.172l3.774-3.774A4 4 0 0 1 51.07 21H68a4 4 0 0 1 4 4v35a4 4 0 0 1-4 4Z"
    />
  </svg>
)

export default FolderIcon