import React, {ReactNode} from "react";
import Tooltip from "@mui/material/Tooltip";
import { FlatProperty } from "../../../models/FlatProperty";
import Typography from "@mui/material/Typography";
import {
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  Stack,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { styled } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@mui/material/CardContent";

type PreviewProps = {
  property: FlatProperty;
  children?: ReactNode
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({  expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  // transition: theme.transitions.create('transform', {
  //   duration: theme.transitions.duration.shortest,
  // }),
}));

function GroupProperty({ property, children }: PreviewProps) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack flex={1}>
      <Card>
        <CardActions disableSpacing>
          <Tooltip title={property.tooltip} placement={"bottom-start"}>
            <Typography flex={1} sx={{ my: 1 }}>{property.name}</Typography>
          </Tooltip>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>

        </CardActions>
        {expanded && <Divider/>}
        <Collapse in={expanded}>
          <CardContent>
            <Stack rowGap={1}>
              {children}
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </Stack>
  );
}

export default GroupProperty;
