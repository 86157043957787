import React, { useState } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import {FlatProperty} from "../../../models/FlatProperty"; // Add css for snow theme

type PreviewProps = {
  property: FlatProperty;
  value: string;
  required: boolean;
  recommended: boolean;
  setValue: (s: string) => void;
  readonly?: boolean;
};

type QuillProps = {
  value: string;
  setValue: (s: string) => void;
};

function RichTextProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
}: PreviewProps) {

  if (readonly) {
    return (
      <Stack rowGap={0.5}>
        <Typography variant={"caption"} color={"textSecondary"}>
          {property.name} {required && "*"} {recommended && <sup>(Rec)</sup>}
        </Typography>
        {value ? <div className={"notes"} dangerouslySetInnerHTML={{ __html: value }} /> : <Typography>{"-"}</Typography>}
      </Stack>
    );
  }

  return (
    <Stack rowGap={1}>
      <FormLabel>{property.name} {required && "*"} {recommended && <sup>(Rec)</sup>}</FormLabel>
      <Quill value={value} setValue={setValue} />
    </Stack>
  );
}

export default RichTextProperty;


const Quill = ({value, setValue}:QuillProps) => {
  const { quill, quillRef } = useQuill();

  const [initialized, setInitialized] = useState(false);

  React.useEffect(() => {
    if (quill && value && !initialized) {
      quill.clipboard.dangerouslyPasteHTML(value);
      setInitialized(true);
    }
  }, [initialized, quill, value]);

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        setValue(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef, setValue]);

  return (
    <div style={{ height: 280 }}>
      <div style={{ width: "100%", height: 200 }}>
        <div ref={quillRef} />
      </div>
    </div>
  )
};
