import React from "react";
import "../../App.css";
import { ContainerObjectType } from "../../models/Container";
import ContainerCover from "../ContainerCover";
import ContentCardBox from "../@lib/ContentCardBox";

type ContainerCardBoxProps = {
  isActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLElement>;
  isSelected?: boolean;
  onSelect?: React.MouseEventHandler<HTMLElement>;
  selectable?: boolean;
  container: ContainerObjectType;
  isParentShared?: boolean;
};

const ContainerCardBox = React.memo((props: ContainerCardBoxProps) => {
  const {
    isParentShared,
    container,
  } = props;


  const isShared = isParentShared || container.shared;

  return (
    <ContentCardBox
      {...props}
      cover={<ContainerCover size={60} coverFile={container.coverFile} />}
      uuid={container.uuid}
      isShared={isShared}
      label={container.label}
      type={container.type}
      color={container.color}
      publishedAt={container.publishedAt}
    />
  );
});

export default ContainerCardBox;
