const ImageFileIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 74H65C67.2091 74 69 72.2091 69 70V35L40 6H15C12.7909 6 11 7.79086 11 10V70C11 72.2091 12.7909 74 15 74Z"
      fill="#B65C0A"/>
    <path d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z" fill="#B65C0A"/>
    <path d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z" fill="black" fillOpacity="0.3"/>
    <path d="M62 63H30L46.3721 39L62 63Z" fill="white"/>
    <path d="M37 63H18L27.7209 49L37 63Z" fill="white"/>
  </svg>

);
export default ImageFileIcon;
