import { PropertyType } from "../../../../../../models/PropertyType";
import Schema from "../../../../../../models/Schema";
import { UseType } from "../../../../../../models/UseType";
import ValidationOptions from "../../../../../../models/ValidationOptions";
import { SelectOption } from "../../../../../../models/DataOptions";
import { SettingProperty } from "../../../../AdministrativeTools/SystemSettings/SystemSettingDialog";
import { FlatProperty } from "../../../../../../models/FlatProperty";
import { urlPattern } from "../../../../../../hooks/common/useUrlValidation";

export function validateValue(
  value: any,
  propertyType: PropertyType,
  isRequired: boolean,
  validationOptions?: ValidationOptions
) {
  if (!isRequired && !value) return null;
  else if (isRequired && !value) return "This field is required";

  switch (propertyType) {
    case PropertyType.SELECT:
      if (isRequired && !value[0]) return "This field is required";
      break;
    case PropertyType.URL:
      // eslint-disable-next-line
      const validURL = urlPattern.test(value);
      return validURL ? null : "Expected format: http(s)://www.example";
    case PropertyType.TEXT_AREA:
    case PropertyType.TEXT_FIELD:
      if (
        validationOptions &&
        validationOptions.minLength &&
        validationOptions.minLength > value.length
      ) {
        return `Text should contain at least ${validationOptions.minLength} characters`;
      }
      if (
        validationOptions &&
        validationOptions.maxLength &&
        validationOptions.maxLength < value.length
      ) {
        return `Text should contain up to ${validationOptions.maxLength} characters`;
      }
      break;
    case PropertyType.NUMBER:
      if (
        validationOptions &&
        validationOptions.minValue &&
        validationOptions.minValue > value
      ) {
        return `The minimum value is ${validationOptions.minValue}`;
      }
      if (
        validationOptions &&
        validationOptions.maxValue &&
        validationOptions.maxValue < value
      ) {
        return `The maximum value is ${validationOptions.maxValue}`;
      }
      break;
    case PropertyType.DATE:
      // TODO: Add date validation
      break;
    default:
  }

  return null;
}

type ContainerProperty = {
  propertyUuid: string;
  value?: string;
  uuid: string;
};

export function validateContainerProperties(
  containerProperties: ContainerProperty[],
  schema: Schema
) {
  const errors = new Map<string, string>();

  schema.schemaGroups.forEach((group) => {
    group.properties.forEach((schemaProperty) => {
      const properties = containerProperties.filter(
        (x) => x.propertyUuid === schemaProperty.property.uuid
      );
      const type = schemaProperty.property.propertyType;
      const isRequired = schemaProperty.use === UseType.REQUIRED;
      const validationOptions = schemaProperty.property.validationOptions;

      properties.forEach((containerProperty) => {
        const error = validateValue(
          containerProperty?.value,
          type!,
          isRequired,
          validationOptions
        );
        if (error) {
          errors.set(containerProperty.uuid!, error);
        }
      });
    });
  });

  return errors;
}

export function validatePreferencesProperties(settings: SettingProperty[]) {
  const errors = new Map<string, string>();

  settings.forEach((property) => {

    if (!property.required) return errors

    if (property.value === null || property.value === undefined) {
      errors.set(property.uuid, `Field ${property.property.name} is required`);
    }

    if (typeof property.value == "string" && property.value === "") {
      errors.set(property.uuid, `Field ${property.property.name} is required`);
    }

  });

  return errors;
}

function hasDuplicates(array: string[]) {
  return new Set(array).size !== array.length;
}

export const validate = (values: any) => {
  const errorMap = new Map<keyof FlatProperty, string>();

  if (!values.name) errorMap.set("name", "Field name is required");

  if (!values.propertyType)
    errorMap.set("propertyType", "Field type is required");

  if (values.defaultValue) {
    const property = FlatProperty.toProperty(values);
    const defaultValueError = validateValue(
      values.defaultValue,
      values.propertyType,
      false,
      property.validationOptions
    );
    if (defaultValueError) errorMap.set("defaultValue", defaultValueError);
  }

  if (values.values.length > 0 && values.propertyType === PropertyType.SELECT) {
    if (
      values.values.find(
        (option: SelectOption) => !option.label || !option.value
      )
    ) {
      errorMap.set("values", "Each option should have a label and a value");
    }

    const keys = values.values.map((x: SelectOption) => x.value);
    const labels = values.values.map((x: SelectOption) => x.label);

    if (hasDuplicates(keys)) {
      errorMap.set("values", "Each option should have a unique value");
    }

    if (hasDuplicates(labels)) {
      errorMap.set("values", "Each option should have a unique label");
    }
  }

  return errorMap;
};
