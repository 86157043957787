import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import PropertyReadOnlyPreview from "../../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import {FlatProperty} from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
  value: boolean;
  setValue: (s: boolean) => void;
  readonly?: boolean;
  required: boolean;
  recommended: boolean;
};

function BooleanProperty({ property, value, setValue, readonly, required, recommended }: PreviewProps) {

  if (readonly) {
    return (
      <PropertyReadOnlyPreview name={property.name} value={value ? "Yes" : "No"} required={required} recommended={recommended}/>
    )
  }

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <FormControlLabel
        sx={{ flex: 1 }}
        control={
          <Switch
            required={required}
            checked={value}
            onChange={() => setValue(!value)}
            name={property.name}
          />
        }
        label={<>{property.name} {recommended && <sup>(Rec)</sup>}</>}
      />
    </Tooltip>
  );
}

export default BooleanProperty;
