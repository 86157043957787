import { create } from "zustand";
import { createCommonStore, Entity, useEntityList } from "./core";
import { API_URL } from "../utils/config";
import PropertyGroup from "../models/PropertyGroup";

const endpoints = {
  getAll: `${API_URL}/cm/property_groups/all`,
  update: `${API_URL}/cm/property_groups`,
  create: `${API_URL}/cm/property_groups`,
  remove: `${API_URL}/cm/property_groups`
}

export const usePropertyGroupStore = create<Entity<PropertyGroup>>(createCommonStore(endpoints, "uuid"));

export const usePropertyGroups = (): [PropertyGroup[], boolean] => {
  const store = usePropertyGroupStore();
  return useEntityList(store);
};
