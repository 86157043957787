import React, { Fragment } from "react";
import { Stack } from "@mui/material";
import StoreSearch from "./StoreSearch";
import LanguagePicker from "components/Navigation/NavigationActions/LanguageNavItem";
import RepoxApps from "components/Navigation/NavigationActions/RepoxAppsNavItem";
import NotificationIcon from "components/Navigation/NavigationActions/NotificationsNavItem";
import AccountNavItem from "components/Navigation/NavigationActions/AccountNavItem";
import SearchHistoryNavItem from "components/Navigation/NavigationActions/SearchHistoryNavItem";
import ThemeSelectionNavItem from "components/Navigation/NavigationActions/ThemeSelectionNavItem";
import MyActivityNavItem from "components/Navigation/NavigationActions/MyActivityNavItem";

const Header = () => {
  return (
    <Fragment>
      <StoreSearch />
      <SearchHistoryNavItem />
      <Stack ml={"auto"} flexDirection={"row"} columnGap={1} alignItems={"center"}>
        <ThemeSelectionNavItem/>
        <LanguagePicker />
        <RepoxApps />
        <MyActivityNavItem/>
        <NotificationIcon />
        <AccountNavItem />
      </Stack>
    </Fragment>
  );
};

export default Header;
