import {Stack} from "@mui/material";
import {ReactNode} from "react";

interface Props {
  children: ReactNode
}

function Toolbar(props: Props) {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        backgroundColor: "background.default",
        px: 3,
        py: 1,
        position: "sticky",
        top: 0,
        zIndex: 1100,
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      {props.children}
    </Stack>
  )
}

export default Toolbar