import SearchIcon from "@mui/icons-material/Search";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useTranslation} from "react-i18next";
import {buildParameters} from "../../utils/utils";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.3s",
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.grey["300"], 0.2)
      : alpha(theme.palette.grey["500"], 0.15),
  "&:hover": {
    transition: "all 0.3s",
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.grey["300"], 0.1)
        : alpha(theme.palette.grey["500"], 0.05),
    borderColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.grey["300"], 0.2)
        : alpha(theme.palette.grey["500"], 0.15),
  },
  border: "1px solid transparent",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(1),
    width: "auto",
  },
  height: 40,
  display: "flex",
  alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: "500px",
  fontSize: 14,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    color: theme.palette.text.primary,
  },
  input: {
    "&::placeholder": {
      color: theme.palette.text.primary,
    },
  },
}));

type SearchFieldProps = {
  value: string | undefined;
  setValue: (newValue: string) => void;
  handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleClickSettings?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  handleClose: () => void;
  onEnter: (parameters: string) => void;
};

export default function GlobalSearchField({
  value,
  setValue,
  handleClick,
  handleClickSettings,
  handleClose,
  onEnter,
}: SearchFieldProps) {

  const { t } = useTranslation();
  const search = t("toolbar.searchbar");

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={`${search}...`}
        inputProps={{ "aria-label": "search" }}
        onClick={(event) => {
          handleClose();
          handleClick(event);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            const parameters = buildParameters({
              text: value ? value : "",
            });
            onEnter(parameters);
            handleClose();
          }
        }}
      />
      <div style={{ flexGrow: 1 }}></div>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        columnGap={1}
        sx={{ mr: 1 }}
      >
        {value && (
          <IconButton size={"small"} onClick={() => setValue("")}>
            <CloseOutlinedIcon
              sx={{ color: "common.white" }}
              fontSize={"medium"}
            />
          </IconButton>
        )}
        {handleClickSettings &&
          <IconButton
            size={"small"}
            onClick={(event) => {
              handleClose();
              handleClickSettings(event);
            }}
          >
            <TuneOutlinedIcon fontSize={"medium"} />
          </IconButton>
        }
      </Stack>
    </Search>
  );
}
