import Stack from "@mui/material/Stack";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { getIconForProperty } from "../../pages/ControlPanel/DataModeling/MetadataSchemas/Schemas/SchemaDetails/PropertyList";
import * as React from "react";
import { PropertyType, PropertyTypes } from "../../models/PropertyType";

type Props = {
  propertyType: (typeof PropertyTypes)[0];
  activeType: PropertyType;
  onClick: (type: PropertyType) => void;
};
function PropertyTypeCard(props: Props) {
  const { propertyType, activeType, onClick } = props;
  return (
    <Card
      sx={{
        flex: 1,
        display: "flex",
        borderColor:
          propertyType.id === activeType ? "primary.main" : "default",
      }}
    >
      <CardActionArea
        style={{ flex: 1 }}
        onClick={() => onClick(propertyType.id)}
      >
        <Stack columnGap={2} direction={"row"} px={2} py={3}>
          {getIconForProperty(propertyType.id, "medium")}
          <Stack rowGap={1}>
            <Typography>{propertyType.name}</Typography>
            <Typography variant={"body1"} color={"textSecondary"}>
              {propertyType.description}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
}

export default PropertyTypeCard;
