import CardActionArea from "@mui/material/CardActionArea";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import React, { Fragment, useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import Typography from "@mui/material/Typography";
import { Add, MoreVert } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import { GroupSchemaAction, SchemaDragDestination } from "./index";
import SchemaGroup from "../../../../../../models/SchemaGroup";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";

type PlusButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const StyledButton = styled(CardActionArea)(({ theme }) => ({
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  height: 47,
  width: 47,
  // @ts-ignore
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // @ts-ignore
  backgroundColor: theme.palette.background.neutral,
}));

function PlusButton({ onClick, disabled }: PlusButtonProps) {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <Typography color={"textSecondary"} sx={{ pt: 0.5 }}>
        <Add color={"inherit"} sx={{ color: "textSecondary" }} />
      </Typography>
    </StyledButton>
  );
}

type GroupButtonProps = {
  group: SchemaGroup;
  isActive: boolean;
  isDraggingOver: boolean;
  handleSelect: () => void;
  dispatchAction: (action: GroupSchemaAction, group: SchemaGroup) => void;
  disabled?: boolean;
};

type StyledCardProps = {
  isActive: boolean;
  isDraggingOver: boolean;
  theme: any;
};

const StyledCard = styled(CardActionArea)(
  ({ theme, isActive, isDraggingOver }: StyledCardProps) => ({
    backgroundColor: isDraggingOver
      ? "#C5F2C7"
      : isActive
      ? theme.palette.background.paper
      : theme.palette.background.neutral,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    height: 47,
    width: 170,
    paddingTop: isActive ? 0 : 1,
    paddingLeft: isActive ? 23 : 24,
    paddingRight: isActive ? 0 : 1,
    borderLeft: isActive ? `1px solid ${theme.palette.primary.main}` : "none",
    borderRight: isActive ? `1px solid ${theme.palette.primary.main}` : "none",
    borderTop: isActive ? `1px solid ${theme.palette.primary.main}` : "none",
    borderBottom: isActive
      ? `1px solid ${theme.palette.background.paper}`
      : `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  })
);

function GroupButton({
  group,
  isActive,
  handleSelect,
  dispatchAction,
  isDraggingOver,
  disabled,
}: GroupButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <StyledCard
        onClick={handleSelect}
        isActive={isActive}
        isDraggingOver={isDraggingOver}
      >
        <Typography
          noWrap
          variant={"button"}
          color={isActive ? "primary" : "textSecondary"}
        >
          {group.name}
        </Typography>
        <IconButton size={"small"} disabled={disabled} onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </StyledCard>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            dispatchAction(GroupSchemaAction.edit, group);
            handleClose();
          }}
        >
          {t("cm.actions.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchAction(GroupSchemaAction.moveLeft, group);
            handleClose();
          }}
        >
          {t("cm.actions.moveLeft")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchAction(GroupSchemaAction.moveRight, group);
            handleClose();
          }}
        >
          {t("cm.actions.moveRight")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchAction(GroupSchemaAction.remove, group);
            handleClose();
          }}
        >
          {t("cm.actions.remove")}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

type PropertyGroupProps = {
  group: SchemaGroup;
  isActive: boolean;
  handleSelect: () => void;
  dispatchAction: (action: GroupSchemaAction, group: SchemaGroup) => void;
  disabled?: boolean;
};

const Group = ({
  group,
  isActive,
  handleSelect,
  dispatchAction,
  disabled,
}: PropertyGroupProps) => (
  <Droppable
    droppableId={`${SchemaDragDestination.groups}_${group.uuid}`}
    direction={"horizontal"}
  >
    {(provided, snapshot) => (
      <div ref={provided.innerRef} {...provided.droppableProps}>
        <Stack>
          <GroupButton
            dispatchAction={dispatchAction}
            group={group}
            isActive={isActive}
            handleSelect={handleSelect}
            isDraggingOver={snapshot.isDraggingOver}
            disabled={disabled}
          />
        </Stack>
        {/*{provided.placeholder}*/}
      </div>
    )}
  </Droppable>
);

type PropertyGroupTabsProps = {
  propertyGroups: SchemaGroup[];
  activeIndex: number;
  hasGroup: boolean;
  disabled?: boolean;
  setActiveIndex: (index: number) => void;
  dispatchAction: (action: GroupSchemaAction, group: SchemaGroup) => void;
};

const StyledStack = styled(Stack)(() => ({
  // flex: 1,
  maxWidth: "100%",
  overflow: "auto",
  marginLeft: 271,
  marginBottom: -1,
  columnGap: 12,
}));

function SchemaGroupTabs({
  propertyGroups,
  dispatchAction,
  activeIndex,
  setActiveIndex,
  hasGroup,
  disabled,
}: PropertyGroupTabsProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(!hasGroup);
  }, [hasGroup, disabled]);

  return (
    <StyledStack direction={"row"}>
      {propertyGroups.map((group, index) => (
        <Group
          group={group}
          disabled={disabled}
          isActive={index === activeIndex}
          handleSelect={() => setActiveIndex(index)}
          dispatchAction={dispatchAction}
        />
      ))}

      {!disabled && (
        <Tooltip
          placement={"right"}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          title={
            hasGroup
              ? t("schemaDetails.createGroupSchema.tooltip1").toString()
              : t("schemaDetails.createGroupSchema.tooltip2").toString()
          }
        >
          <div>
            <PlusButton
              disabled={disabled}
              onClick={() =>
                dispatchAction(GroupSchemaAction.create, new SchemaGroup())
              }
            />
          </div>
        </Tooltip>
      )}
    </StyledStack>
  );
}

export default SchemaGroupTabs;
