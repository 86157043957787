import * as React from "react";
import useCollapsedLayout from "hooks/ui/useCollapsedLayout";
import ModernLayout from "components/@lib/ModernLayout";
import ExploreIcon from "@mui/icons-material/Explore";
import GuideHeader from "../../components/GuideHeader";
import GuideLogo, { GuideLogoIconOnly } from "components/@lib/Graphics/GuideLogo";

type GuideLayoutProps = {
  children: React.ReactNode;
};

function GuideLayout({ children }: GuideLayoutProps) {
  useCollapsedLayout();

  return (
    <ModernLayout
      menuItems={menuItems}
      header={<GuideHeader />}
      logo={<GuideLogo />}
      logoIcon={<GuideLogoIconOnly/>}
      rootPath={"/guide"}
    >
      {children}
    </ModernLayout>
  );
}

export default GuideLayout;

const menuItems = [
  {
    icon: ExploreIcon,
    name: "guides.navigation.guides",
    path: "guide/applications",
  },
];
