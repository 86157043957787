import Stack from "@mui/material/Stack";
import DefaultCover from "../../../../../assets/images/default_image.png";
import {FileType} from "../../../../../models/Import";

interface AssetPlaceHoldersProps {
    filetype?: FileType
}

function AssetPlaceHolder({filetype}: AssetPlaceHoldersProps) {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      rowGap={1}
      position={"relative"}
    >
      <img
        alt={""}
        src={DefaultCover}
        style={{
          width: filetype === "IMAGE" ? 120 : 80,
          height: filetype === "IMAGE" ? 120 : 80,
          objectFit: "contain",
          borderRadius: 4
        }}
      />
    </Stack>
  );
}

export default AssetPlaceHolder
