import EntityType from "./EntityType";

export const AppRoute = {
  [EntityType.schema]:
    "/store/control-panel/data-modeling/metadata-schemas/schemas",
  [EntityType.property]:
    "/store/control-panel/data-modeling/metadata-schemas/properties",
  [EntityType.propertyGroup]:
    "/store/control-panel/data-modeling/metadata-schemas/properties",
  [EntityType.attribute]:
    "/store/control-panel/data-modeling/metadata-schemas/attributes",
  [EntityType.containerType]:
    "/store/control-panel/data-modeling/content-model/container-types",
  [EntityType.datastreamType]:
    "/store/control-panel/data-modeling/content-model/datastream-types",
  [EntityType.user]: "/store/control-panel/user-management/users",
  [EntityType.tag]: "/store/control-panel/tags",
  [EntityType.vocabulary]:
    "/store/control-panel/data-modeling/metadata-schemas/vocabularies",
  [EntityType.namespace]:
    "/store/control-panel/data-modeling/metadata-schemas/namespaces",
  [EntityType.mapping]:
    "/store/control-panel/data-modeling/metadata-schemas/mappings",
  [EntityType.apiKey]: "/store/control-panel/api-keys",
  [EntityType.xrApplication]: "/xr/applications",
  [EntityType.portal]: "/portal",
  [EntityType.oaipmhSet]: "/store/control-panel/interoperability/oai-pmh",
  [EntityType.workflow]: "/store/control-panel/data-modeling/workflows",
  [EntityType.identifier]: "/store/control-panel/data-modeling/metadata-schemas/identifiers",
};
