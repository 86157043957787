import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import SelectProperty from "../ControlledProperty/SelectProperty";
import {FlatProperty} from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
};

const initialState1 = "";
const initialState2: string[] = [];

function SelectPreview({ property }: PreviewProps) {
  const [value, setValue] = useState(initialState1);
  const [values, setValues] = useState<string[]>(initialState2);

  usePreviewDefaultValue<string>(
    property.defaultValue,
    setValue,
    initialState1
  );
  usePreviewDefaultValue<string[]>(
    property.defaultValue,
    setValues,
    initialState2
  );

  return (
    <SelectProperty
      property={property}
      value={property.multipleValues ? values : value}
      setValue={property.multipleValues ? setValues : setValue}
      required={false}
      recommended={false}
    />
  );
}

export default SelectPreview;
