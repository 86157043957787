import React from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Option } from "../../../hooks/form/useFormElements";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

type PreviewProps = {
  value: string;
  setValue: (newValue: any) => void;
};

const booleanOptions = [
  { id: "true", name: "True" },
  { id: "false", name: "False" },
];

function BooleanValueField({ value, setValue }: PreviewProps) {

  return (
    <FormControl fullWidth sx={{ flex: 1 }}>
      <InputLabel id="demo-simple-select-label" size={"small"}>
        Property Value
      </InputLabel>
      <Select
        label={"Property Value"}
        MenuProps={{
          disablePortal: true,
        }}
        onChange={setValue}
        value={value}
        size={"small"}
      >
        {booleanOptions.map((opt: Option) => (
          <MenuItem key={opt.id} value={opt.id}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {opt.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default BooleanValueField;
