import * as React from "react";
import WebIcon from "@mui/icons-material/Web";
import ModernLayout from "../../../../components/@lib/ModernLayout";
import PortalHeader from "../../components/PortalHeader";
import StoreLogo, {StoreLogoIconOnly} from "../../../../components/@lib/Graphics/StoreLogo";

type DefaultLayoutProps = {
  children: React.ReactNode;
};

function DefaultLayout({ children }: DefaultLayoutProps) {
  return (
    <ModernLayout
      menuItems={menuItems}
      header={<PortalHeader />}
      logo={<StoreLogo />}
      logoIcon={<StoreLogoIconOnly/>}
      rootPath={"/portal"}
    >
      {children}
    </ModernLayout>
  );
}

export default DefaultLayout;

const menuItems = [
  {
    icon: WebIcon,
    name: "Portals",
    path: "portal",
    color: "primary.main",
    description: "",
  },
];
