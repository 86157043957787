import EntityType from "./EntityType";
import {API_URL} from "../../utils/config";

export const ApiEndpoint = {
  [EntityType.schema]: `${API_URL}/cm/schemas`,
  [EntityType.property]: `${API_URL}/cm/properties`,
  [EntityType.propertyGroup]: `${API_URL}/cm/property_groups`,
  [EntityType.attribute]: `${API_URL}/cm/attributes`,
  [EntityType.containerType]: `${API_URL}/cm/container_types`,
  [EntityType.datastreamType]: `${API_URL}/cm/datastream_types`,
  [EntityType.user]: `${API_URL}/users`,
  [EntityType.tag]: `${API_URL}/tags`,
  [EntityType.vocabulary]: `${API_URL}/cm/vocabularies`,
  [EntityType.namespace]: `${API_URL}/cm/property_namespaces`,
  [EntityType.mapping]: `${API_URL}/cm/mappings`,
  [EntityType.apiKey]: `${API_URL}/admin/dev/api_keys`,
  [EntityType.xrApplication]: `${API_URL}/xr/applications`,
  [EntityType.portal]: `${API_URL}/portals`,
  [EntityType.oaipmhSet]: `${API_URL}/admin/oaipmh/sets`,
  [EntityType.workflow]: `${API_URL}/admin/workflows`,
  [EntityType.identifier]: `${API_URL}/cm/identifiers`,
}
