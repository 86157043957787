const GeoLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2304 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256 306.104C256 306.104 341.334 251.303 341.334 170.667C341.334 123.539 303.129 85.3335 256 85.3335C208.872 85.3335 170.667 123.539 170.667 170.667C170.667 251.303 256 306.104 256 306.104ZM256 225.28C286.162 225.28 310.614 200.829 310.614 170.667C310.614 140.505 286.162 116.053 256 116.053C225.838 116.053 201.387 140.505 201.387 170.667C201.387 200.829 225.838 225.28 256 225.28Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.395 355.916L279.683 341.596C279.975 341.405 280.348 341.157 280.797 340.855C281.694 340.25 282.896 339.425 284.36 338.387C287.283 336.312 291.275 333.367 295.98 329.604C305.33 322.127 317.825 311.162 330.426 297.116C344.486 281.443 360.092 260.332 370.816 234.667H398.438C399.356 234.667 400.156 235.292 400.378 236.182L447.378 424.182C447.694 425.444 446.739 426.667 445.438 426.667H298.666L257.395 355.916Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.984 290.692L91.3233 405.334H186.858L119.984 290.692ZM70.627 400.16C67.2608 413.624 77.4445 426.667 91.3233 426.667H224L112 234.667L70.627 400.16Z"
      fill="currentColor"
    />
    <path
      d="M730.504 204.921C755.55 204.921 774.669 209.513 787.86 218.696C801.218 227.88 807.897 241.906 807.897 260.774C807.897 284.317 796.292 300.263 773.083 308.612C776.255 312.452 779.845 317.127 783.852 322.638C787.86 328.148 791.951 334.159 796.125 340.671C800.299 347.016 804.307 353.611 808.147 360.457C811.988 367.136 815.41 373.731 818.416 380.243H783.101C779.929 374.232 776.506 368.221 772.832 362.21C769.159 356.032 765.402 350.105 761.562 344.428C757.888 338.584 754.215 333.157 750.541 328.148C746.868 322.971 743.445 318.38 740.272 314.372C737.935 314.539 735.931 314.623 734.261 314.623C732.592 314.623 731.005 314.623 729.503 314.623H714.225V380.243H682.667V209.179C690.347 207.509 698.529 206.424 707.212 205.923C715.894 205.255 723.659 204.921 730.504 204.921ZM732.759 232.221C726.08 232.221 719.902 232.472 714.225 232.973V289.076H728C735.681 289.076 742.443 288.658 748.287 287.824C754.131 286.989 758.973 285.486 762.814 283.315C766.821 281.145 769.827 278.223 771.83 274.549C773.834 270.876 774.836 266.2 774.836 260.523C774.836 255.18 773.834 250.672 771.83 246.998C769.827 243.325 766.905 240.403 763.064 238.232C759.391 236.062 754.966 234.559 749.79 233.724C744.614 232.722 738.937 232.221 732.759 232.221Z"
      fill="currentColor"
    />
    <path
      d="M855.396 380.243V206.674H966.851V233.474H886.954V276.302H958.085V302.601H886.954V353.444H972.862V380.243H855.396Z"
      fill="currentColor"
    />
    <path
      d="M1063.76 204.921C1088.98 204.921 1108.26 209.513 1121.62 218.696C1135.14 227.88 1141.91 242.24 1141.91 261.776C1141.91 271.961 1140.07 280.727 1136.4 288.074C1132.89 295.254 1127.71 301.181 1120.87 305.857C1114.02 310.365 1105.67 313.704 1095.82 315.875C1085.97 318.046 1074.7 319.131 1062.01 319.131H1046.23V380.243H1014.67V209.179C1022.02 207.509 1030.12 206.424 1038.97 205.923C1047.98 205.255 1056.25 204.921 1063.76 204.921ZM1066.02 232.221C1058 232.221 1051.41 232.472 1046.23 232.973V292.081H1061.51C1076.7 292.081 1088.39 289.994 1096.57 285.82C1104.76 281.479 1108.85 273.38 1108.85 261.525C1108.85 255.848 1107.76 251.089 1105.59 247.249C1103.42 243.409 1100.41 240.403 1096.57 238.232C1092.73 236.062 1088.14 234.559 1082.8 233.724C1077.62 232.722 1072.03 232.221 1066.02 232.221Z"
      fill="currentColor"
    />
    <path
      d="M1209.04 293.334C1209.04 302.851 1210.21 311.534 1212.55 319.382C1214.89 327.062 1218.23 333.658 1222.57 339.168C1227.08 344.511 1232.5 348.685 1238.85 351.691C1245.19 354.696 1252.37 356.199 1260.39 356.199C1268.23 356.199 1275.33 354.696 1281.68 351.691C1288.02 348.685 1293.36 344.511 1297.71 339.168C1302.21 333.658 1305.64 327.062 1307.97 319.382C1310.31 311.534 1311.48 302.851 1311.48 293.334C1311.48 283.816 1310.31 275.217 1307.97 267.536C1305.64 259.688 1302.21 253.093 1297.71 247.75C1293.36 242.24 1288.02 237.982 1281.68 234.976C1275.33 231.971 1268.23 230.468 1260.39 230.468C1252.37 230.468 1245.19 231.971 1238.85 234.976C1232.5 237.982 1227.08 242.24 1222.57 247.75C1218.23 253.26 1214.89 259.855 1212.55 267.536C1210.21 275.217 1209.04 283.816 1209.04 293.334ZM1344.54 293.334C1344.54 308.194 1342.29 321.302 1337.78 332.656C1333.44 344.01 1327.43 353.528 1319.75 361.208C1312.23 368.722 1303.38 374.399 1293.2 378.24C1283.01 382.08 1272.16 384 1260.64 384C1249.12 384 1238.18 382.08 1227.83 378.24C1217.64 374.399 1208.71 368.722 1201.03 361.208C1193.51 353.528 1187.5 344.01 1182.99 332.656C1178.49 321.302 1176.23 308.194 1176.23 293.334C1176.23 278.473 1178.49 265.449 1182.99 254.262C1187.5 242.908 1193.6 233.39 1201.28 225.709C1209.13 218.029 1218.14 212.268 1228.33 208.428C1238.51 204.587 1249.28 202.667 1260.64 202.667C1271.99 202.667 1282.76 204.587 1292.95 208.428C1303.13 212.268 1311.98 218.029 1319.5 225.709C1327.18 233.39 1333.27 242.908 1337.78 254.262C1342.29 265.449 1344.54 278.473 1344.54 293.334Z"
      fill="currentColor"
    />
    <path
      d="M1488.02 380.243C1483.18 370.392 1477.08 359.539 1469.74 347.684C1462.39 335.828 1454.71 324.391 1446.69 313.37C1443.35 317.712 1439.68 322.888 1435.67 328.899C1431.83 334.91 1427.91 341.088 1423.9 347.433C1420.06 353.611 1416.47 359.622 1413.13 365.466C1409.79 371.31 1407.12 376.236 1405.12 380.243H1369.3C1376.82 366.051 1385.5 351.524 1395.35 336.663C1405.2 321.636 1415.89 305.94 1427.41 289.577L1371.81 206.674H1409.38L1447.7 266.033L1485.52 206.674H1521.58L1466.73 289.326C1479.42 306.525 1490.61 322.804 1500.29 338.166C1510.14 353.528 1518.41 367.553 1525.09 380.243H1488.02Z"
      fill="currentColor"
    />
    <path
      d="M2069.28 314.57C2069.28 325.95 2067.62 335.987 2064.3 344.682C2060.97 353.377 2056.43 360.665 2050.68 366.546C2045.05 372.3 2038.47 376.648 2030.93 379.588C2023.38 382.529 2015.39 384 2006.95 384C1998.51 384 1990.52 382.529 1982.98 379.588C1975.43 376.648 1968.78 372.3 1963.03 366.546C1957.4 360.665 1952.93 353.377 1949.61 344.682C1946.28 335.987 1944.62 325.95 1944.62 314.57C1944.62 303.191 1946.28 293.217 1949.61 284.651C1952.93 275.956 1957.4 268.732 1963.03 262.978C1968.78 257.096 1975.43 252.685 1982.98 249.744C1990.52 246.803 1998.51 245.333 2006.95 245.333C2015.39 245.333 2023.38 246.803 2030.93 249.744C2038.47 252.685 2045.05 257.096 2050.68 262.978C2056.43 268.732 2060.97 275.956 2064.3 284.651C2067.62 293.217 2069.28 303.191 2069.28 314.57ZM2055.48 314.57C2055.48 305.62 2054.32 297.629 2052.02 290.596C2049.72 283.436 2046.46 277.363 2042.24 272.376C2038.02 267.389 2032.91 263.617 2026.9 261.06C2021.02 258.375 2014.37 257.032 2006.95 257.032C1999.54 257.032 1992.82 258.375 1986.81 261.06C1980.93 263.617 1975.88 267.389 1971.66 272.376C1967.44 277.363 1964.18 283.436 1961.88 290.596C1959.58 297.629 1958.43 305.62 1958.43 314.57C1958.43 323.521 1959.58 331.576 1961.88 338.736C1964.18 345.769 1967.44 351.778 1971.66 356.765C1975.88 361.752 1980.93 365.588 1986.81 368.273C1992.82 370.958 1999.54 372.3 2006.95 372.3C2014.37 372.3 2021.02 370.958 2026.9 368.273C2032.91 365.588 2038.02 361.752 2042.24 356.765C2046.46 351.778 2049.72 345.769 2052.02 338.736C2054.32 331.576 2055.48 323.521 2055.48 314.57Z"
      fill="currentColor"
    />
    <path
      d="M1837.2 381.124V248.211H1913.92V259.527H1850.24V305.749H1907.01V316.873H1850.24V369.808H1918.9V381.124H1837.2Z"
      fill="currentColor"
    />
    <path
      d="M1784.23 318.215H1797.27V377.479C1794.84 378.502 1790.04 379.78 1782.88 381.315C1775.85 382.977 1767.16 383.808 1756.8 383.808C1748.1 383.808 1740.05 382.274 1732.63 379.205C1725.22 376.136 1718.76 371.725 1713.26 365.971C1707.89 360.089 1703.67 352.865 1700.6 344.298C1697.53 335.604 1696 325.694 1696 314.57C1696 303.446 1697.66 293.601 1700.99 285.034C1704.31 276.34 1708.79 269.115 1714.41 263.362C1720.17 257.48 1726.81 253.005 1734.36 249.936C1741.9 246.867 1749.96 245.333 1758.52 245.333C1768.37 245.333 1776.49 246.42 1782.88 248.593C1789.28 250.639 1793.94 252.621 1796.88 254.539L1792.47 265.663C1788.89 263.106 1784.03 261.06 1777.9 259.526C1771.76 257.864 1765.11 257.032 1757.95 257.032C1751.3 257.032 1745.04 258.247 1739.15 260.676C1733.27 263.106 1728.16 266.75 1723.81 271.609C1719.46 276.467 1716.01 282.477 1713.45 289.637C1711.02 296.798 1709.81 305.109 1709.81 314.57C1709.81 323.393 1710.83 331.384 1712.88 338.545C1714.92 345.577 1717.99 351.651 1722.08 356.765C1726.3 361.752 1731.48 365.588 1737.62 368.273C1743.76 370.958 1750.85 372.3 1758.91 372.3C1765.43 372.3 1770.86 371.981 1775.21 371.341C1779.69 370.574 1782.69 369.871 1784.23 369.232V318.215Z"
      fill="currentColor"
    />
  </svg>
)

export const GeoLogoIconOnly = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"100%"}
    height={"100%"}
    fill="none"
    viewBox={"0 0 512 512"}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M257 306.104s85.334-54.802 85.334-135.438c0-47.128-38.205-85.333-85.334-85.333-47.128 0-85.333 38.205-85.333 85.333 0 80.636 85.333 135.438 85.333 135.438Zm0-80.824c30.162 0 54.614-24.452 54.614-54.614 0-30.162-24.452-54.613-54.614-54.613-30.162 0-54.613 24.451-54.613 54.613S226.838 225.28 257 225.28ZM258.395 355.915l22.288-14.319c.292-.192.665-.44 1.114-.742.897-.605 2.099-1.43 3.563-2.468a255.871 255.871 0 0 0 11.62-8.783c9.35-7.477 21.845-18.442 34.446-32.488 14.06-15.673 29.666-36.784 40.39-62.449h27.622a2 2 0 0 1 1.94 1.515l47 188a2 2 0 0 1-1.94 2.485H299.666l-41.271-70.751ZM120.984 290.691l-28.66 114.642h95.534l-66.874-114.642ZM71.627 400.159c-3.366 13.464 6.818 26.507 20.696 26.507H225l-112-192-41.373 165.493Z"
      clipRule="evenodd"
    />
  </svg>
)
export default GeoLogo
