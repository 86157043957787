import {SpatialValue} from "../components/Properties/ControlledProperty/SpatialProperty";

export type SelectOption = {
  label: string
  value: string
  position?: number
  id?: number
}

type DataOptions = {
  textCase?: TextTransform
  defaultValue?: string | string[] | boolean | SpatialValue | number[] | number
  dataSource?: DataSource,
  enableStaticSearch?: boolean
  multipleValues?: boolean
  values?: any
  marks?: any
  vocabularyId?: string
  identifierId?: string
  step?: number
  datastreamTypes?: string[]
  containerType?: string
}

export enum TextTransform {
  NONE = "MIXED",
  LOWERCASE ="LOWERCASE",
  UPPERCASE ="UPPERCASE",
}

export enum DataSource {
  containerType = "CONTAINER_TYPE",
  datastreamType = "DATASTREAM_TYPE",
}

export default DataOptions
