import LinearProgress from "@mui/material/LinearProgress";
import React from "react";

function ToolbarProgressIndicator({ loading }: { loading: boolean }) {
  if (!loading) return null;
  return (
    <LinearProgress
      style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 9999 }}
    />
  );
}

export default ToolbarProgressIndicator;
