import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { FileCopy as CopyIcon, OpenInNew as OpenIcon } from '@mui/icons-material/';
import { getTenantId } from "../../../utils/utils";
import { Box, DialogContent, DialogTitle, Grid, IconButton, Tab, Tabs, TextField, Typography } from "@mui/material";
import Dialog from '../../../components/@lib/Dialog';

interface SurveyData {
  questions: any[];
}

interface SendDialogProps {
  open: boolean;
  handleClose: () => void;
  surveyData?: SurveyData;
}

interface Action {
  title: string;
  action: () => void;
}

const SendSurveyDialog: React.FC<SendDialogProps> = ({ open, handleClose, surveyData }) => {
  const params = useParams();
  const uuid = params.surveyUUID;

  const [embedHeight, setEmbedHeight] = useState(surveyData ? 300 + surveyData.questions.length * 100 : 300);
  const [embedWidth, setEmbedWidth] = useState(640);
  const [activeTab, setActiveTab] = useState(0);
  // const [busy, setBusy] = useState(false);
  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // eslint-disable-next-line
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const surveyURL = `https://${window.location.host}/${getTenantId()}/survey/${uuid}`;
  const summaryURL = `https://${window.location.host}/${getTenantId()}/survey/summary/${uuid}`;
  const surveyEmbedURL = `<iframe src="${surveyURL}?embedded=true" width="${embedWidth}" height="${embedHeight}" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`;
  const summaryEmbedURL = `<iframe src="${summaryURL}?embedded=true" width="${embedWidth}" height="${embedHeight}" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`;

  const copyToClipboard = (text: string, message: string) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const primaryActions: Record<number, Action> = [
    { title: 'Copy survey link', action: () => copyToClipboard(surveyURL, 'Link copied to clipboard!') },
    { title: 'Copy embed link', action: () => copyToClipboard(surveyEmbedURL, 'Embedding code copied to clipboard!') }
  ]

  const secondaryActions: Record<number, Action> = [
    { title: 'Copy summary link', action: () => copyToClipboard(summaryURL, 'Link copied to clipboard!') },
    { title: 'Copy summary embed link', action: () => copyToClipboard(summaryEmbedURL, 'Link copied to clipboard!') },
  ]

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      primaryAction={primaryActions[activeTab].action}
      primaryActionTitle={primaryActions[activeTab].title}
      secondaryAction={secondaryActions[activeTab].action}
      secondaryActionTitle={secondaryActions[activeTab].title}
    >
      <DialogTitle>Share Survey</DialogTitle>
      <DialogContent>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={2}>
          <Tabs centered value={activeTab} onChange={handleChangeTab} aria-label="Survey Tabs">
            <Tab icon={<OpenIcon />} {...a11yProps(0)} />
            <Tab icon={<CopyIcon />} {...a11yProps(1)} />
          </Tabs>
        </Box>

        {activeTab===0&&<Grid item container spacing={2} alignItems={'center'}>
          <Grid item xs={12}>
            <Typography>Survey link</Typography>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item xs>
              <TextField disabled value={surveyURL} variant="outlined" fullWidth />
            </Grid>
            <Grid item>
              <IconButton onClick={primaryActions[0].action}><CopyIcon /></IconButton>
            </Grid>
            <Grid item>
              <a href={surveyURL} target='_blank' rel="noreferrer"><OpenIcon /></a>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography>Summary link</Typography>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item xs>
              <TextField disabled value={summaryURL} variant="outlined" fullWidth />
            </Grid>
            <Grid item>
              <IconButton onClick={secondaryActions[0].action}><CopyIcon /></IconButton>
            </Grid>
            <Grid item>
              <a href={summaryURL} target='_blank' rel="noreferrer"><OpenIcon /></a>
            </Grid>
          </Grid>

          </Grid>}

        {activeTab===1&&<Grid item container spacing={2} alignContent={'center'}>

          <Grid item xs={12}>
            <Typography>Survey embed code</Typography>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item xs>
              <TextField disabled value={surveyEmbedURL} variant="outlined" fullWidth />
            </Grid>
            <Grid item>
              <IconButton onClick={primaryActions[1].action}><CopyIcon /></IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography>Summary embed code</Typography>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item xs>
              <TextField disabled value={summaryEmbedURL} variant="outlined" fullWidth />
            </Grid>
            <Grid item>
              <IconButton onClick={secondaryActions[1].action}><CopyIcon /></IconButton>
            </Grid>
          </Grid>


          <Grid item xs={6}>
            <Typography>Width</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Height</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField type="number" inputProps={{ min: 640, step: 10 }} value={embedWidth} variant="outlined" onChange={(e) => setEmbedWidth(parseInt(e.target.value))} />
          </Grid>
          <Grid item xs={6}>
            <TextField type="number" inputProps={{ min: 300, step: 10 }} value={embedHeight} variant="outlined" onChange={(e) => setEmbedHeight(parseInt(e.target.value))} />
          </Grid>

        </Grid>}

      </DialogContent>
    </Dialog>
  );
};

export default SendSurveyDialog;
