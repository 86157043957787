import {PropertyHolder} from "./Container";

export class ImportContainer extends PropertyHolder {
  label: string = "";
  type: string = "";
  color: string = "";
  typeName: string = "";
}

export class ImportExcelPreview {
  valid: boolean=false;
  errors: string[]=[];
  warnings: string[]=[];
  containerTotal: number=0;
  containerCount: number=0;
  warningCount: number=0;
  containers: ImportContainer[]=[];

}

export class ImportArchivePreview {
  valid: boolean=false;
  errors: string[]=[];
  warnings: string[]=[];
  containerTotal: number=0;
  containerCount: number=0;
  datastreamTotal: number=0;
  datastreamCount: number=0;
}

export type FileType = "IMAGE" | "AUDIO" | "VIDEO" | "FILE" | "ICON";

export enum FileExtensions {
  "IMAGE"= ".jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp,.svg",
  "AUDIO"= ".mp3,.wav,.ogg,.flac,.aac,.m4a,.wma",
  "VIDEO"= ".mp4,.mkv,.flv,.avi,.mov,.wmv,.mpeg",
  "FILE"= ".doc,.docx,.pdf,.xls,.xlsx,.txt,.csv,.zip,.rar,.7z,.ppt,.pptx",
  "ICON"= ".jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp,.svg",
}

export type ImageDimensions = {
  height: number;
  width: number;
}

export type FileSection = {
  key: string;
  title: string;
  filetype: FileType;
};
