import React, { ReactNode } from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import "../../../App.css";
import PublicIcon from "@mui/icons-material/Public";
import Box from "@mui/material/Box";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { useTranslation } from "react-i18next";
import {styled} from "@mui/material/styles";

type ContentCardProps = {
  isActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLElement>;
  isSelected?: boolean;
  onSelect?: React.MouseEventHandler<HTMLElement>;
  selectable?: boolean;
  cover: ReactNode;
  uuid: string;
  isShared: boolean;
  color?: string;
  publishedAt?: string;
  label: string;
  type: string;
  icon?: ReactNode
};

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.primary.light
}))

const ContentCardBox = React.memo((props: ContentCardProps) => {
  const {
    onClick,
    isActive,
    onDoubleClick,
    isSelected,
    onSelect,
    selectable,
    cover,
    uuid,
    isShared,
    color,
    publishedAt,
    label,
    type,
    // icon
  } = props;

  const { t } = useTranslation();

  return (
    <Stack
      sx={{ width: "100%", overflow: "hidden" }}
      direction={"column"}
      position={"relative"}
      rowGap={1.5}
      onClick={selectable ? onSelect : onClick}
      id={uuid}
      onDoubleClick={onDoubleClick}
    >
      <Card
        variant={"elevation"}
        sx={{
          border: "1px solid",
          borderColor: isActive ? "primary.main" : "divider",
          backdropFilter: "blur(8px)",
          bgcolor: "rgba(255, 255, 255, 0.1)",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 130,
          }}
        >
          {selectable && (
            <Checkbox
              size={"small"}
              checked={isSelected}
              id={uuid}
              //onChange={onSelect}
              sx={{ position: "absolute", top: 0, left: 0 }}
            />
          )}

          {cover}

          {/*{icon && (*/}
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      position: "absolute",*/}
          {/*      left: 0,*/}
          {/*      top: 0,*/}
          {/*      ml: 1,*/}
          {/*      mt: 1,*/}
          {/*      width: 24,*/}
          {/*      height: 24,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {icon}*/}
          {/*  </Box>*/}
          {/*)}*/}

          {color && (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                mr: 1,
                mt: 1,
                width: 40,
                height: 6,
                borderRadius: 2,
                backgroundColor: color,
              }}
            />
          )}

          {publishedAt && (
            <Tooltip title={t("tooltip.published")}>
              <PublicIcon
                fontSize={"small"}
                color={"primary"}
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  mr: isShared ? 4 : 1,
                  mb: 1,
                }}
              />
            </Tooltip>
          )}

          {isShared && (
            <Tooltip title={t("tooltip.shared")}>
              <PeopleOutlinedIcon
                fontSize={"small"}
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  mr: 1,
                  mb: 1,
                }}
              />
            </Tooltip>
          )}
        </CardContent>
      </Card>

      <Stack
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        rowGap={1}
      >
        <Typography
          variant={"body1"}
          noWrap
          style={{
            textAlign: "center",
            fontSize: 14,
            maxWidth: "100%", // Ensure the text does not overflow
          }}
        >
          {label}
        </Typography>
        <StyledText noWrap variant={"body2"}>
          {type}
        </StyledText>
      </Stack>
    </Stack>
  );
});

export default ContentCardBox;
