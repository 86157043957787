import { create } from "zustand";

interface StoreState {
  drawerOpen: boolean;
  showPropertiesInAccordion: boolean;
  toggleLayout: () => void;
  togglePropertiesInAccordion: () => void;
}

export const useUIStore = create<StoreState>((set) => ({
  drawerOpen: JSON.parse(localStorage.getItem("isOpen") || "false"),
  showPropertiesInAccordion: JSON.parse(localStorage.getItem("properties_in_accordion") || "false"),
  toggleLayout: () => {
    set(state => {
      localStorage.setItem("isOpen", JSON.stringify(!state.drawerOpen));
      return {
        drawerOpen: !state.drawerOpen
      }
    })
  },
  togglePropertiesInAccordion: () => {
    set(state => {
      localStorage.setItem("properties_in_accordion", JSON.stringify(!state.showPropertiesInAccordion));
      return {
        showPropertiesInAccordion: !state.showPropertiesInAccordion
      }
    })
  },
}));

