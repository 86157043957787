import {
  ElementType,
  FormElement,
} from "../../../../../hooks/form/useFormElements";
import { ChangeEvent, useMemo } from "react";
import EntitySlug from "../../ContentModel/EntitySlug";
import * as React from "react";

export const useSchemaFormElements = (schemaUuid?: string): FormElement[] => {
  return useMemo(() => {
    return [
      {
        id: "id",
        label: "id",
        type: ElementType.custom,
        control: (
          values: any,
          setValues: (e: ChangeEvent) => void,
          allowEdit: boolean,
          variant: string,
          inProgress: boolean
        ) => (
          <EntitySlug
            values={values}
            setValues={setValues}
            allowEdit={allowEdit}
            variant={variant}
            inProgress={inProgress}
            slugPrefix={"sc:"}
          />
        ),
        columns: 12,
      },
      {
        id: "description",
        label: "formElement.description",
        type: ElementType.textArea,
        columns: 12,
      },
      {
        id: "rootElement",
        label: "formElement.rootElement",
        type: ElementType.text,
        isRequired: true,
        columns: 12,
      },
      {
        id: "namespaces",
        label: "formElement.namespaces",
        type: ElementType.textArea,
        columns: 12,
      },
      {
        id: "managed",
        label: "formElement.managed",
        type: ElementType.boolean,
        disabled: !!schemaUuid,
        columns: 12,
      },
    ];
  }, [schemaUuid]);
};
