import React, { Fragment, useMemo, useState } from "react";
import PropertyReadOnlyPreview from "../../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import { FlatProperty } from "../../../models/FlatProperty";
import Tooltip from "@mui/material/Tooltip";
import { API_URL } from "../../../utils/config";
import { useVocabularyTermNamesStore } from "../../../hooks/properties/useVocabularyTermNames";
import TermDialog from "../../../pages/ControlPanel/DataModeling/MetadataSchemas/Vocabularies/VocabularyDetails/TermDialog";
import { useIAMPermission } from "../../../store/userStore";
import IAMPermission from "../../../models/IAMPermission";
import AsyncFlatTableSelect from "../../@lib/Inputs/AsyncFlatTableSelect";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { AddCircle } from "@mui/icons-material";

type PreviewProps = {
  property: FlatProperty;
  value: string[];
  setValue: (newValue: any) => void;
  readonly?: boolean;
  errorMessage?: string;
  required: boolean;
  recommended: boolean;
};

function VocabularyProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
  errorMessage,
}: PreviewProps) {
  const [forceRefreshID, setForeRefreshID] = useState(0);
  const [openTermDialog, setOpenTermDialog] = useState(false);
  const url = useMemo(() => {
    const vocID = property.vocabularyId;
    return (
      vocID &&
      `${API_URL}/cm/vocabularies/${vocID}/terms/all?q=${forceRefreshID}`
    );
  }, [forceRefreshID, property.vocabularyId]);

  const { termMap, loading, getTermNames } = useVocabularyTermNamesStore()
  const iam = useIAMPermission()

  if (readonly) {
    const names = value
      .map((uuid) => termMap.get(uuid)?.label || "N/A")
      .join(", ");

    return (
      <PropertyReadOnlyPreview
        name={property.name}
        value={loading ? "Loading.." : names}
        required={required}
        recommended={recommended}
      />
    );
  }

  const handleCreate = () => setOpenTermDialog(true);
  const hasEditPermission = iam.has(
    IAMPermission.store_settings_contentModel_vocabularies_edit
  );

  const defaultValues = value.map((uuid) => ({
    label: termMap.get(uuid)?.label || "N/A",
    uuid: uuid,
  }));

  return (
    <Fragment>
      <Tooltip title={property.tooltip} placement={"bottom-start"}>
        <Stack>
          <AsyncFlatTableSelect
            label={property.name}
            value={value}
            defaultValue={defaultValues}
            onChange={(newValue) => setValue(newValue)}
            name={property.name}
            required={required}
            recommended={recommended}
            helperText={errorMessage}
            error={Boolean(errorMessage)}
            multiSelect={true}
            url={url!}
          />
          {hasEditPermission && handleCreate && (
            <Button
              startIcon={<AddCircle />}
              sx={{ alignSelf: "flex-start" }}
              size={"small"}
              onClick={handleCreate}
            >
              Create Option
            </Button>
          )}
        </Stack>

      </Tooltip>
      {hasEditPermission && (
        <TermDialog
          open={openTermDialog}
          handleClose={() => setOpenTermDialog(false)}
          term={undefined}
          onCreate={(term) => {
            setForeRefreshID((p) => p + 1)
            setValue([...value, term.uuid])
            getTermNames([...value, term.uuid])
          }}
          onUpdate={() => {}}
          onDelete={() => {}}
          vocUuid={property.vocabularyId!}
        />
      )}
    </Fragment>
  );
}

export default VocabularyProperty;
