import usePaletteStore, { Palette } from "store/useThemeConfiguration";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import BasicPopper from "../../../Common/BasicPopper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import PaletteIcon from '@mui/icons-material/Palette';
import ListItemIcon from "@mui/material/ListItemIcon";
import {Radio} from "@mui/material";

const options = [
  {
    id: Palette.classic,
    name: "toolbar.themes.classic",
  },
  {
    id: Palette.dark,
    name: "toolbar.themes.dark",
  },
  {
    id: Palette.os,
    name: "toolbar.themes.auto",
  },
];

function PaletteSelector() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const { selectPalette, palette } = usePaletteStore();
  const {t} = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack flexDirection={"column"} alignItems={"flex-end"}>
      <IconButton
        id="notificationsPopper"
        aria-controls={open ? "notificationsPopper-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <PaletteIcon />
      </IconButton>
      <BasicPopper
        id={"notificationsPopper-menu"}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        title={t("toolbar.themes.title")}
      >
        <MenuList sx={{ width: 200 }}>

          {options.map(option => (
            <MenuItem key={option.id} onClick={() => selectPalette(option.id)}>
              <ListItemIcon>
                <Radio size={"small"} checked={palette === option.id}/>
              </ListItemIcon>
              <ListItemText>
                <Typography variant={"caption"}>
                  {t(option.name)}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </BasicPopper>
    </Stack>
  );
}

export default PaletteSelector;
