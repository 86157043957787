const PDFIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 74H65C67.2091 74 69 72.2091 69 70V35L40 6H15C12.7909 6 11 7.79086 11 10V70C11 72.2091 12.7909 74 15 74Z"
      fill="#871933"
    />
    <path
      d="M53.4043 44.7812V59H49.9766V44.7812H53.4043ZM58.9316 50.6992V53.3359H52.4277V50.6992H58.9316ZM59.4883 44.7812V47.4277H52.4277V44.7812H59.4883Z"
      fill="white"
    />
    <path
      d="M39.6465 59H36.4922L36.5117 56.3633H39.6465C40.2975 56.3633 40.8477 56.207 41.2969 55.8945C41.7526 55.582 42.0977 55.1165 42.332 54.498C42.5729 53.873 42.6934 53.1048 42.6934 52.1934V51.5781C42.6934 50.8945 42.625 50.2956 42.4883 49.7812C42.3581 49.2604 42.1628 48.8275 41.9023 48.4824C41.6419 48.1309 41.3164 47.8672 40.9258 47.6914C40.5417 47.5156 40.099 47.4277 39.5977 47.4277H36.4336V44.7812H39.5977C40.5612 44.7812 41.4434 44.9473 42.2441 45.2793C43.0514 45.6048 43.748 46.0736 44.334 46.6855C44.9264 47.291 45.3822 48.0104 45.7012 48.8438C46.0267 49.6706 46.1895 50.5885 46.1895 51.5977V52.1934C46.1895 53.196 46.0267 54.1139 45.7012 54.9473C45.3822 55.7806 44.9297 56.5 44.3438 57.1055C43.7578 57.7109 43.0645 58.1797 42.2637 58.5117C41.4694 58.8372 40.597 59 39.6465 59ZM38.4551 44.7812V59H35.0273V44.7812H38.4551Z"
      fill="white"
    />
    <path
      d="M25.6738 54.1758H22.1387V51.5293H25.6738C26.1556 51.5293 26.5462 51.4479 26.8457 51.2852C27.1452 51.1224 27.3633 50.8978 27.5 50.6113C27.6432 50.3184 27.7148 49.9896 27.7148 49.625C27.7148 49.2344 27.6432 48.873 27.5 48.541C27.3633 48.209 27.1452 47.9421 26.8457 47.7402C26.5462 47.5319 26.1556 47.4277 25.6738 47.4277H23.4277V59H20V44.7812H25.6738C26.8132 44.7812 27.793 44.9896 28.6133 45.4062C29.4401 45.8229 30.0749 46.3958 30.5176 47.125C30.9603 47.8477 31.1816 48.6745 31.1816 49.6055C31.1816 50.5234 30.9603 51.3242 30.5176 52.0078C30.0749 52.6914 29.4401 53.2253 28.6133 53.6094C27.793 53.987 26.8132 54.1758 25.6738 54.1758Z"
      fill="white"
    />
    <path d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z" fill="#490011" />
  </svg>
);
export default PDFIcon;
