import {styled} from "@mui/styles";

export const StickyHeader = styled("div")(({ theme }) => ({
	position: "sticky",
	top: 57,
	zIndex: 2,
	paddingTop: 8,
	marginTop: -8,
	backgroundColor: theme.palette.background.default,
}));
