import Box from "@mui/material/Box";
import React from "react";
import FileIcon from "../@lib/Graphics/FileIcon";
import {Datastream} from "../../models/Datastream";
import VideoFileIcon from "../@lib/Graphics/VideoFileIcon";
import AudioFileIcon from "../@lib/Graphics/AudioFileIcon";
import SpreadsheetIcon from "../@lib/Graphics/SpreadsheetIcon";
import DocumentIcon from "../@lib/Graphics/DocumentIcon";
import PresentationIcon from "../@lib/Graphics/PresentationIcon";
import PDFIcon from "../@lib/Graphics/PDFIcon";
import ImageFileIcon from "../@lib/Graphics/ImageFileIcon";
import LinkIcon from "../@lib/Graphics/LinkIcon";
import { RepoxType } from "../../models/RepoxType";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  size: 36 | 60;
  datastream: Datastream;
}

export const getDatastreamIcon = (datastream: Datastream) => {
  const {  mimeType } = datastream.bitstreamFile ?? { mimeType: "" }

  switch (true) {
    case mimeType.includes("pdf"):
      return <PDFIcon />
    case mimeType.includes("image"):
      return <ImageFileIcon />
    case mimeType.includes("video"):
      return <VideoFileIcon />
    case mimeType.includes("audio"):
      return <AudioFileIcon />
    case mimeType.includes("excel"):
      return <SpreadsheetIcon />
    case mimeType.includes("officedocument.spreadsheetml"):
    case mimeType.includes("opendocument"):
      return <DocumentIcon />
    case mimeType.includes("powerpoint"):
    case mimeType.includes("officedocument.presentation"):
      return <PresentationIcon />
    case mimeType.includes("repox/shortcut"):
    case datastream.repoxType === RepoxType.REPOX_GEO:
    case datastream.repoxType === RepoxType.REPOX_GUIDE:
    case datastream.repoxType === RepoxType.REPOX_LINK:
      return <LinkIcon />
    default: return <FileIcon />
  }

}

function DatastreamCover({ datastream, size }: Props) {

  return datastream.bitstreamFile?.thumbnails.mediumUrl ? (
    <Tooltip
      title={
        <img
          src={datastream.bitstreamFile.thumbnails.mediumUrl}
          alt={""}
          style={{ borderRadius: 8 }}
        />
      }
      placement="right"
      style={{ maxWidth: "400px" }}
      arrow
    >
      <img
        src={datastream.bitstreamFile.thumbnails.mediumUrl}
        alt={""}
        style={{
          width: size,
          height: size,
          objectFit: "cover",
          borderRadius: 8,
        }}
      />
    </Tooltip>
  ) : (
    <Box sx={{ width: size, height: size, minWidth: size }}>
      {getDatastreamIcon(datastream)}
    </Box>
  );
}

export default DatastreamCover;
