import * as React from "react";
import {FlatProperty} from "../../../models/FlatProperty";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

type PreviewProps = {
  property: FlatProperty;
};

function IdentifierPreview(props: PreviewProps) {
  const { property } = props;

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <Stack rowGap={0.5} sx={{ width: "100%" }}>
        <Typography variant={"caption"} color={"textSecondary"}>
          {property.name}
        </Typography>
      </Stack>
    </Tooltip>
  );
}

export default IdentifierPreview;
