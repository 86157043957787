import * as React from "react";
import useCollapsedLayout from "../../../../hooks/ui/useCollapsedLayout";
import ModernLayout from "../../../../components/@lib/ModernLayout";
import SurveyHeader from "../../components/SurveyHeader";
import SurveyLogo, { SurveyLogoIconOnly } from "../../../../components/@lib/Graphics/SurveyLogo";

type SurveyLayoutProps = {
  children: React.ReactNode;
};

function SurveyLayout({ children }: SurveyLayoutProps) {
  useCollapsedLayout();

  return (
    <ModernLayout
      menuItems={[]}
      header={<SurveyHeader />}
      logo={<SurveyLogo />}
      logoIcon={<SurveyLogoIconOnly/>}
      rootPath={"/surveys"}
    >
      {children}
    </ModernLayout>
  );
}

export default SurveyLayout;
