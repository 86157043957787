import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import {FlatProperty} from "../../../models/FlatProperty";
import SliderProperty from "../ControlledProperty/SliderProperty";

type PreviewProps = {
  property: FlatProperty;
};

const initialState = [0]

function TogglePreview({ property }: PreviewProps) {
  const [value, setValue] = useState(initialState);

  usePreviewDefaultValue<number[]>(property.defaultValue, setValue, initialState)

  return (
    <SliderProperty property={property} value={value} setValue={setValue} required={false} recommended={false}/>
  );
}

export default TogglePreview;
