import * as React from "react";
import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import TextAreaProperty from "../ControlledProperty/TextAreaProperty";
import {FlatProperty} from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
};

const initialState = "";

function TextAreaPreview(props: PreviewProps) {
  const { property } = props;
  const [value, setValue] = useState(initialState);

  usePreviewDefaultValue<string>(property.defaultValue, setValue, initialState);

  return (
    <TextAreaProperty property={property} value={value} setValue={setValue} required={false} recommended={false}/>
  );
}

export default TextAreaPreview;
