import * as React from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import ContextActionType from "../../models/ContextActionType";
import { ContextAction } from "../Common/ContextMenu/ContextMenuList";
import ActionList from "../Common/ContextMenu/ActionList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useTranslation} from "react-i18next";
import { useState} from "react";
import Popover from "@mui/material/Popover";

type TableMenuProps = {
  handleMenuAction: (menuAction: ContextActionType, entityID: string) => void
  actions: ContextAction[]
  entityID: string;
  entityName: string;
  disabled?: boolean;
};

export default function TableMenu(props: TableMenuProps) {
  const { actions, handleMenuAction, entityID, entityName, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();
  const [anchorChildActions, setAnchorChildActions] = useState(null);
  const [childActions, setChildActions] = useState<ContextAction[] | undefined>(undefined);
  const openChildActions = Boolean(anchorChildActions);

  const handlePopoverOpen = (event: any) => {
    setAnchorChildActions(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorChildActions(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorChildActions(null);
    setAnchorEl(null);
  };

  if (actions.length === 0) return null

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ActionList
          entityID={entityID}
          entityName={t(entityName).toString()}
          handleAction={handleMenuAction}
          handleClose={handleClose}
          actions={actions}
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
          setChildActions={setChildActions}
          openChildActions={openChildActions}
        />
      </Menu>
      {childActions && (
        <Popover
          id="mouse-over-popover"
          anchorEl={anchorChildActions}
          open={openChildActions}
          onClose={handleClose}
          disablePortal
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            pointerEvents: "none",
          }}
        >
          <ActionList
            entityID={entityID}
            entityName={t(entityName).toString()}
            handleAction={handleMenuAction}
            handleClose={handleClose}
            actions={childActions}
          />
        </Popover>
      )}
    </div>
  );
}
