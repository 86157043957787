import Dialog from "../../../../../components/@lib/Dialog";
import Property from "../../../../../models/Property";
import useFormElements from "../../../../../hooks/form/useFormElements";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Collapse from "@mui/material/Collapse";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { fetchData, postData, updateData } from "../../../../../utils/utils";
import { API_URL } from "../../../../../utils/config";
import { usePropertyFormElements } from "./form-elements";
import PropertyAttributes from "./PropertyAttributes";
import Attribute from "../../../../../models/Attribute";
import { usePropertyDefaultValueMiddleware } from "../../../../../hooks/common/usePreviewDefaultValue";
import { validate } from "./helpers/validation";
import { FlatProperty } from "../../../../../models/FlatProperty";
import { useTranslation } from "react-i18next";
import {
  PropertyType,
  PropertyTypes,
} from "../../../../../models/PropertyType";
import PropertyTypeCard from "../../../../../components/Properties/PropertyTypeCard";
import { clearDataOptionValues } from "../../../../../utils/property";
import Typography from "@mui/material/Typography";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  propertyGroupUUID?: string;
  property?: Property;
  onCreate?: () => void;
  onUpdate?: (payload: any) => void;
};

function PropertyDialog({
  open,
  handleClose,
  property,
  onUpdate,
  onCreate,
  propertyGroupUUID,
}: DialogProps) {
  const [allAttributes, setAllAttributes] = useState<Attribute[]>([]);
  const [value, setValue] = React.useState("0");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [showWizard, setShowWizard] = useState(false);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    values,
    handleChange,
    setValues,
    isSubmitting,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: new FlatProperty(),
    validate: validate,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      const property = FlatProperty.toProperty(values);

      if (!property.propertyGroup) {
        setErrorMsg("The Property Group field at General tab is incomplete.");
        return;
      }

      if (property.propertyType === PropertyType.VOCABULARY && !property.dataOptions.vocabularyId) {
        setErrorMsg("The Vocabulary field at VALIDATION & DATA OPTIONS tab is incomplete.");
        return;
      }

      if (property.propertyType === PropertyType.IDENTIFIER && !property.dataOptions.identifierId) {
        setErrorMsg("The Identifier field at VALIDATION & DATA OPTIONS tab is incomplete.");
        return;
      }

      if (
        (property.propertyType === PropertyType.REFERENCE && !property.dataOptions.dataSource) || (property.propertyType === PropertyType.REFERENCE && property.dataOptions.dataSource && !property.dataOptions.values.length)) {
        setErrorMsg("The Datasource and Types fields at VALIDATION & DATA OPTIONS tab are incomplete.");
        return;
      }

      if (property.propertyType === PropertyType.SELECT && property.dataOptions.values.length === 0) {
        setErrorMsg("The values at VALIDATION & DATA OPTIONS tab are incomplete.");
        return;
      }

      if (property.propertyType === PropertyType.IDENTIFIER && !property.dataOptions.identifierId) {
        setErrorMsg("The Identifier field at VALIDATION & DATA OPTIONS tab is incomplete.");
        return;
      }

      setErrorMsg("");
      setSubmitting(true);

      const payload = {
        ...property,
        dataOptions: {
          ...property.dataOptions,
          values: clearDataOptionValues(
            property.propertyType,
            property.dataOptions.values
          ),
        },
        slug: `p:${property.slug}`,
        tagValueType: property.tagValueType
      };

      try {
        if (values.uuid) {
          const response = await updateData(
            `${API_URL}/cm/properties/${property.uuid}`,
            payload
          );
          onUpdate && onUpdate(response);
          handleClose();
        } else {
          await postData(`${API_URL}/cm/properties`, payload);
          onCreate && onCreate();
          handleClose();
        }
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setErrorMsg("");
    const myProperty = new FlatProperty();
    if (propertyGroupUUID) {
      myProperty.propertyGroup = propertyGroupUUID;
    }
    setValues(myProperty);
    if (!property || !open) return;
    setValue("0");
    fetchData(`${API_URL}/cm/properties/${property.uuid}`)
      .then((data) => {
        setValues(Property.toFlatProperty(data));
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, [property, setValues, open, propertyGroupUUID]);

  const handleUpdateAttributes = useCallback(
    (attributes: Attribute[]) => {
      setValues((prevState) => ({
        ...prevState,
        attributes,
      }));
    },
    [setValues]
  );

  const getAllAttributes = useCallback(() => {
    fetchData(`${API_URL}/cm/attributes/all`)
      .then((data) => setAllAttributes(data))
      .catch((ex) => console.log(ex));
  }, []);

  useEffect(() => {
    getAllAttributes();
  }, [getAllAttributes]);

  const elements = usePropertyFormElements(
    values.propertyType,
    values,
    values.uuid,
    propertyGroupUUID
  );
  const handleChangeMiddleware = usePropertyDefaultValueMiddleware(
    handleChange,
    setValues,
    values
  );
  const form = useFormElements({
    formElements: elements,
    values: values,
    handleChange: handleChangeMiddleware,
    inProgress: isSubmitting,
    setValues: setValues,
    errors,
    initializeTabValue: open,
  });

  useEffect(() => {
    if (open) setShowWizard(!property?.uuid);
  }, [open, property?.uuid]);

  const renderTitle = () => (
    <TabList value={value} onChange={handleChangeTab} variant="fullWidth">
      <Tab label={t("properties.modal.details")} value={"0"} />
      <Tab label={t("properties.modal.attributes")} value={"1"} />
    </TabList>
  );

  const handleTypeSelect = (type: PropertyType) => {
    setValues((prevState) => ({
      ...prevState,
      propertyType: type,
    }));
  };

  return (
    <TabContext value={value}>
      <Dialog
        open={open}
        handleClose={handleClose}
        secondaryAction={handleClose}
        onSubmit={(e) => {
          if (showWizard) {
            e.preventDefault();
            setShowWizard(false);
          } else {
            handleSubmit(e);
          }
        }}
        primaryActionTitle={t(
          showWizard ? "buttons.next" : "buttons.create"
        ).toString()}
        secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
        maxWidth={"md"}
        fullWidth
        inProgress={isSubmitting}
        title={showWizard ? t("properties.modal.create").toString() : undefined}
        renderTitle={!showWizard && renderTitle}
      >
        {showWizard ? (
          <Grid container spacing={2}>
            {PropertyTypes.map((type) => (
              <Grid
                md={4}
                sm={6}
                xs={6}
                key={type.id}
                item
                sx={{ display: "flex" }}
              >
                <PropertyTypeCard
                  propertyType={{
                    // TODO: Maria add translations here
                    ...type,
                  }}
                  activeType={values.propertyType}
                  onClick={handleTypeSelect}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Fragment>
            <TabPanel value={"0"}>
              <Collapse in={!!errorMsg}>
                <Alert severity="error">
                  <Typography variant={"caption"}>{errorMsg}</Typography>
                </Alert>
              </Collapse>
              {form}
            </TabPanel>
            <TabPanel value={"1"} sx={{ p: 0 }}>
              <PropertyAttributes
                propertyAttributes={values.attributes}
                allAttributes={allAttributes}
                getAllAttributes={getAllAttributes}
                handleUpdateAttributes={handleUpdateAttributes}
                inDialog
              />
            </TabPanel>
          </Fragment>
        )}
      </Dialog>
    </TabContext>
  );
}

export default PropertyDialog;
