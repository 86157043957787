const DocumentIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 74H65C67.2091 74 69 72.2091 69 70V35L40 6H15C12.7909 6 11 7.79086 11 10V70C11 72.2091 12.7909 74 15 74Z"
      fill="#2A30DA"
    />
    <path
      d="M60 66L20 66C18.8954 66 18 65.1046 18 64C18 62.8954 18.8954 62 20 62L60 62C61.1046 62 62 62.8954 62 64C62 65.1046 61.1046 66 60 66Z"
      fill="white"
    />
    <path
      d="M60 56L20 56C18.8954 56 18 55.1046 18 54C18 52.8954 18.8954 52 20 52L60 52C61.1046 52 62 52.8954 62 54C62 55.1046 61.1046 56 60 56Z"
      fill="white"
    />
    <path
      d="M46 46L20 46C18.8954 46 18 45.1046 18 44C18 42.8954 18.8954 42 20 42L46 42C47.1046 42 48 42.8954 48 44C48 45.1046 47.1046 46 46 46Z"
      fill="white"
    />
    <path d="M69 35L40 6V31C40 33.2091 41.7909 35 44 35H69Z" fill="#0B1080" />
  </svg>
);
export default DocumentIcon;
