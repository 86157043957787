const LinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={"100%"}
    height={"100%"}
    viewBox="0 0 80 80"
  >
    <path
      d="M15 74h50c2.2 0 4-1.8 4-4V35L40 6H15c-2.2 0-4 1.8-4 4v60c0 2.2 1.8 4 4 4z"
      style={{
        fill: "#d1d5da",
      }}
    />
    <path
      d="M69 35 40 6v25c0 2.2 1.8 4 4 4h25z"
      style={{
        fill: "#afb5be",
      }}
    />
    <path
      d="m26.6 62-7.3-7.3c-.3-.3-.8-.3-1.2 0-.1.1-.2.3-.2.5v3.4C9 60.2 4.3 67 7.7 75.5c.2.4.7.6 1.1.4.3-.1.5-.4.5-.7.3-3.5 4.8-9.1 8.6-8.8v3.4c0 .4.4.8.8.8.2 0 .4-.1.5-.2l7.3-7.3c.4-.2.4-.8.1-1.1z"
      style={{
        fill: "#2d2d2d",
      }}
    />
  </svg>
);
export default LinkIcon;
