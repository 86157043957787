import Typography from "@mui/material/Typography";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import styled from "@emotion/styled";
import Property from "../../../../../../models/Property";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import useFilterArray from "../../../../../../hooks/common/useFilterArray";
import { SchemaDragDestination } from "./index";
import IconButton from "@mui/material/IconButton";
import { usePropertyGroups } from "../../../../../../store/propertyGroupStore";
import PropertyGroup from "../../../../../../models/PropertyGroup";
import Button from "@mui/material/Button";
import { PropertyType } from "../../../../../../models/PropertyType";
import ListIcon from "@mui/icons-material/List";
import LinkIcon from "@mui/icons-material/Link";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MapIcon from "@mui/icons-material/Map";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import NumbersIcon from "@mui/icons-material/Numbers";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ReservedUUID from "../../../../../../models/ReservedUUID";
import { useIAMPermission } from "../../../../../../store/userStore";
import IAMPermission from "../../../../../../models/IAMPermission";
import { useTranslation } from "react-i18next";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";

const grid = 8;

const StyledProperty = styled(Box)(({ theme }) => ({
  width: "100%",
  // @ts-ignore
  border: "1px solid " + theme.palette.divider,
  borderRadius: 12,
  padding: "8px 8px",
  marginBottom: grid,
  // @ts-ignore
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

type PropertyItemProps = {
  property: Property;
  index: number;
  onEdit: (property: Property) => void;
  isDragDisabled: boolean;
  canEditProperties: boolean;
};

export const getIconForProperty = (
  type: PropertyType,
  size: "inherit" | "large" | "medium" | "small" = "small"
) => {
  switch (type) {
    case PropertyType.DATASTREAM_REF:
    case PropertyType.REFERENCE:
      return <ShortcutIcon fontSize={size} />;
    case PropertyType.IDENTIFIER:
      return <PrivacyTipOutlinedIcon fontSize={size} />;
    case PropertyType.DATE:
      return <CalendarMonthIcon fontSize={size} />;
    case PropertyType.TEXT_FIELD:
    case PropertyType.TEXT_AREA:
    case PropertyType.RICH_TEXT:
      return <TextFieldsIcon fontSize={size} />;
    case PropertyType.URL:
      return <LinkIcon fontSize={size} />;
    case PropertyType.SPATIAL:
      return <MapIcon fontSize={size} />;
    case PropertyType.BOOLEAN:
      return <ToggleOffIcon fontSize={size} />;
    case PropertyType.NUMBER:
      return <NumbersIcon fontSize={size} />;
    case PropertyType.SELECT:
      return <ListIcon fontSize={size} />;
    case PropertyType.VOCABULARY:
      return <MenuBookIcon fontSize={size} />;
    case PropertyType.SLIDER:
      return <LinearScaleIcon fontSize={size} />;
    case PropertyType.PROPERTY_GROUP:
      return <AppsOutlinedIcon fontSize={size} />;
  }
};

function PropertyItem({
  property,
  index,
  onEdit,
  isDragDisabled,
  canEditProperties,
}: PropertyItemProps) {
  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={`${property.uuid}_definition`}
      index={index}
    >
      {(provided) => (
        <StyledProperty
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ opacity: isDragDisabled ? 0.5 : 1 }}
        >
          {getIconForProperty(property.propertyType)}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            ml={1}
            flex={1}
          >
            <Typography variant={"body2"}>{property.name}</Typography>
            {property.propertyGroup !== ReservedUUID.SystemPropertyGroup &&
              canEditProperties && (
                <IconButton size={"small"} onClick={() => onEdit(property)}>
                  <EditOutlinedIcon fontSize={"small"} />
                </IconButton>
              )}
          </Stack>
        </StyledProperty>
      )}
    </Draggable>
  );
}

type ListProps = {
  properties: Property[];
  onEdit: (property: Property) => void;
  isDragDisabled: boolean;
  reservedPropertyUUIDS: string[];
  canEditProperties: boolean;
};

// @ts-ignore
const List = React.memo(function MyList({
  properties,
  onEdit,
  isDragDisabled,
  reservedPropertyUUIDS,
  canEditProperties,
}: ListProps) {
  return properties.map((property, index: number) => (
    <Stack direction={"row"} alignItems={"center"}>
      <Stack
        sx={{
          width: 10,
          height: 1,
          borderBottom: "1px solid",
          borderColor: "primary.main",
        }}
      />
      <PropertyItem
        canEditProperties={canEditProperties}
        isDragDisabled={
          isDragDisabled || reservedPropertyUUIDS.includes(property.uuid!)
        }
        property={property}
        onEdit={onEdit}
        index={index}
        key={`${property.uuid}_definition`}
      />
    </Stack>
  ));
});

type PropertyListProps = {
  properties: Property[];
  createOrUpdate: (property?: Property) => void;
  isDragDisabled: boolean;
  reservedPropertyUUIDS: string[];
};

type GroupItemProps = {
  group: PropertyGroup;
  properties: Property[];
  onEdit: (property?: Property) => void;
  isDragDisabled: boolean;
  reservedPropertyUUIDS: string[];
  canEditProperties: boolean;
};

function GroupItem({
  group,
  properties,
  isDragDisabled,
  onEdit,
  reservedPropertyUUIDS,
  canEditProperties,
}: GroupItemProps) {
  const [open, setOpen] = useState(false);

  return (
    <Stack key={group.uuid} rowGap={1}>
      <Box
        sx={{ backgroundColor: "background.default" }}
        style={{ position: "sticky", top: 0, width: "100%", zIndex: 1 }}
      >
        <Button
          onClick={() => setOpen(!open)}
          variant={"outlined"}
          size={"medium"}
          fullWidth
          endIcon={!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        >
          <Tooltip title={`${group.name} (${properties.length})`}>
            <Typography
              variant={"body2"}
              style={{
                textTransform: "none",
                flex: 1,
                textAlign: "left",
                fontWeight: "normal",
              }}
              noWrap
            >
              {group.name} <b>({properties.length})</b>
            </Typography>
          </Tooltip>
        </Button>
      </Box>
      <Collapse in={open}>
        <Stack
          sx={{ borderLeft: "1px solid", borderColor: "primary.main", ml: 1 }}
        >
          <List
            // @ts-ignore
            properties={properties}
            onEdit={onEdit}
            canEditProperties={canEditProperties}
            isDragDisabled={isDragDisabled}
            reservedPropertyUUIDS={reservedPropertyUUIDS}
          />
        </Stack>
      </Collapse>
    </Stack>
  );
}

function PropertyList({
  properties,
  createOrUpdate,
  isDragDisabled,
  reservedPropertyUUIDS,
}: PropertyListProps) {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const filtered = useFilterArray(properties, query, ["name"]);
  const [groups] = usePropertyGroups();
  const iam = useIAMPermission();
  const canEditProperties = iam.has(
    IAMPermission.store_settings_contentModel_propertyGroup_edit
  );

  return (
    <Stack px={2} direction={"column"} style={{ width: 270 }} rowGap={2} py={2}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        columnGap={1}
      >
        <Typography sx={{ flex: 1 }} variant={"subtitle1"}>
          {t("schemaDetails.existingProperties")}
        </Typography>
        {canEditProperties && (
          <IconButton color={"primary"} onClick={() => createOrUpdate()}>
            <AddCircleOutlinedIcon />
          </IconButton>
        )}
      </Stack>

      <Stack>
        <TextField
          value={query}
          size={"small"}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t("common.search").toString()}
          variant="outlined"
          InputProps={{ startAdornment: <SearchIcon /> }}
        />
      </Stack>
      <div style={{ maxHeight: "calc(100vh - 340px)", overflow: "auto" }}>
        <Stack>
          <Droppable droppableId={SchemaDragDestination.list} isDropDisabled>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {groups.map((group) => (
                  <GroupItem
                    group={group}
                    properties={filtered.filter(
                      (property) => property.propertyGroup === group.uuid
                    )}
                    onEdit={createOrUpdate}
                    isDragDisabled={isDragDisabled}
                    reservedPropertyUUIDS={reservedPropertyUUIDS}
                    canEditProperties={canEditProperties}
                  />
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Stack>
      </div>
    </Stack>
  );
}

export default PropertyList;
