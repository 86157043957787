import React, {Fragment, useMemo} from "react";
import { combineStrings } from "../../../utils/utils";
import { TextTransform } from "../../../models/DataOptions";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import PropertyReadOnlyPreview from "../../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import {FlatProperty} from "../../../models/FlatProperty";
import {PropertyType} from "../../../models/PropertyType";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";

type PreviewProps = {
  property: FlatProperty;
  value: string;
  required: boolean;
  recommended: boolean;
  setValue: (s: string) => void;
  readonly?: boolean;
  errorMessage?: string;
};

function TextFieldProperty({
  property,
  value,
  setValue,
  readonly,
  required,
  recommended,
  errorMessage
}: PreviewProps) {


  const helperText = useMemo(() => {
    let charChars, wordCounts;
    if (property.showCharCounter) {
      let count = value.length;
      charChars = `Characters: ${count}`;
    }

    if (property.showWordCounter) {
      let count = value.trim().split(" ").filter(x => x.length > 0).length;
      wordCounts = `Words: ${count}`;
    }
    return combineStrings(", ", charChars, wordCounts);
  }, [property.showCharCounter, property.showWordCounter, value]);

  const formattedValue = useMemo(() => {
    switch (property.textCase) {
      case TextTransform.LOWERCASE:
        return value.toLowerCase();
      case TextTransform.UPPERCASE:
        return value.toUpperCase();
      default:
        return value;
    }
  }, [property.textCase, value]);

  if (readonly) {
    return (
      <Fragment>
        {property.propertyType === PropertyType.TEXT_FIELD ? <PropertyReadOnlyPreview name={property.name} value={formattedValue} required={required} recommended={recommended}/> : (
          <Stack rowGap={0.5}>
            <Typography variant={"caption"} color={"textSecondary"}>
              {property.name} {required && "*"} {recommended &&  <sup>(Rec)</sup>}
            </Typography>
            {formattedValue ? (
              <Link
                to={formattedValue}
                target={"_blank"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {formattedValue}
              </Link>
            ) : (
              <Typography variant={"body1"}>{"-"}</Typography>
            )}
          </Stack>
        )}
      </Fragment>
    )
  }

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <TextField
        variant={"standard"}
        fullWidth
        required={required}
        // required
        size={"small"}
        value={formattedValue}
        onChange={(e) => setValue(e.target.value)}
        label={<>{property.name} {recommended && <sup>(Rec)</sup>}</>}
        placeholder={property.placeholder}
        InputProps={{
          startAdornment: property.prefix && (
            <InputAdornment position="start">{property.prefix}</InputAdornment>
          ),
          endAdornment: property.suffix && (
            <InputAdornment position="end">{property.suffix}</InputAdornment>
          ),
        }}
        helperText={errorMessage ?? helperText}
        error={Boolean(errorMessage)}
      />
    </Tooltip>
  );
}

export default TextFieldProperty;
