const FileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"100%"}
    height={"100%"}
    fill="none"
    viewBox={"0 0 80 80"}
  >
    <path
      fill="#D1D5DA"
      d="M15 74h50a4 4 0 0 0 4-4V35L40 6H15a4 4 0 0 0-4 4v60a4 4 0 0 0 4 4Z"
    />
    <path fill="#AFB5BE" d="M69 35 40 6v25a4 4 0 0 0 4 4h25Z" />
  </svg>
)

export default FileIcon