import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import BooleanProperty from "../ControlledProperty/BooleanProperty";
import {FlatProperty} from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
};

const initialState = false

function TogglePreview({ property }: PreviewProps) {
  const [value, setValue] = useState(initialState);

  usePreviewDefaultValue<boolean>(property.defaultValue, setValue, initialState)

  return (
    <BooleanProperty property={property} value={value} setValue={setValue} required={false} recommended={false}/>
  );
}

export default TogglePreview;
