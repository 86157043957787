import LinearProgress from "@mui/material/LinearProgress";
import {Stack} from "@mui/material";

interface Props {
  inProgress: boolean
}
function ProgressLoader(props: Props) {
  if (!props.inProgress) return null

  return (
    <Stack position={"fixed"} top={0} left={0} width={"100%"} zIndex={1101}>
      <LinearProgress sx={{ width: "100%" }} />
    </Stack>
  )
}

export default ProgressLoader