import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { DateField } from "@mui/x-date-pickers/DateField/DateField";
import { FlatProperty } from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
  value: string;
  setValue: (s: string) => void;
};

function DateValueField({ property, value, setValue }: PreviewProps) {
  return (
    <TextField
      fullWidth
      size={"small"}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        autoComplete: "off",
      }}
      sx={{ flex: 1 }}
      type={"date"}
      onChange={(e) => setValue(e.target.value)}
      label={"Property Value"}
      placeholder={property.placeholder}
      InputProps={{
        inputProps: { min: property.minValue, max: property.maxValue },
        startAdornment: property.prefix && (
          <InputAdornment position="start">
            <DateField />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default DateValueField;
