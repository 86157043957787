import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { Fragment, useMemo } from "react";
import {
  AddOutlined,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { propertyValueFilters } from "../../../models/GlobalSearch";
import useContainerSchema from "../../../hooks/container/useContainerSchema";
import {
  logicalOperatorOptions,
  operatorOptions,
} from "../../../pages/Search/Filters/FilterOption";
import Property from "../../../models/Property";
import { Option } from "../../../hooks/form/useFormElements";
import useDatastreamSchema from "../../../hooks/datastream/useDatastreamSchema";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Typography from "@mui/material/Typography";
import { getIconForProperty } from "../../../pages/ControlPanel/DataModeling/MetadataSchemas/Schemas/SchemaDetails/PropertyList";
import { PropertyType } from "../../../models/PropertyType";
import ControlledPropertyValueField from "./ControlledPropertyValueField";

type PropertiesConfigProps = {
  types: Option[];
  propertyFilters: propertyValueFilters[];
  handleChange: (prop: string, array: any) => void;
  customWidth?: string;
  disabled?: boolean;
  isContainerType: boolean;
};

export const getOperatorsByPropertyType = (type: PropertyType) => {
  switch (type) {
    case PropertyType.TEXT_FIELD:
    case PropertyType.TEXT_AREA:
    case PropertyType.RICH_TEXT:
      return operatorOptions.filter(
        (x) => x.id !== "BEFORE" && x.id !== "AFTER"
      );
    case PropertyType.NUMBER:
    case PropertyType.DATE:
      return operatorOptions.filter((x) => x.id !== "CONTAINS");
    case PropertyType.SELECT:
    case PropertyType.VOCABULARY:
    case PropertyType.SLIDER:
    case PropertyType.BOOLEAN:
    case PropertyType.SPATIAL:
    case PropertyType.URL:
    case PropertyType.REFERENCE:
      return operatorOptions.filter(
        (x) => x.id === "EQUALS" || x.id === "NOT_EQUALS"
      );
    default:
      return operatorOptions.filter(
        (x) => x.id === "EQUALS" || x.id === "NOT_EQUALS"
      );
  }
};

function PropertiesConfig({
  types,
  propertyFilters,
  handleChange,
  disabled,
  isContainerType,
}: PropertiesConfigProps) {
  const [containerSchema] = useContainerSchema(
    types.length && isContainerType ? types[0].id : undefined
  );
  const [datastreamSchema] = useDatastreamSchema(
    types.length && !isContainerType ? types[0].id : undefined
  );

  const properties = useMemo(() => {
    const schema = isContainerType ? containerSchema : datastreamSchema;
    if (schema.schemaGroups && schema.schemaGroups.length) {
      let propertiesArray = schema.schemaGroups[0].properties;
      schema.schemaGroups.forEach((group, index) => {
        if (index !== 0)
          propertiesArray = propertiesArray.concat(group.properties);
      });
      return propertiesArray.map((x) => x.property);
    }
    return [];
  }, [containerSchema, datastreamSchema, isContainerType]);

  const handleAddFilter = () => {
    const array = [...propertyFilters, new propertyValueFilters()];
    handleChange("properties", array);
  };

  const handleRemoveFilterAtIndex = (index: number) => {
    const array = [
      ...propertyFilters.slice(0, index),
      ...propertyFilters.slice(index + 1),
    ];
    handleChange("properties", array);
  };

  const handleEditFilter = (prop: string, index: number, resetFilters?: boolean) => (e: any) => {
    const value = e.target ? e.target.value : e;
    const array = propertyFilters.map((filter, i) => {
      if (index === i) {
        if (resetFilters) {
          return {
            ...filter,
            [prop]: value,
            operator: "EQUALS",
            propertyValue: "",
          };
        }
        return {
          ...filter,
          [prop]: value,
        };
      }
      return filter;
    });
    handleChange("properties", array);
  };

  return (
    <Fragment>
      {properties.length > 0 ? (
        <Stack direction={"column"} rowGap={2} alignItems={"flex-start"}>
          {propertyFilters.map((opt, index: number) => {
            const selectedProperty = properties.find((p) => p.uuid === opt.propertyUuid);
            const operators = getOperatorsByPropertyType(selectedProperty?.propertyType ?? PropertyType.TEXT_FIELD);
            const flatProperty = Property.toFlatProperty(selectedProperty ?? new Property());
            return (
              <Stack
                direction={"row"}
                columnGap={2}
                alignItems={"center"}
                style={{ width: "100%" }}
              >
                {index !== 0 && (
                  <Select
                    onChange={handleEditFilter("logicalOperator", index)}
                    value={opt.logicalOperator}
                    size={"small"}
                    sx={{ width: "80px" }}
                    MenuProps={{
                      disablePortal: true,
                    }}
                  >
                    {logicalOperatorOptions.map((opt: any) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <FormControl>
                  <InputLabel shrink id="demo-simple-select-label">
                    Property
                  </InputLabel>
                  <Select
                    label={"Property"}
                    MenuProps={{
                      disablePortal: true,
                      sx: { maxHeight: "350px" },
                    }}
                    onChange={handleEditFilter("propertyUuid", index, true)}
                    value={opt.propertyUuid}
                    size={"small"}
                    sx={{ width: "200px" }}
                  >
                    <MenuItem value={"ANY"}>
                      <Stack
                        display={"flex"}
                        flexDirection={"row"}
                        columnGap={0.5}
                        alignItems={"center"}
                      >
                        <ArticleOutlinedIcon fontSize={"small"} />
                        <Typography>ANY</Typography>
                      </Stack>
                    </MenuItem>
                    {properties.map((opt) => (
                      <MenuItem key={opt.uuid} value={opt.uuid}>
                        <Stack
                          display={"flex"}
                          flexDirection={"row"}
                          columnGap={0.5}
                          alignItems={"center"}
                        >
                          {getIconForProperty(opt.propertyType, "small")}
                          <Typography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {opt.name}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel shrink id="demo-simple-select-label">
                    Operator
                  </InputLabel>
                  <Select
                    label={"Operator"}
                    MenuProps={{
                      disablePortal: true,
                      sx: { maxHeight: "350px" },
                    }}
                    onChange={handleEditFilter("operator", index)}
                    value={opt.operator}
                    size={"small"}
                    sx={{ width: "150px" }}
                  >
                  {operators.map((opt: any) => (
                    <MenuItem key={opt.id} value={opt.id} disabled={opt.disabled}>
                      {opt.name}
                    </MenuItem>
                  ))}
                  </Select>
                </FormControl>
                <ControlledPropertyValueField
                  property={flatProperty}
                  value={opt.propertyValue}
                  setValue={(value) =>
                    handleEditFilter("propertyValue", index)(value)
                  }
                  disablePortal={true}
                />
                <IconButton
                  size={"small"}
                  color={"error"}
                  onClick={() => handleRemoveFilterAtIndex(index)}
                  disabled={disabled}
                >
                  <RemoveCircleOutline fontSize={"small"} />
                </IconButton>
              </Stack>
            );
          })}
          {!disabled && (
            <Button
              size={"small"}
              onClick={handleAddFilter}
              startIcon={<AddOutlined />}
            >
              Add Property
            </Button>
          )}
        </Stack>
      ) : null}
    </Fragment>
  );
}

export default PropertiesConfig;
