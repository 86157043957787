// eslint-disable-next-line
export const urlPattern = new RegExp(
  '^(https?:\\/\\/)' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
);

export const useUrlValidation = (value: string) => {
  const validURL = urlPattern.test(value);
  let errorMessage = null;
  if (value) {
    if (!validURL) errorMessage = "Expected format: http(s)://www.example.com";
  }
  return errorMessage;
};
