import * as React from "react";
import useSchemaUsage, {
  UsageEntityType,
} from "../../../../../../hooks/contentModel/useSchemaUsage";
import { useNavigate } from "react-router-dom";
import UsageDialog, {
  UsageRelationship,
} from "../../../ContentModel/UsageDialog";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

type SchemaUsagesDialogProps = {
  schemaUUID?: string;
  open: boolean;
  handleClose: () => void;
};

function SchemaUsagesDialog(props: SchemaUsagesDialogProps) {
  const { schemaUUID, open, handleClose } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const options = [
    {
      id: UsageRelationship.containerTypes,
      name: t("cm.containerTypes"),
    },
    {
      id: UsageRelationship.datastreamTypes,
      name: t("cm.datastreamTypes"),
    },
    {
      id: UsageRelationship.containers,
      name: t("search.type.containers"),
      disabled: true,
    },
    {
      id: UsageRelationship.datastreams,
      name: t("search.type.datastreams"),
      disabled: true,
    },
  ];
  const [value, setValue] = React.useState(options[0].id);
  const [usage, isLoading] = useSchemaUsage(open, schemaUUID);

  const contents = {
    [UsageRelationship.containerTypes]: usage.containerTypes,
    [UsageRelationship.datastreamTypes]: usage.datastreamTypes,
    [UsageRelationship.datastreams]: [],
    [UsageRelationship.containers]: [],
  };

  const handleItem = (
    rowItem: UsageEntityType,
    usageRelationship: UsageRelationship
  ) => {
    switch (usageRelationship) {
      case UsageRelationship.containerTypes:
        navigate(
          `/store/control-panel/content-model/container-types/${rowItem.uuid}`
        );
        break;
      case UsageRelationship.datastreamTypes:
        navigate(
          `/store/control-panel/content-model/datastream-types/${rowItem.uuid}`
        );
        break;
      case UsageRelationship.containers:
        break;
      case UsageRelationship.datastreams:
        break;
      default:
        break;
    }
  };

  const totalElements = useMemo(() => {
    if (value === UsageRelationship.containerTypes) {
      return usage.containerTypes.length;
    }
    return usage.datastreamTypes.length;
  }, [value, usage]);

  return (
    <UsageDialog
      isLoading={isLoading}
      open={open}
      handleClose={handleClose}
      value={value}
      setValue={setValue}
      contents={contents}
      options={options}
      onItemClick={handleItem}
      totalElements={totalElements}
      title={t("schemas.viewUsage.modal.title")}
    />
  );
}

export default SchemaUsagesDialog;
