import * as React from "react";
import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import NumberProperty from "../ControlledProperty/NumberProperty";
import {FlatProperty} from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
};

const initialState = ""

function NumberPreview(props: PreviewProps) {
  const { property } = props;
  const [value, setValue] = useState(initialState);

  usePreviewDefaultValue<string>(property.defaultValue?.toString(), setValue, initialState)

  return (
      <NumberProperty property={property} value={value} setValue={setValue} required={false} recommended={false}/>
  );
}

export default NumberPreview;
