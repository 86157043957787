import * as React from "react";
import { Outlet } from "react-router-dom";
import ModernLayout from "../../components/@lib/ModernLayout";
import FolderIcon from "@mui/icons-material/Folder";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import StarIcon from "@mui/icons-material/Star";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import IAMPermission from "../../models/IAMPermission";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import StoreHeader from "../../components/Navigation/StoreHeader";
import StoreLogo, {StoreLogoIconOnly} from "../../components/@lib/Graphics/StoreLogo";

function DefaultLayout() {
  return (
    <ModernLayout
      logo={<StoreLogo />}
      menuItems={defaultMenu}
      header={<StoreHeader />}
      logoIcon={<StoreLogoIconOnly/>}
      rootPath={"/store/content"}
    >
      <Outlet />
    </ModernLayout>
  );
}

export default DefaultLayout;

const defaultMenu = [
  {
    icon: FolderIcon,
    name: "sidenav.myContent",
    path: "store/content",
  },
  {
    icon: WatchLaterIcon,
    name: "sidenav.recent",
    path: "store/recent",
  },
  {
    icon: StarIcon,
    name: "sidenav.popular",
    path: "store/popular",
  },
  {
    icon: InsertChartIcon,
    name: "sidenav.reports",
    path: "store/reports",
  },
  {
    icon: DeleteIcon,
    name: "sidenav.trash",
    path: "store/trash",
  },
  {
    icon: LinkOutlinedIcon,
    name: "sidenav.quickLinks",
    path: "",
    items: [
      {
        icon: EventNoteOutlinedIcon,
        name: "ms.vocabularies",
        path: "store/control-panel/data-modeling/metadata-schemas/vocabularies",
        permissions: [IAMPermission.store_settings_tags_view],
      },
      {
        icon: LocalOfferOutlinedIcon,
        name: "sidenav.settings.tags",
        path: "store/control-panel/data-modeling/tags",
        permissions: [IAMPermission.store_settings_tags_view],
      },
    ],
  },
];
