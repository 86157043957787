import { Draggable, Droppable } from "react-beautiful-dnd";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import React, { Fragment, useState } from "react";
import {
  AddCircleOutline,
  AddCircleOutlined,
  DragHandle,
  RemoveCircleOutline,
  TextFields,
} from "@mui/icons-material";
import { SchemaProperty } from "../../../../../../models/Schema";
import Typography from "@mui/material/Typography";
import { SchemaDragDestination } from "./index";
import Preview from "../../../../../../components/Properties/Previews/Preview";
import IconButton from "@mui/material/IconButton";
import Property from "../../../../../../models/Property";
import { styled } from "@mui/styles";
// @ts-ignore
import { ReactComponent as DropImage } from "../../../../../../assets/images/undraw_diary_re_4jpc.svg";
import ConfirmationDialog from "../../../../../../components/@lib/ConfirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { PropertyType } from "../../../../../../models/PropertyType";
import GroupPropertyPreview from "../../../../../../components/Properties/Previews/GroupPropertyPreview";
import {Button} from "@mui/material";
import {getIconForProperty} from "./PropertyList";

type PropertyItemProps = {
  property: SchemaProperty;
  properties: SchemaProperty[];
  index: number;
  setSelectedElementUUID: (el: string | undefined) => void;
  selectedElementUUID: string | undefined;
  disabled: boolean;
  handleRemoveProperty: (uuid: SchemaProperty) => void;
  onAddProperty: () => void;
};

function PropertyItem({
  property,
  index,
  setSelectedElementUUID,
  selectedElementUUID,
  disabled,
  handleRemoveProperty,
  properties,
  onAddProperty
}: PropertyItemProps) {
  const isSelected = selectedElementUUID === property.uuid;
  const {t} = useTranslation();
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState<SchemaProperty | undefined>()
  const [isDeleting, setIsDeleting] = useState(false)
  const handleDeleteProperty = async () => {
    if (!propertyToDelete) return
    setIsDeleting(true)
    await handleRemoveProperty(propertyToDelete);
    setIsDeleting(false)
    setOpenDeleteWarning(false);
  };

  return (
    <Fragment>
      <Draggable
        draggableId={`schema_${property.uuid}`}
        index={index}
        isDragDisabled={disabled}
      >

        {(provided, snapshot) => {
          const renderContent = () => {
            const isGroup = property.property.propertyType === PropertyType.PROPERTY_GROUP
            if (!snapshot.isDragging) {
              return (
                <Stack direction={"row"} width={"100%"}>
                  {isGroup ? (
                    <GroupPropertyPreview property={Property.toFlatProperty(property.property)}>
                      <Stack direction={"column"} rowGap={2}>
                        {properties.map((schemaProperty) => {
                          const flat = Property.toFlatProperty(schemaProperty.property);
                          return (
                            <Stack direction={"row"} columnGap={1} alignItems={"center"} key={`${property.uuid}_${flat.uuid}`}>
                              <Preview property={flat} />
                              <Fade in={!disabled && !snapshot.isDragging}>
                                <IconButton
                                  size={"small"}
                                  onClick={() => {
                                    setPropertyToDelete(schemaProperty)
                                    setOpenDeleteWarning(true)
                                  }}
                                >
                                  <RemoveCircleOutline />
                                </IconButton>
                              </Fade>
                            </Stack>
                          );
                        })}
                        <Button
                          size={"small"}
                          sx={{ alignSelf: "flex-start"}}
                          startIcon={<AddCircleOutline/>}
                          onClick={onAddProperty}
                        >
                          Add Property
                        </Button>
                      </Stack>
                    </GroupPropertyPreview>
                  ) : (
                    <Preview property={Property.toFlatProperty(property.property)} />
                  )}

                  <Stack
                    display={"flex"}
                    direction={"row"}
                    columnGap={0.5}
                    sx={{ "&:hover": { cursor: "default" } }}
                  >
                    {property.repeatable && (
                      <PropertyIndicator
                        title={t("schemas.properties.repeatable")}
                        initial={"R"}
                      />
                    )}
                    {property.indexable && (
                      <PropertyIndicator
                        title={t("schemas.properties.indexable")}
                        initial={"I"}
                      />
                    )}
                    {property.publishable && (
                      <PropertyIndicator
                        title={t("schemas.properties.publishable")}
                        initial={"P"}
                      />
                    )}
                  </Stack>
                </Stack>
              );
            }

            return (
              <Card elevation={2}>
                <Stack
                  direction={"row"}
                  p={1}
                  alignItems={"center"}
                  columnGap={1}
                >
                  <TextFields />
                  <Typography>{property.property.name}</Typography>
                </Stack>
              </Card>
            );
          };

          return (
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                width: "100%",
                borderRadius: 1,
                backgroundColor: isSelected
                  ? "background.neutral"
                  : "2px solid none",
              }}
              columnGap={2}
              onClick={() => setSelectedElementUUID(property.uuid)}
              p={1}
              mb={2}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Fade in={!disabled}>
                <DragHandle />
              </Fade>

              <Tooltip title={property.property.propertyType} arrow>
                <IconButton
                  size={"small"}
                  disableRipple
                  sx={{
                    backgroundColor: "primary.main",
                    color: "primary.contrastText",
                    cursor: "default",
                  }}
                >
                  {getIconForProperty(
                    property.property.propertyType,
                    "inherit"
                  )}
                </IconButton>
              </Tooltip>

              {renderContent()}
              <Fade in={!disabled && !snapshot.isDragging}>
                <IconButton
                  size={"small"}
                  onClick={() => {
                    setPropertyToDelete(property);
                    setOpenDeleteWarning(true);
                  }}
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Fade>
            </Stack>
          );
        }}
      </Draggable>
      <ConfirmationDialog
        open={openDeleteWarning}
        inProgress={isDeleting}
        handleClose={() => setOpenDeleteWarning(false)}
        primaryAction={handleDeleteProperty}
        title={`Delete Confirmation`}
        message={`Are you sure you want to delete this Property?`}
        primaryActionTitle={"Delete"}
      />
    </Fragment>
  );
}

type ListProps = {
  properties: SchemaProperty[];
  setSelectedElementUUID: (el: string | undefined) => void;
  selectedElementUUID: string | undefined;
  disabled: boolean;
  handleRemoveProperty: (uuid: SchemaProperty) => void;
  onAddProperty: () => void
};

// @ts-ignore
const List = React.memo(function MyList({
  properties,
  setSelectedElementUUID,
  selectedElementUUID,
  disabled,
  handleRemoveProperty,
  onAddProperty
}: ListProps) {
  return properties
    .filter(x => !x.parent)
    .sort((a, b) => a.position - b.position)
    .map((property, index: number) => {
      const childProperties = properties.filter(x => x.parent === property.uuid)

      return (
        <PropertyItem
            property={property}
            properties={childProperties}
            setSelectedElementUUID={setSelectedElementUUID}
            selectedElementUUID={selectedElementUUID}
            index={index}
            key={`schema_${property.uuid}`}
            disabled={disabled}
            handleRemoveProperty={handleRemoveProperty}
            onAddProperty={onAddProperty}
        />
      )
    });
});

const StyledContainer = styled(Stack)(({ theme }) => ({
  flex: 2,
  // @ts-ignore
  borderLeft: `2px solid ${theme.palette.divider}`,
  // @ts-ignore
  borderRight: `2px solid ${theme.palette.divider}`,
  maxHeight: "calc(100vh - 200px)",
  height: "calc(100vh - 200px)",
  overflow: "auto",
  // @ts-ignore
  backgroundColor: theme.palette.background.paper,
}));

type PropertyFormProps = {
  properties: SchemaProperty[];
  hasGroup: boolean;
  disabled?: boolean;
  loading: boolean;
  selectedElementUUID: string | undefined;
  setSelectedElementUUID: (el: string | undefined) => void;
  handleRemoveProperty: (uuid: SchemaProperty) => void;
  onAddProperty: () => void
};

function PropertyForm(props: PropertyFormProps) {
  const {
    properties,
    hasGroup,
    setSelectedElementUUID,
    selectedElementUUID,
    disabled,
    handleRemoveProperty,
    loading,
    onAddProperty
  } = props;

  if (loading) {
    return (
      <StyledContainer
        direction={"column"}
        rowGap={1}
        p={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer direction={"column"}>
      <Droppable
        droppableId={SchemaDragDestination.form}
        isDropDisabled={disabled}
      >
        {(provided, snapshot) => (
          <Stack py={8} px={2} style={{}}>
            <Box
              sx={{
                minHeight: "100%",
                border: "2px dotted",
                borderColor:
                  snapshot.isDraggingOver ||
                  (properties.length === 0 && hasGroup)
                    ? "primary.light"
                    : "transparent",
                transition: "all 0.3s",
                borderRadius: 2,
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <List
                // @ts-ignore
                properties={properties}
                setSelectedElementUUID={setSelectedElementUUID}
                selectedElementUUID={selectedElementUUID}
                disabled={disabled}
                handleRemoveProperty={handleRemoveProperty}
                onAddProperty={onAddProperty}
              />
              {properties.length > 0 && provided.placeholder}
              {properties.length === 0 && (
                <Stack
                  py={6}
                  style={{ minHeight: "100%", height: 400 }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <DropImage style={{ width: 200 }} />
                  {hasGroup && (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      columnGap={1}
                    >
                      <AddCircleOutlined color={"primary"} />
                      <Typography variant={"subtitle1"}>
                        Drop Properties Here
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              )}
            </Box>
          </Stack>
        )}
      </Droppable>
    </StyledContainer>
  );
}

export default PropertyForm;


function PropertyIndicator(props: { title: string, initial: string }) {
  const { title, initial } = props
  return (
      <Tooltip title={title} arrow>
        <Box
            sx={{
              width: "20px",
              height: "20px",
              borderRadius: 12,
              backgroundColor: "secondary.main",
            }}
        >
          <Stack alignItems={"center"} justifyContent={"center"} width={'100%'} height={'100%'}>
            <Typography
                variant={"body2"}
                color={"primary"}
            >
              {initial}
            </Typography>
          </Stack>
        </Box>
      </Tooltip>
  )
}