import Dialog from "../../../../../../components/@lib/Dialog";
import DeleteButton from "../../../../../../components/Common/DeleteButton";
import * as React from "react";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import {
  deleteData,
  postData,
  updateData,
} from "../../../../../../utils/utils";
import { API_URL } from "../../../../../../utils/config";
import { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import Term from "../../../../../../models/Term";
import ConfirmationDialog from "../../../../../../components/@lib/ConfirmationDialog";
import useFormElements, {
  ElementType,
} from "../../../../../../hooks/form/useFormElements";
import { useUrlValidation } from "../../../../../../hooks/common/useUrlValidation";
import { useTranslation } from "react-i18next";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  term?: Term;
  onCreate: (term: Term) => void;
  onUpdate: () => void;
  onDelete?: () => void;
  vocUuid: string;
};

const formElements = [
  {
    id: "label",
    label: "formElement.label",
    type: ElementType.text,
    isRequired: true,
    columns: 12,
  },
  {
    id: "termId",
    label: "formElement.termID",
    type: ElementType.text,
    columns: 12,
  },
  {
    id: "termUri",
    label: "formElement.termURI",
    type: ElementType.text,
    columns: 12,
  },
];

function TermDialog({
  open,
  handleClose,
  term,
  onUpdate,
  onCreate,
  onDelete,
  vocUuid,
}: DialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    values,
    handleChange,
    setValues,
    isSubmitting,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: new Term(),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (uriValidation) return;

      setSubmitting(true);
      try {
        if (term) {
          await updateData(
            `${API_URL}/cm/vocabularies/${vocUuid}/terms/${term.uuid}`,
            values
          );
          onUpdate();
          handleClose();
        } else {
          const result = await postData(
            `${API_URL}/cm/vocabularies/${vocUuid}/terms`,
            values
          );
          onCreate(result);
          handleClose();
        }
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
      setSubmitting(false);
      resetForm();
    },
  });

  const uriValidation = useUrlValidation(values.termUri);
  const errors = useMemo(() => {
    return { termUri: uriValidation };
  }, [uriValidation]);

  const form = useFormElements({
    formElements,
    values,
    handleChange,
    inProgress: isSubmitting,
    variant: "standard",
    setValues,
    errors,
  });

  useEffect(() => {
    setValues(term ?? new Term());
  }, [term, setValues]);

  const handleDelete = async () => {
    try {
      await deleteData(
        // @ts-ignore
        `${API_URL}/cm/vocabularies/${vocUuid}/terms/${term.uuid}`
      );
      onDelete && onDelete();
      handleClose();
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setOpenDeleteDialog(false);
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("terms.modal.title").toString()}
      onSubmit={handleSubmit}
      secondaryAction={handleClose}
      primaryActionTitle={
        term ? t("buttons.update").toString() : t("buttons.create").toString()
      }
      secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
      maxWidth={"xs"}
      fullWidth
      inProgress={isSubmitting}
    >
      <Stack rowGap={2} sx={{ ml: -1, mt: 1 }}>
        {form}
      </Stack>
      {term && onDelete && (
        <DeleteButton onClick={() => setOpenDeleteDialog(true)} />
      )}
      <ConfirmationDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        primaryAction={handleDelete}
        title={`Delete Confirmation`}
        message={`Are you sure you want to delete this term? This action can not be undone!`}
        primaryActionTitle={"Delete"}
      />
    </Dialog>
  );
}

export default TermDialog;
