// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

// function responsiveFontSizes({ sm, md, lg }) {
//   return {
//     '@media (min-width:600px)': {
//       fontSize: pxToRem(sm),
//     },
//     '@media (min-width:900px)': {
//       fontSize: pxToRem(md),
//     },
//     '@media (min-width:1200px)': {
//       fontSize: pxToRem(lg),
//     },
//   };
// }

const FONT_PRIMARY = 'Lato, sans-serif';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    // fontWeight: 700,
    // lineHeight: 20 / 16,
    // fontSize: pxToRem(16),
  },
  h2: {
    // fontWeight: 500,
    // lineHeight: 20 / 13,
    // fontSize: pxToRem(13),
  },
  h3: {
    // fontWeight: 500,
    // lineHeight: 16/12,
    // fontSize: pxToRem(12),
    },
    h4: {
      // fontWeight: 400,
      // lineHeight: 16/16,
      // fontSize: pxToRem(24),
  },
  h5: {
    // fontWeight: 400,
    // lineHeight: 11.75/10,
    // fontSize: pxToRem(10),
  },
  h6: {
    // fontWeight: 500,
    // lineHeight: 14.06 / 12,
    // fontSize: pxToRem(12),
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 20/20,
    fontSize: pxToRem(20),
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 16 / 12,
    fontSize: pxToRem(12),
  },
  body1: {
    lineHeight: 1.2,
    fontSize: pxToRem(16),
  },
  body2: {
    // fontWeight: 500,
    lineHeight: 16/12,
    fontSize: pxToRem(12),
  },
  caption: {

    lineHeight: 14/12,
    fontSize: pxToRem(14),
  },
  overline: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'uppercase',
  },
};

export default typography;
