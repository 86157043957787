import * as React from "react";
import ModernLayout from "../../../../components/@lib/ModernLayout";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import XRHeader from "../../components/XRHeader";
import StoreLogo, {StoreLogoIconOnly} from "../../../../components/@lib/Graphics/StoreLogo";

type DefaultLayoutProps = {
  children: React.ReactNode;
};

function DefaultLayout({ children }: DefaultLayoutProps) {
  return (
    <ModernLayout
      header={<XRHeader />}
      menuItems={menuItems}
      logo={<StoreLogo />}
      logoIcon={<StoreLogoIconOnly/>}
      rootPath={"/xr"}
    >
      {children}
    </ModernLayout>
  );
}

export default DefaultLayout;

const menuItems = [
  {
    icon: ViewInArIcon,
    name: "Applications",
    path: "xr/applications",
    color: "primary.main",
    description: "",
  },
];
