import Dialog from "../../../../../components/@lib/Dialog";
import { Stack } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useSortHandler } from "../../../../../hooks/sort/useSortHandler";
import { useDefaultTable } from "../../../../../hooks/defaultTable/useDefaultTable";
import DefaultTable from "../../../../../components/DefaultTable";
import Attribute from "../../../../../models/Attribute";
import Button from "@mui/material/Button";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { useIAMPermission } from "../../../../../store/userStore";
import IAMPermission from "../../../../../models/IAMPermission";
import { useTranslation } from "react-i18next";

type AddAttributeDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleCreateNew: () => void;
  attributes: Attribute[];
  handleAddAttributes: (attributes: string[]) => void;
  appName?: string;
};

const headCells = [
  {
    id: "name",
    numeric: false,
    label: "table.name",
  },
  {
    id: "attributeType",
    numeric: false,
    label: "table.type",
  },
];

function AddAttributeDialog({
  open,
  handleClose,
  attributes,
  handleCreateNew,
  handleAddAttributes,
}: AddAttributeDialogProps) {
  const sortHandler = useSortHandler();
  const { t } = useTranslation();
  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
    selected,
    setSelected,
  } = useDefaultTable(attributes, sortHandler, "uuid", false);

  useEffect(() => {
    if (open) setSelected([]);
  }, [open, setSelected]);

  const iam = useIAMPermission();

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("properties.addedAttributes.modal.title").toString()}
      primaryAction={() => handleAddAttributes(selected)}
      primaryActionTitle={t("buttons.confirm").toString()}
      maxWidth={"sm"}
      fullWidth
    >
      <Stack direction={"column"} rowGap={2}>
        <Stack
          columnGap={2}
          alignItems={"flex-start"}
          rowGap={1}
          direction={"row"}
        >
          <div style={{ flexGrow: 1 }}></div>
          {iam.has(
            IAMPermission.store_settings_contentModel_attributes_edit
          ) && (
            <Button
              startIcon={<AddCircleOutlinedIcon />}
              style={{ textTransform: "none" }}
              size={"small"}
              onClick={handleCreateNew}
            >
              {t("properties.addedAttributes.modal.createNew")}
            </Button>
          )}
        </Stack>

        {/* User Table */}
        <DefaultTable
          headCells={headCells}
          renderDefaultBody={renderDefaultBody}
          renderHeadCell={renderHeadCell}
          rows={attributes}
          renderCheckAllCell={renderCheckAllCell}
          selectableRows
          disableRowGap
        />
      </Stack>
    </Dialog>
  );
}

export default AddAttributeDialog;
