import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TimelineOppositeContent } from "@mui/lab";
import Typography from "@mui/material/Typography";
import Paging from "../../../models/Pageable";
import EntityActivity from "../../../models/EntityActivity";
import { ActualDate, ActualTime } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

type ActivityProps = {
  activityData: Paging<EntityActivity>;
  loadPage: () => void;
  fullPage?: boolean;
  options?: string[];
  setFilters?: React.Dispatch<React.SetStateAction<string[]>>;
  setActivityPage?: React.Dispatch<React.SetStateAction<number>>;
};

function Activity({
  activityData,
  loadPage,
  fullPage,
  options,
  setFilters,
  setActivityPage,
}: ActivityProps) {
  const { t } = useTranslation();
  return (
    <Stack
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      rowGap={2}
    >
      {setFilters && options && setActivityPage && (
        <Autocomplete
          multiple
          fullWidth
          size={"small"}
          id="tags-outlined"
          options={options}
          onChange={(event: any, newValue: string[]) => {
            setFilters(newValue);
            setActivityPage(0);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("activity.type")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          sx={{ width: 300 }}
        />
      )}
      <Timeline sx={{ py: 0 }}>
        {activityData.content.map((event, index) => (
          <TimelineItem style={{ margin: 0, padding: 0 }} key={index}>
            <TimelineOppositeContent sx={{ py: 0, px: 1 }}>
              <Typography variant="body2" color="textSecondary">
                {ActualDate(event.createdAt)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {ActualTime(event.createdAt)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <ArrowCircleRightOutlinedIcon
                fontSize={"medium"}
                color={"primary"}
              />
              <TimelineConnector sx={{ backgroundColor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: 0, pb: 4, pl: 2, width: 150 }}>
              <Paper elevation={4} sx={{ height: "auto", width: 140 }}>
                <Typography variant="body2" sx={{ p: 1 }}>
                  {event.message}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      {!activityData.last && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ alignSelf: "center" }}
          onClick={loadPage}
        >
          <Typography variant={"subtitle2"}>
            {fullPage ? t("buttons.showMore") : t("buttons.loadMore")}
          </Typography>
        </Button>
      )}
    </Stack>
  );
}

export default Activity;
