export type TagValueTypeProps = {
    id: TagValueType;
    name: string;
}

export enum TagValueType {
    ALL = "ALL",
    LABEL = "LABEL",
    FILENAME = "FILENAME",
    URL = "URL",
    THUMBURL = "THUMBURL",
    VALUE = "VALUE",
    UUID = "UUID",
    TERM_ID = "TERM_ID",
    TERM_URI = "TERM_URI",
}

export const TagValueTypes: TagValueTypeProps[] = [
    {
        id: TagValueType.ALL,
        name: "ALL",
    },
    {
        id: TagValueType.UUID,
        name: "UUID",
    },
    {
        id: TagValueType.LABEL,
        name: "LABEL",
    },
    {
        id: TagValueType.FILENAME,
        name: "FILENAME",
    },
    {
        id: TagValueType.URL,
        name: "URL",
    },
    {
        id: TagValueType.THUMBURL,
        name: "THUMBURL",
    },
    {
        id: TagValueType.VALUE,
        name: "VALUE",
    },
    {
        id: TagValueType.TERM_ID,
        name: "TERM_ID",
    },
    {
        id: TagValueType.TERM_URI,
        name: "TERM_URI",
    }
]