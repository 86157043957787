import React, {Fragment, useEffect, useMemo, useState} from "react";
import Dialog from "components/@lib/Dialog";
import { useDefaultTable } from "hooks/defaultTable/useDefaultTable";
import { useSortHandler } from "hooks/sort/useSortHandler";
import DefaultTable from "components/DefaultTable";
import { Stack } from "@mui/material";
import {useMap} from "hooks/common/useMap";
import SearchField from "components/@lib/Inputs/SearchField";
import useFilterArray from "hooks/common/useFilterArray";
import TableSelectorButton from "components/@lib/Inputs/Common/TableSelectorButton";

interface Option {
  id: string;
  name: string;
}

type Props = {
  label: string;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  name: string;
  value?: string | string[];
  onChange: (selected: string[]) => void;
  multiSelect?: boolean;
  disabled?: boolean;
  options: Option[]
  headCells?: HeadCell[]
};

type HeadCell = {
  id: string
  label: string
}

const defaultCells = [
  {
    id: "name",
    label: "Name",
  },
];

function TableSelect(props: Props) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("")

  const {
    label,
    required,
    recommended,
    onChange,
    error,
    helperText,
    value,
    disabled,
    multiSelect,
    options,
    headCells = defaultCells
  } = props;

  const filtered = useFilterArray(options, search, ["name"])

  const sortHandler = useSortHandler();
  const {
    renderDefaultBody,
    renderHeadCell,
    renderCheckAllCell,
    setSelected,
    selected,
  } = useDefaultTable(filtered, sortHandler, "id", true, multiSelect);


  useEffect(() => {
    if (open) {
      if (Array.isArray(value)) {
        // @ts-ignore
        setSelected(value)
      } else if (typeof value == "string"){
        // @ts-ignore
        setSelected([value])
      }
    }
  }, [open, setSelected, value]);

  const handleConfirmSelection = () => {
    setOpen(false);
    onChange(selected);
  };

  const optionMap = useMap(options, "id")

  const valueString = useMemo(() => {
    if (Array.isArray(value)) {
      if (value.length === 0) return undefined
      return value.map(id => optionMap.get(id)?.name ?? "").join(", ")
    } else {
      if (!value) return undefined
      return optionMap.get(value)?.name
    }
  }, [optionMap, value])

  return (
    <Fragment>
      <TableSelectorButton
        handleClick={() => setOpen(true)}
        label={label}
        required={required}
        recommended={recommended}
        disabled={disabled}
        valueString={valueString ?? "None"}
        error={error}
        helperText={helperText}
      />

      <Dialog
        primaryAction={handleConfirmSelection}
        maxWidth={"sm"}
        fullWidth
        open={open}
        handleClose={() => setOpen(false)}
        title={label}
      >
        <Stack rowGap={2}>
          <SearchField value={search} setValue={setSearch} />
          <DefaultTable
            selectableRows
            renderCheckAllCell={renderCheckAllCell}
            headCells={headCells}
            renderDefaultBody={renderDefaultBody}
            renderHeadCell={renderHeadCell}
            rows={filtered}
          />
        </Stack>
      </Dialog>
    </Fragment>
  );
}

export default TableSelect;
