import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import PropertyReadOnlyPreview from "../../../pages/Content/Containers/Common/PropertyReadOnlyPreview";
import {FlatProperty} from "../../../models/FlatProperty";

type PreviewProps = {
  property: FlatProperty;
  value: string;
  setValue: (s: string) => void;
  readonly?: boolean;
  errorMessage?: string;
  required: boolean;
  recommended: boolean;
};

function NumberProperty({ property, value, setValue, readonly, required, recommended, errorMessage }: PreviewProps) {

  if (readonly) {
    const { requireDecimal, decimalLimit } = property
    const number = requireDecimal && value ? parseFloat(value).toFixed(decimalLimit) : value

    return (
      <PropertyReadOnlyPreview name={property.name} value={number} required={required} recommended={recommended}/>
    )
  }

  return (
    <Tooltip title={property.tooltip} placement={"bottom-start"}>
      <TextField
        variant={"standard"}
        // required
        size={"small"}
        type={"number"}
        fullWidth
        required={required}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        label={<>{property.name} {recommended && <sup>(Rec)</sup>}</>}
        placeholder={property.placeholder}
        InputProps={{
          startAdornment: property.prefix && (
            <InputAdornment position="start">{property.prefix}</InputAdornment>
          ),
          endAdornment: property.suffix && (
            <InputAdornment position="end">{property.suffix}</InputAdornment>
          ),
        }}
        helperText={errorMessage}
        error={Boolean(errorMessage)}
      />
    </Tooltip>
  );
}

export default NumberProperty;
