export enum JobState {
  SUCCEEDED = "SUCCEEDED",
  ENQUEUED = "ENQUEUED",
  SCHEDULED = "SCHEDULED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
}

class Job {
  jobId: string = "";
  jobName: string = "";
  jobType: string = "";
  jobTypeText: string = "";
  createdBy: string = "";
  progress: number = 0;
  state: JobState = JobState.PROCESSING;
  startAt: string = "";
  completedAt: string = "";
  createdAt: string = "";
  jobResult: object = {};
  jobParams: object = {};
  jobActionDetails?: ActionDetails = { actionDetails: [] }
}

interface ActionDetails {
  actionDetails: ActionDetail[]
}

export enum JobActionDetailType {
  download = "DOWNLOAD",
  redirect = "REDIRECT"
}

interface ActionDetail {
  type: JobActionDetailType
  value: string
}

export default Job;