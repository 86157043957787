import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { UsageEntityType } from "../../../../../hooks/contentModel/useSchemaUsage";
import { useNavigate } from "react-router-dom";
import UsageDialog, { UsageRelationship } from "../../ContentModel/UsageDialog";
import { usePaginatedEntityUsage } from "../../../../../hooks/contentModel/usePaginatedEntityUsage";
import { API_URL } from "../../../../../utils/config";
import { useTranslation } from "react-i18next";

type PropertyUsagesDialogProps = {
  propertyUUID?: string;
  open: boolean;
  handleClose: () => void;
  optionValue?: string;
};

interface SettingResult {
  uuid: string;
  label: string;
  type: {
    uuid: string;
    name: string;
  };
  parent: string;
}

function PropertyUsagesDialog(props: PropertyUsagesDialogProps) {
  const { propertyUUID, open, handleClose, optionValue } = props;
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const options = [
    {
      id: UsageRelationship.containers,
      name: t("search.type.containers"),
    },
    {
      id: UsageRelationship.datastreams,
      name: t("search.type.datastreams"),
    },
  ];
  const [value, setValue] = React.useState(options[0].id);
  const { results: containerUsages, isLoading: isLoadingContainerUsages } =
    usePaginatedEntityUsage<SettingResult>(
      open && value === UsageRelationship.containers
        ? `${API_URL}/cm/properties/${propertyUUID}/dataOptions/${optionValue}/usages_containers`
        : null,
      page
    );

  const { results: datastreamUsages, isLoading: isLoadingDatastreamUsages } =
    usePaginatedEntityUsage<SettingResult>(
      open && value === UsageRelationship.datastreams
        ? `${API_URL}/cm/properties/${propertyUUID}/dataOptions/${optionValue}/usages_datastreams`
        : null,
      page
    );

  const resultedContainers = useMemo(() => {
    return containerUsages.content.map((result) => {
      return {
        uuid: result.uuid,
        name: result.label,
        parent: result.parent,
      };
    });
  }, [containerUsages]);

  const resultedDatastreams = useMemo(() => {
    return datastreamUsages.content.map((result) => {
      return {
        uuid: result.uuid,
        name: result.label,
        parent: result.parent,
      };
    });
  }, [datastreamUsages]);

  useEffect(() => {
    if (!open) return;
    setPage(0);
  }, [value, open]);

  const contents = {
    [UsageRelationship.datastreams]: resultedDatastreams,
    [UsageRelationship.containers]: resultedContainers,
  };

  const handleItem = (
    rowItem: UsageEntityType,
    usageRelationship: UsageRelationship
  ) => {
    switch (usageRelationship) {
      case UsageRelationship.containers:
        navigate(`/store/content/${rowItem.uuid}`);
        break;
      case UsageRelationship.datastreams:
        navigate(`/store/content/${rowItem.parent}`);
        break;
      default:
        break;
    }
  };

  const onClose = () => {
    setPage(0);
    setValue(options[0].id);
    handleClose();
  };

  return (
    <UsageDialog
      isLoading={isLoadingContainerUsages || isLoadingDatastreamUsages}
      open={open}
      handleClose={onClose}
      value={value}
      setValue={setValue}
      contents={contents}
      options={options}
      onItemClick={handleItem}
      title={"Property Usage"}
      page={page}
      setPage={setPage}
      totalPages={
        value === UsageRelationship.containers
          ? containerUsages.totalPages
          : datastreamUsages.totalPages
      }
      totalElements={
        value === UsageRelationship.containers
          ? containerUsages.totalElements
          : datastreamUsages.totalElements
      }
    />
  );
}

export default PropertyUsagesDialog;
