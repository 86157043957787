import { create } from "zustand";
import { createCommonStore, Entity, useEntityList } from "./core";
import { API_URL } from "../utils/config";
import Vocabulary from "../models/Vocabulary";

export const useVocabularyStore = create<Entity<Vocabulary>>(
  createCommonStore(`${API_URL}/cm/vocabularies/all`, "uuid")
);

export const useVocabularyList = (): [Vocabulary[], boolean] => {
  const store = useVocabularyStore();
  return useEntityList(store);
};
