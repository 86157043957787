import { create } from "zustand";
import {createMap, postData} from "../../utils/utils";
import {API_URL} from "../../utils/config";
import Term from "../../models/Term";

interface StoreState {
  termMap: Map<string, Term>;
  getTermNames: (uuids: string[]) => void;
  loading: boolean
}

export const useVocabularyTermNamesStore = create<StoreState>((set) => ({
  termMap: new Map<string, Term>(),
  loading: false,
  getTermNames: async (uuids: string[]) => {
    set({ loading: true })
    try {
      const results: Term[] = await postData(`${API_URL}/cm/vocabularies/terms`, { uuids })
      const termMap = createMap(results, "uuid")
      set({ termMap })
    } catch (ex) {}
    set({ loading: false })
  },
}));
