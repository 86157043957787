import {
  ElementType,
  FormElement,
} from "../../../../../hooks/form/useFormElements";
import { AttributeType } from "../../../../../models/AttributeType";
import { TextTransform } from "../../../../../models/DataOptions";
import Attribute, { FlatAttribute } from "../../../../../models/Attribute";
import * as React from "react";
import { ChangeEvent, useMemo } from "react";
import { getDefaultValueFormElement } from "../../../../../hooks/common/usePreviewDefaultValue";
import { FlatProperty } from "../../../../../models/FlatProperty";
import OptionValuesTable from "../Properties/OptionValuesTable";

export const formElements = [
  {
    id: "general",
    type: ElementType.group,
    label: "formElement.general",
    expanded: true,
    formElements: [
      {
        id: "name",
        label: "formElement.name",
        type: ElementType.text,
        isRequired: true,
        columns: 8,
      },
      {
        id: "attributeType",
        label: "formElement.type",
        type: ElementType.select,
        columns: 4,
        isRequired: true,
        options: [
          { id: AttributeType.TEXT, name: "Text" },
          { id: AttributeType.SELECT, name: "Select" },
          { id: AttributeType.URL, name: "Url" },
        ],
      },
      {
        id: "description",
        label: "formElement.description",
        type: ElementType.textArea,
        columns: 12,
      },
    ],
  },
  {
    id: "displayOptions",
    label: "formElement.displayOptions",
    type: ElementType.group,
    expanded: true,
    formElements: [
      {
        id: "prefix",
        label: "formElement.prefix",
        type: ElementType.text,
        columns: 6,
        in: [AttributeType.TEXT],
      },
      {
        id: "suffix",
        label: "formElement.suffix",
        type: ElementType.text,
        columns: 6,
        in: [AttributeType.TEXT],
      },
      {
        id: "tooltip",
        label: "formElement.tooltip",
        type: ElementType.text,
        columns: 6,
        in: [AttributeType.TEXT, AttributeType.SELECT, AttributeType.URL],
      },
      {
        id: "placeholder",
        label: "formElement.placeholder",
        type: ElementType.text,
        columns: 6,
        in: [AttributeType.TEXT, AttributeType.URL],
      },
      {
        id: "showCharCounter",
        label: "formElement.showCharacter",
        type: ElementType.boolean,
        columns: 6,
      },
      {
        id: "showWordCounter",
        label: "formElement.showWord",
        type: ElementType.boolean,
        columns: 6,
        in: [AttributeType.TEXT],
      },
      {
        id: "textCase",
        label: "formElement.textTransform",
        type: ElementType.select,
        columns: 6,
        in: [AttributeType.TEXT],
        options: [
          {
            id: TextTransform.NONE,
            name: "None",
          },
          {
            id: TextTransform.LOWERCASE,
            name: "Lowercase",
          },
          {
            id: TextTransform.UPPERCASE,
            name: "Uppercase",
          },
        ],
      },
    ],
  },
  {
    id: "validationAndDataOptions",
    label: "formElement.validationOptions",
    type: ElementType.group,
    expanded: true,
    formElements: [
      {
        id: "defaultValue",
        label: "formElement.defaultValue",
        type: ElementType.text,
        columns: 12,
        in: [AttributeType.SELECT, AttributeType.TEXT],
      },
      {
        id: "minLength",
        label: "formElement.minLength",
        type: ElementType.number,
        columns: 6,
        in: [AttributeType.TEXT],
      },
      {
        id: "maxLength",
        label: "formElement.maxLength",
        type: ElementType.number,
        columns: 6,
        in: [AttributeType.TEXT],
      },
      {
        id: "values",
        label: "formElement.values",
        type: ElementType.custom,
        control: (
          values: any,
          setValues: (e: ChangeEvent) => void,
          allowEdit: boolean,
          variant: string,
          error?: string
        ) => (
          <OptionValuesTable
            values={values}
            setValues={setValues}
            allowEdit={allowEdit}
            variant={variant}
            error={error}
          />
        ),
        columns: 12,
        in: [AttributeType.SELECT],
      },
    ],
  },
];

export function convertAttributeToForm(data: Attribute): FlatAttribute {
  return {
    attributeType: data.attributeType,
    defaultValue: data.dataOptions?.defaultValue,
    description: data.description,
    maxLength: data.validationOptions?.maxLength?.toString() ?? "",
    minLength: data.validationOptions?.minLength?.toString() ?? "",
    placeholder: data.displayOptions?.placeholder || "",
    suffix: data.displayOptions?.suffix ?? "",
    prefix: data.displayOptions?.prefix ?? "",
    name: data.name,
    showCharCounter: data.displayOptions?.showCharCounter ?? false,
    showWordCounter: data.displayOptions?.showWordCounter ?? false,
    textCase: data.dataOptions?.textCase ?? TextTransform.NONE,
    tooltip: data.displayOptions?.tooltip ?? "",
    values: data.dataOptions?.values ?? [],
    // @ts-ignore
    uuid: data.uuid ?? "",
  };
}

export function convertFlatToAttribute(flat: FlatAttribute): Attribute {
  const attribute = new Attribute();
  // @ts-ignore
  attribute.attributeType = flat.attributeType;
  attribute.description = flat.description;
  attribute.uuid = flat.uuid;
  attribute.name = flat.name;
  attribute.dataOptions = {
    textCase: flat.textCase,
    defaultValue: flat.defaultValue,
    values: flat.values,
  };
  attribute.displayOptions = {
    suffix: flat.suffix,
    prefix: flat.prefix,
    tooltip: flat.tooltip,
    showCharCounter: flat.showCharCounter,
    showWordCounter: flat.showWordCounter,
    placeholder: flat.placeholder,
  };
  attribute.validationOptions = {
    maxLength: parseInt(flat.maxLength),
    minLength: parseInt(flat.minLength),
  };
  return attribute;
}

export const useAttributeFormElements = (
  type: AttributeType,
  values: FlatAttribute,
  uuid?: String
): FormElement[] => {
  return useMemo(() => {
    return formElements.map((el) => {
      return {
        ...el,
        formElements: el.formElements
          // @ts-ignore
          .filter((child) => {
            if (child.in) {
              return child.in.includes(type);
            }
            return true;
          })
          // @ts-ignore
          .map((child) => {
            if (child.id === "attributeType") {
              return {
                ...child,
                disabled: Boolean(uuid),
              };
            } else if (child.id === "defaultValue") {
              const property = new FlatProperty();
              property.propertyType = Attribute.getPropertyAssociateType(type);
              property.values = values.values;
              return getDefaultValueFormElement(property, child);
            }
            return child;
          }),
      };
    });
  }, [type, uuid, values.values]);
};
