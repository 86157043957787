import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "../../../../../../components/@lib/Dialog";
import React, { useEffect, useMemo, useState } from "react";
import { SchemaProperty } from "../../../../../../models/Schema";

type DeletePropertyDialogProps = {
  open: boolean;
  schemaProperty?: SchemaProperty;
  handleClose: () => void;
  message: string;
  handleUsage: () => void;
  handleDelete: (
    schemaProperty: SchemaProperty,
    force: boolean,
    reason: string
  ) => void;
};

function DeletePropertyDialog({
  open,
  handleClose,
  handleDelete,
  schemaProperty,
  message,
  handleUsage,
}: DeletePropertyDialogProps) {
  const [reason, setReason] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const handleDeleteAnyway = async () => {
    setInProgress(true);
    await handleDelete(schemaProperty!, true, reason);
    setInProgress(false);
  };

  const isDisabled = useMemo(() => {
    return reason !== `delete ${schemaProperty?.property?.name}`;
  }, [reason, schemaProperty]);

  useEffect(() => {
    setReason("");
  }, [open]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={"Remove Property from Schema"}
      primaryAction={handleDeleteAnyway}
      disablePrimaryAction={isDisabled}
      primaryActionTitle={"Delete Anyway"}
      secondaryActionTitle={"View Usages"}
      secondaryAction={handleUsage}
      maxWidth={"sm"}
      inProgress={inProgress}
      fullWidth
    >
      <Stack direction={"column"} rowGap={2}>
        <Typography>{message}</Typography>
        <Typography>
          To confirm deletion, type{" "}
          <b>delete {schemaProperty?.property?.name}</b> in the text input
          field.
        </Typography>
        <TextField
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          label={"Confirmation"}
          variant={"standard"}
          size={"small"}
        />
      </Stack>
    </Dialog>
  );
}

export default DeletePropertyDialog;
