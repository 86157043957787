import { useState } from "react";
import { usePreviewDefaultValue } from "../../../hooks/common/usePreviewDefaultValue";
import {FlatProperty} from "../../../models/FlatProperty";
import VocabularyProperty from "../ControlledProperty/VocabularyProperty";
import Stack from "@mui/material/Stack";

type PreviewProps = {
  property: FlatProperty;
};

const initialState: string[] = [];

function VocabularyPreview({ property }: PreviewProps) {
  const [values, setValues] = useState<string[]>(initialState);

  usePreviewDefaultValue<string[]>(property.defaultValue, setValues, initialState);

  return (
    <Stack style={{ width: "100%" }}>
      <VocabularyProperty
        property={property}
        value={values}
        setValue={setValues}
        required={false}
        recommended={false}
      />
    </Stack>
  );
}

export default VocabularyPreview;
