import {
  ElementType,
  FormElement,
} from "../../../../../hooks/form/useFormElements";
import { DataSource, TextTransform } from "../../../../../models/DataOptions";
import * as React from "react";
import { ChangeEvent, useMemo } from "react";
import {
  PropertyType,
  PropertyTypes,
} from "../../../../../models/PropertyType";
import OptionValuesTable from "./OptionValuesTable";
import { getDefaultValueFormElement } from "../../../../../hooks/common/usePreviewDefaultValue";
import { usePropertyGroups } from "../../../../../store/propertyGroupStore";
import { useArrayOptions } from "../../../../../hooks/common/useArrayOptions";
import { FlatProperty } from "../../../../../models/FlatProperty";
import { useDatastreamTypeList } from "../../../../../store/datastreamTypeStore";
import { useContainerTypeList } from "../../../../../store/containerTypeStore";
import { useVocabularyList } from "../../../../../store/vocabularyStore";
import ReservedUUID from "../../../../../models/ReservedUUID";
import { useNamespaceList } from "../../../../../store/namespaceStore";
import moment from "moment";
import EntitySlug from "../../ContentModel/EntitySlug";
import { sortBy } from "../../../../../utils/utils";
import MarksTable from "./MarksTable";
import { useIdentifierList } from "../../../../../store/identifierStore";
import { TagValueTypes } from "../../../../../models/TagValueType";
import {useTagValueTypeOptions} from "../../../../../hooks/properties/useTagValueTypeOptions";

enum DateFormat {
  classic = "DD/MM/YYYY",
  monthYear = "MM/YYYY",
  year = "YYYY",
  fullDate = "MMMM Do YYYY, h:mm:ss a",
  day = "dddd",
  dateNoDay = "MMMM YYYY",
  date = "MMM Do YY",
  // escaped = "YYYY [escaped] YYYY",
}

export const formElements = [
  {
    id: "general",
    type: ElementType.group,
    label: "formElement.general",
    expanded: true,
    children: [
      {
        id: "id",
        label: "id",
        type: ElementType.custom,
        control: (
          values: any,
          setValues: (e: ChangeEvent) => void,
          allowEdit: boolean,
          variant: string,
          inProgress: boolean
        ) => (
          <EntitySlug
            values={values}
            setValues={setValues}
            allowEdit={allowEdit}
            variant={variant}
            inProgress={inProgress}
            slugPrefix={"p:"}
          />
        ),
        columns: 12,
      },
      {
        id: "propertyGroup",
        label: "formElement.propertyGroup",
        type: ElementType.select,
        columns: 6,
        isRequired: false,
        hasNone: true,
        options: [],
      },
      {
        id: "propertyType",
        label: "formElement.type",
        type: ElementType.select,
        columns: 6,
        isRequired: true,
        options: PropertyTypes.sort((a, b) => sortBy(a, b, "name")),
      },
      {
        id: "description",
        label: "formElement.description",
        type: ElementType.textArea,
        columns: 12,
      },
    ],
  },
  {
    id: "displayOptions",
    label: "formElement.displayOptions",
    type: ElementType.group,
    expanded: true,
    children: [
      {
        id: "prefix",
        label: "formElement.prefix",
        type: ElementType.text,
        columns: 6,
        in: [
          PropertyType.TEXT_FIELD,
          PropertyType.TEXT_AREA,
          PropertyType.NUMBER,
        ],
      },
      {
        id: "suffix",
        label: "formElement.suffix",
        type: ElementType.text,
        columns: 6,
        in: [
          PropertyType.TEXT_FIELD,
          PropertyType.TEXT_AREA,
          PropertyType.NUMBER,
        ],
      },
      {
        id: "tooltip",
        label: "formElement.tooltip",
        type: ElementType.text,
        columns: 6,
        in: [
          PropertyType.TEXT_FIELD,
          PropertyType.TEXT_AREA,
          PropertyType.NUMBER,
          PropertyType.BOOLEAN,
          PropertyType.SELECT,
          PropertyType.URL,
          PropertyType.RICH_TEXT,
          PropertyType.REFERENCE,
          PropertyType.SPATIAL,
          PropertyType.DATE,
          PropertyType.VOCABULARY,
          PropertyType.SLIDER,
          PropertyType.DATASTREAM_REF,
          PropertyType.IDENTIFIER,
        ],
      },
      {
        id: "placeholder",
        label: "formElement.placeholder",
        type: ElementType.text,
        columns: 6,
        in: [
          PropertyType.TEXT_FIELD,
          PropertyType.TEXT_AREA,
          PropertyType.NUMBER,
          PropertyType.URL,
          PropertyType.VOCABULARY,
          PropertyType.IDENTIFIER,
        ],
      },
      {
        id: "showCharCounter",
        label: "formElement.showCharacter",
        type: ElementType.boolean,
        columns: 6,
        in: [PropertyType.TEXT_FIELD, PropertyType.TEXT_AREA],
      },
      {
        id: "showWordCounter",
        label: "formElement.showWord",
        type: ElementType.boolean,
        columns: 6,
        in: [PropertyType.TEXT_FIELD, PropertyType.TEXT_AREA],
      },
      {
        id: "maxRows",
        label: "formElement.maxRows",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.TEXT_AREA],
      },
      {
        id: "minRows",
        label: "formElement.minRows",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.TEXT_AREA],
      },
      {
        id: "requireDecimal",
        label: "formElement.requireDecimal",
        type: ElementType.boolean,
        columns: 6,
        in: [PropertyType.NUMBER],
      },
      {
        id: "decimalLimit",
        label: "formElement.decimalLimit",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.NUMBER],
      },
      {
        id: "delimeter",
        label: "formElement.delimeter",
        type: ElementType.boolean,
        columns: 6,
        in: [PropertyType.NUMBER],
      },
      {
        id: "textCase",
        label: "formElement.textTransform",
        type: ElementType.select,
        columns: 6,
        in: [PropertyType.TEXT_FIELD, PropertyType.TEXT_AREA],
        options: [
          {
            id: TextTransform.NONE,
            name: "None",
          },
          {
            id: TextTransform.LOWERCASE,
            name: "Lowercase",
          },
          {
            id: TextTransform.UPPERCASE,
            name: "Uppercase",
          },
        ],
      },
      {
        id: "enableStaticSearch",
        label: "formElement.enableStaticSearch",
        type: ElementType.boolean,
        columns: 6,
        in: [PropertyType.SELECT],
      },
      {
        id: "mask",
        label: "formElement.mask",
        type: ElementType.select,
        hasNone: true,
        options: [
          {
            id: DateFormat.classic,
            name: moment().format(DateFormat.classic),
          },
          {
            id: DateFormat.monthYear,
            name: moment().format(DateFormat.monthYear),
          },
          {
            id: DateFormat.year,
            name: moment().format(DateFormat.year),
          },
          {
            id: DateFormat.day,
            name: moment().format(DateFormat.day),
          },
          {
            id: DateFormat.date,
            name: moment().format(DateFormat.date),
          },
          {
            id: DateFormat.dateNoDay,
            name: moment().format(DateFormat.dateNoDay),
          },
          {
            id: DateFormat.fullDate,
            name: moment().format(DateFormat.fullDate),
          },
        ],
        columns: 6,
        in: [PropertyType.DATE],
      },
    ],
  },

  {
    id: "validationAndDataOptions",
    label: "formElement.validationOptions",
    type: ElementType.group,
    expanded: true,
    children: [
      {
        id: "dataSource",
        label: "formElement.datasource",
        type: ElementType.select,
        isRequired: false,
        hasNone: true,
        columns: 6,
        in: [PropertyType.REFERENCE],
        options: [
          {
            id: DataSource.datastreamType,
            name: "Datastream Types",
          },
          {
            id: DataSource.containerType,
            name: "Container Types",
          },
        ],
      },
      {
        id: "vocabularyId",
        label: "formElement.vocabulary",
        type: ElementType.select,
        isRequired: false,
        hasNone: true,
        columns: 12,
        in: [PropertyType.VOCABULARY],
        options: [],
      },
      {
        id: "identifierId",
        label: "formElement.identifier",
        type: ElementType.select,
        isRequired: false,
        hasNone: true,
        columns: 12,
        in: [PropertyType.IDENTIFIER],
        options: [],
      },
      {
        id: "values",
        label: "formElement.selectTypes",
        type: ElementType.multiSelect,
        isRequired: false,
        columns: 6,
        hasNone: true,
        options: [],
        in: [PropertyType.REFERENCE],
      },
      {
        id: "containerType",
        label: "formElement.selectContainerType",
        type: ElementType.select,
        isRequired: true,
        columns: 6,
        hasNone: false,
        options: [],
        in: [PropertyType.DATASTREAM_REF],
      },
      {
        id: "datastreamTypes",
        label: "formElement.selectDatastreamType",
        type: ElementType.multiSelect,
        isRequired: true,
        columns: 6,
        hasNone: false,
        options: [],
        in: [PropertyType.DATASTREAM_REF],
      },
      {
        id: "minValue",
        label: "formElement.minDate",
        type: ElementType.date,
        columns: 6,
        in: [PropertyType.DATE],
      },
      {
        id: "maxValue",
        label: "formElement.maxDate",
        type: ElementType.date,
        columns: 6,
        in: [PropertyType.DATE],
      },
      {
        id: "defaultValue",
        label: "formElement.defaultValue",
        type: ElementType.text,
        hasReadonly: true,
        columns: 12,
        in: [
          PropertyType.TEXT_FIELD,
          PropertyType.URL,
          PropertyType.VOCABULARY,
          PropertyType.SLIDER,
          PropertyType.SPATIAL,
        ],
      },
      {
        id: "multipleValues",
        label: "formElement.multipleValues",
        type: ElementType.boolean,
        columns: 6,
        in: [PropertyType.SELECT, PropertyType.SLIDER],
      },
      {
        id: "values",
        label: "formElement.values",
        type: ElementType.custom,
        control: (
          values: any,
          setValues: (e: ChangeEvent) => void,
          allowEdit: boolean,
          variant: string,
          error?: string
        ) => (
          <OptionValuesTable
            required
            values={values}
            setValues={setValues}
            allowEdit={allowEdit}
            variant={variant}
            error={error}
          />
        ),
        columns: 12,
        in: [PropertyType.SELECT],
      },
      {
        id: "marks",
        label: "formElement.marks",
        type: ElementType.custom,
        control: (
          values: any,
          setValues: (e: ChangeEvent) => void,
          allowEdit: boolean,
          variant: string,
          error?: string
        ) => (
          <MarksTable
            values={values}
            setValues={setValues}
            allowEdit={allowEdit}
            variant={variant}
            error={error}
          />
        ),
        columns: 12,
        in: [PropertyType.SLIDER],
      },
      {
        id: "minValue",
        label: "formElement.minValue",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.NUMBER, PropertyType.SLIDER],
      },
      {
        id: "maxValue",
        label: "formElement.maxValue",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.NUMBER, PropertyType.SLIDER],
      },
      {
        id: "step",
        label: "formElement.step",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.SLIDER],
      },
      {
        id: "minLength",
        label: "formElement.minLength",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.TEXT_FIELD, PropertyType.TEXT_AREA],
      },
      {
        id: "maxLength",
        label: "formElement.maxLength",
        type: ElementType.number,
        columns: 6,
        in: [PropertyType.TEXT_FIELD, PropertyType.TEXT_AREA],
      },
    ],
  },
  {
    id: "serialization",
    type: ElementType.group,
    expanded: true,
    label: "formElement.serialization",
    children: [
      {
        id: "namespace",
        label: "formElement.namespace",
        type: ElementType.select,
        hasNone: true,
        options: [],
        columns: 6,
      },
      {
        id: "tagName",
        label: "formElement.tag",
        type: ElementType.text,
        columns: 6,
      },
      {
        id: "tagValueType",
        label: "formElement.tagValueType",
        type: ElementType.select,
        columns: 6,
        in: [PropertyType.DATASTREAM_REF, PropertyType.SELECT, PropertyType.VOCABULARY],
        options: []
      }
    ],
  },

];

export const usePropertyFormElements = (
  type: PropertyType,
  values: FlatProperty,
  uuid?: String,
  propertyGroupUUID?: string
): FormElement[] => {
  const [propertyGroups] = usePropertyGroups();
  const [datastreamTypes] = useDatastreamTypeList();
  const [containerTypes] = useContainerTypeList();
  const [vocabularies] = useVocabularyList();
  const [identifiers] = useIdentifierList();
  const [namespaces] = useNamespaceList();
  const groupOptions = useArrayOptions(propertyGroups, "uuid", "name");
  const datastreamOptions = useArrayOptions(datastreamTypes, "uuid", "name");
  const containerOptions = useArrayOptions(containerTypes, "uuid", "name");
  const vocabularyOptions = useArrayOptions(vocabularies, "uuid", "name");
  const namespaceOptions = useArrayOptions(namespaces, "prefix", "name", "/store/control-panel/data-modeling/metadata-schemas/namespaces/");
  const containerType = containerTypes.find(x => x.uuid === values.containerType)
  const allowedDatastreams = datastreamTypes.filter(x => {
    if (containerType?.allowedDatastreams[0] === ReservedUUID.ContentModelAllowedAll) {
      return true
    }
    return containerType?.allowedDatastreams.includes(x.uuid!)
  })
  const allowedDatastreamOptions = useArrayOptions(allowedDatastreams, "uuid", "name");
  const identifierOptions = useArrayOptions(identifiers, "uuid", "name");

  const tagValueTypeOptions = useTagValueTypeOptions(type, TagValueTypes)


  return useMemo(() => {
    return formElements.map((el) => {
      return {
        ...el,
        formElements: el.children
          // @ts-ignore
          .filter((child) => {
            if (child.in) {
              return child.in.includes(type);
            }
            return true;
          })
          // @ts-ignore
          .map((child) => {
            if (child.id === "propertyType") {
              return {
                ...child,
                disabled: Boolean(uuid),
              };
            } else if (child.id === "defaultValue") {
              return getDefaultValueFormElement(values, child);
            } else if (child.id === "propertyGroup") {
              const options = groupOptions.map((x) => {
                const disabled = x.id === ReservedUUID.SystemPropertyGroup;
                return {
                  ...x,
                  disabled,
                };
              });
              return {
                ...child,
                options,
                disabled: Boolean(propertyGroupUUID),
              };
            } else if (
              child.id === "values" &&
              (values.propertyType === PropertyType.REFERENCE)
            ) {
              if (values.dataSource === DataSource.containerType) {
                return {
                  ...child,
                  options: containerOptions,
                };
              } else if (values.dataSource === DataSource.datastreamType) {
                return {
                  ...child,
                  options: datastreamOptions,
                };
              }

              return {
                ...child,
                disabled: true,
                options: [],
              };
            } else if (child.id === "vocabularyId" && values.propertyType === PropertyType.VOCABULARY) {
              return {
                ...child,
                options: vocabularyOptions.sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                }),
              };
            } else if (
              child.id === "identifierId" &&
              values.propertyType === PropertyType.IDENTIFIER
            ) {
              return {
                ...child,
                options: identifierOptions.sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                }),
              };
            } else if (child.id === "namespace") {
              return {
                ...child,
                options: namespaceOptions,
              };
            } else if (child.id === "containerType") {
              return {
                ...child,
                options: containerOptions
              }
            } else if (child.id === "datastreamTypes") {

              return {
                ...child,
                options: allowedDatastreamOptions,
                disabled: !values.containerType
              }
            } else if (child.id === "tagValueType") {
              return {
                ...child,
                options: tagValueTypeOptions
              }
            }
            return child;
          }),
      };
    });
  }, [
    allowedDatastreamOptions,
    containerOptions,
    datastreamOptions,
    groupOptions,
    namespaceOptions,
    propertyGroupUUID,
    type,
    uuid,
    values,
    vocabularyOptions,
    identifierOptions,
    tagValueTypeOptions
  ]);
};
