import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React, { useMemo, useState, useCallback } from "react";
import MediaLibraryDialog, {MediaFormat} from "./MediaLibraryDialog";
import { FileType } from "../../../models/Import";
import AssetItem from "./AssetPicker/components/AssetItem";
import AssetAddButton from "./AssetPicker/components/AssetAddButton";
import AssetPlaceHolder from "./AssetPicker/components/AssetPlaceHolder";
import AssetHelperText from "./AssetPicker/components/AssetHelperText";
import ConfirmationDialog from "../../@lib/ConfirmationDialog";
import { useTranslation } from "react-i18next";

type Asset = { uuid: string, caption: string };

interface Props {
  maxFiles?: number;
  value: Asset[] | Asset | string[] | string;
  setValue: (values: Asset[] | Asset | string[] | string | undefined) => void;
  label?: string;
  helperText?: string | React.ReactNode;
  filetype: FileType;
  fullWidth?: boolean;
  captioned?: boolean;
  allowedFormats?: [MediaFormat]
  error?: boolean
  handleReorder?: (uuid: string, direction: 1 | -1) => void
}

function AssetPicker({ maxFiles, label, value, setValue, helperText, fullWidth = false, filetype, captioned = false, allowedFormats, error, handleReorder }: Props) {
  const [openImagePicker, setOpenImagePicker] = useState(false);
  const {t} = useTranslation();
  const handleIconDidChange = (assets: string[]) => {
    setOpenImagePicker(false);
    if (!assets[0]) return;

    let newValue: any;
    if (captioned) {
      if (typeof value === "string" || !value) {
        newValue = { uuid: assets[0], caption: "" };
      } else {
        newValue = Array.isArray(value)
          ? [...value as Asset[], ...assets.map((uuid) => ({ uuid, caption: "" }))]
          : { uuid: assets[0], caption: "" };
        if (Array.isArray(newValue)) {
          newValue = newValue.filter((newAsset, index, self) =>
            index === self.findIndex((asset) => asset.uuid === newAsset.uuid)
          );
        }
      }
    } else {
      if (typeof value === "string" || !value) {
        newValue = assets[0];
      } else {
        newValue = Array.isArray(value)
          ? [...value as string[], ...assets.filter((uuid) => !(value as string[]).includes(uuid))]
          : assets[0];
      }
    }
    setValue(newValue);
  };


  const assetUUIDs = useMemo(() => {
    if (!value) return [];
    if (!Array.isArray(value)) return captioned && (value as Asset).uuid ? [value as Asset] : [value as string];
    return value;
  }, [value, captioned]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<any>(false);


  const handleRemove = useCallback(
    () => {
      const uuid = openDeleteDialog?.uuid || openDeleteDialog.toString();
      if (!uuid) return;
      if (!Array.isArray(value)) {
        setValue(undefined);
        setOpenDeleteDialog(false)
      } else {
        const filterFunc = (v: any) => v.uuid !== uuid;
        setValue(captioned ? (value as Asset[]).filter(filterFunc) : (value as string[]).filter((v) => v !== uuid));
        setOpenDeleteDialog(false)
      }
    },
    [setValue, value, captioned, openDeleteDialog]
  );

  const handleCaptionChange = useCallback(
    (assetUuid: string, newCaption: string) => {
      const mapFunc = (item: any) =>
        item.uuid === assetUuid ? { ...item, caption: newCaption } : item;
      setValue(Array.isArray(value) ? value.map(mapFunc) : { ...value as Asset, caption: newCaption });
    },
    [setValue, value]
  );

  return (
    <Box display={fullWidth ? "initial" : "inline-flex"}>
      <ConfirmationDialog
        open={openDeleteDialog!==false}
        handleClose={() => setOpenDeleteDialog(false)}
        primaryAction={handleRemove}
        title={t("deleteConfirmation.modal.title")}
        message={t("deleteAsset.confirmation.msg")}
        secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
        primaryActionTitle={t("buttons.delete").toString()}
        inProgress={false}
      />
      <Stack rowGap={1} position={"relative"}>
        {label && (
          <Typography
            color={"textSecondary"}
            position={"absolute"}
            left={16}
            top={-8}
            sx={{ backgroundColor: "background.paper", fontSize: "0.8rem" }}
          >
            {label}
          </Typography>
        )}
        <Stack
          rowGap={1}
          px={2}
          py={3}
          sx={{
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 1
          }}
        >
          <Stack
            justifyContent={maxFiles===1?"center":"flex-start"}
            alignItems={"center"}
            columnGap={2}
            rowGap={2}
            direction={"row"}
            minWidth={fullWidth ? "auto" :  filetype === "IMAGE" ? 180 : 90} minHeight={fullWidth ? "auto" : filetype === "IMAGE" ? 180 : 90}
          >
            {assetUUIDs.map((asset: Asset | string, index) => (
              <AssetItem
                asset={asset}
                captioned={captioned}
                onRemove={() => setOpenDeleteDialog(asset)}
                onCaptionChange={handleCaptionChange}
                isFirst={index === 0}
                isLast={index === assetUUIDs.length -1}
                handleReorder={handleReorder}
              />
            ))}
            {assetUUIDs.length === 0 && filetype === "IMAGE" && maxFiles===1 && <AssetPlaceHolder filetype={filetype} />}
            {assetUUIDs.length === 0 && filetype === "ICON" && maxFiles===1 && <AssetPlaceHolder filetype={filetype}  />}

          </Stack>
          <Stack>
            <AssetAddButton
              maxFiles={maxFiles}
              currentFileCount={assetUUIDs.length}
              onAddClick={() => setOpenImagePicker(true)}
            />
          </Stack>
          <AssetHelperText
            helperText={helperText}
            error={error}
            maxFiles={maxFiles}
            currentFileCount={assetUUIDs.length}
          />
        </Stack>
      </Stack>
      <MediaLibraryDialog
        open={openImagePicker}
        handleClose={() => setOpenImagePicker(false)}
        singleSelect={maxFiles === 1}
        onSelectConfirm={handleIconDidChange}
        allowedFormats={allowedFormats}
      />
    </Box>
  );
}

export default AssetPicker;
