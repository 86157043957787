import * as React from "react";
import { PropertyType } from "../../../models/PropertyType";
import { FlatProperty } from "../../../models/FlatProperty";
import DefaultValueField from "./DefaultValueField";
import VocabularyValueField from "./VocabularyValueField";
import DateValueField from "./DateValueField";
import BooleanValueField from "./BooleanValueField";
import SelectValueField from "./SelectValueField";
import NumberField from "./NumberField";

type PreviewProps = {
  property: FlatProperty;
  value: any;
  setValue: (newValue: any) => void;
  disablePortal?: boolean;
};

function ControlledPropertyValueField(props: PreviewProps) {
  const { property, value, setValue, disablePortal } = props;

  if (!property.uuid)
    return <DefaultValueField value={value} setValue={setValue} />;

  switch (property.propertyType) {
    case PropertyType.TEXT_FIELD:
    case PropertyType.TEXT_AREA:
    case PropertyType.RICH_TEXT:
      return <DefaultValueField value={value} setValue={setValue} />;
    case PropertyType.NUMBER:
      return <NumberField value={value} setValue={setValue} />;
    case PropertyType.SELECT:
      return <SelectValueField value={value} setValue={setValue} property={property} />;
    case PropertyType.BOOLEAN:
      return <BooleanValueField value={value} setValue={setValue} />;
    case PropertyType.DATE:
      return <DateValueField value={value} setValue={setValue} property={property} />;
    case PropertyType.VOCABULARY:
      return (
        <VocabularyValueField
          label={"Property Value"}
          value={value}
          onChange={setValue}
          name={property.name}
          labelProp={"label"}
          valueProp={"uuid"}
          property={property}
          disablePortal={disablePortal}
        />
      );
    default:
      return <DefaultValueField value={value} setValue={setValue} />;
  }
}

export default ControlledPropertyValueField;
